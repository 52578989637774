import React from 'react';

import SEO from '../../componentes/Main/SEO.js';
import DataSeo from '../../constantes/DataSeo.js';

import '../../global.css';

import BadgeMencion from '../../componentes/Main/BadgeMencion.js';
import Mencion from '../../componentes/Objetos/ObjetoMencion.js';

// import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";
import { getFirestore, collection, getDocs, query, where, orderBy, limit, doc } from "firebase/firestore";

import iconoTwitter from '../../imagenes/iconos/icon-twitter.svg';
// import iconoFacebook from '../../imagenes/iconos/icon-facebook.svg';
import imagenMenciones from '../../imagenes/Menciones.jpg';

const banner = DataSeo.MencionesImage;
// const imagenMenciones = "https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";
// const imagen = "";

// --------------

const db = getFirestore();


// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// --------------

class Menciones extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            ListaMenciones:[],
        };

        this.getListaMenciones();
    }

    // ENTRADAS ----------------------
    async getListaMenciones(){
        
        // Creamos una lista local
        let lista = [];

        const referenciaMenciones = collection(db, `Menciones`);

        // Create a query against the collection.
        const q = query(referenciaMenciones, orderBy("date", "desc"), limit(15)).withConverter(Mencion.mencionConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    // Variable de tipo Entrada.class
                    var objMencion = new Mencion('','','','','','','');
                    objMencion = documento.data();

                    // let idReferencia = documento.id;

                    // Extraemos la URL/id
                    const urlPublica = documento.id; 

                    let date = objMencion.date;

                    // Convertimos Timestamp a Date
                    const dateMencion = date.toDate();

                    /* NECESARIO para la tarjeta */
                    // "día, fecha de mes de año"
                    var fechaString = dateMencion.toLocaleDateString("es-MX", formatoFecha);

                    let logotipo = objMencion.logotipo;
                    let autor = objMencion.autor;
                    let descripcionAutor = objMencion.descripcionAutor;

                    let cita = objMencion.cita;
                    let urlCita = objMencion.urlCita;

                    // Creamos un objeto local
                    let objeto = { urlPublica, objMencion, fechaString, logotipo, autor, descripcionAutor, cita, urlCita};

                    // Agregamos el objeto local a la lista local
                    lista.push(objeto);

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            ListaMenciones: lista
                        }
                    });
                });

            if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            ListaMenciones: lista
                        }
                    });

            }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        return (
            <React.Fragment>

                {/* SEO */}
                <SEO
                    title={DataSeo.MencionesTitle}
                    description={DataSeo.MencionesDescription}
                    image={banner}
                    url={DataSeo.MencionesLink}
                    name={"@apeordi"}
                    type={"article"}
                />

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Menciones</h1>
                        <h2>Sitios oficiales, noticieros y medios</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenMenciones} alt="Imagen de Unsplash"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>Reseñas nacionales e internacionales</h3>
                        <br/>
                        <span>Aquí dejo una pequeña colección de todos los medios oficiales que me han mencionado, entrevistado o respaldado.</span><br/>
                        <span><b>¿Nos dejas un comentario?</b></span><br/>
                        <br/>
                        --
                        <br/>
                        <br/>
                        <span>Si quieres agendar una entrevista, escríbeme por Twitter:</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/user?screen_name=apeordi"><img className='icon-footer' src={iconoTwitter} alt="Red social" title='Twitter' style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/>@apeordi</a>
                    </div>
                    
                </div>

                {/* Items del blog */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="filtro-pantalla-completa">
                        <span className='span-filtro-dia' title='Se muestran todas las menciones'>Menciones</span>
                    </div>

                    {/* Menciones de medios */}
                    {this.state.ListaMenciones.map((lista, i) => (

                        <BadgeMencion
                            key={i}
                            idItem={`ID${lista.urlPublica}`}

                            importancia={lista.objMencion.importancia}
                            date={lista.objMencion.date}
                            fechaString={lista.fechaString}

                            logotipo={lista.objMencion.logotipo}
                            autor={lista.objMencion.autor}
                            descripcionAutor={lista.objMencion.descripcionAutor}
                            
                            cita={lista.objMencion.cita}
                            urlCita={lista.objMencion.urlCita} />
                    ))}

                </div>


                


            </React.Fragment>


        );
    }
}


export default Menciones;