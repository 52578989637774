import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "../SesionIniciada/estilos/BadgeEntrada.css";

// Objetos personales
import Pagina from '../SesionIniciada/ObjetoPagina.js';

//Iconos importados
import iconTareaIncompleta from '../../imagenes/iconos/icon-tarea-incompleta.svg'
import iconTareaCompletada from '../../imagenes/iconos/icon-tarea-completada.svg'
import iconEditarTarea from '../../imagenes/iconos/icon-editar-tarea.svg'
// import iconActualizarTarea from '../../imagenes/iconos/icon-actualizar-tarea.svg'
// import iconCancelarActualizacionTarea from '../../imagenes/iconos/icon-cancelar-actualizacion-tarea.svg'

// import iconCompartir from '../../imagenes/iconos/icon_compartir_128px.png'
// import iconMyBio from '../../imagenes/iconos/icon_agendapdf_512px.png'

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// ------------------------

const db = getFirestore();

var objPagina = new Pagina('','','','');

// ------------------------

class BadgePagina extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // objTarea = new Entrada(this.props.uid, this.props.date, this.props.texto, this.props.leido);

        // Estado
        this.state = {
            iconCompletada: iconTareaIncompleta,
        };

        // ID de la Tarea
        // idReferencia = this.props.idReferencia;

        // const uid = this.props.uid;
        // const mUid = this.props.idCreador;

        
        // ----------------------------        

        this.handleEliminarEntrada = this.handleEliminarEntrada.bind(this);
        this.handleCompartir = this.handleCompartir.bind(this);
        this.handleVerPerfil = this.handleVerPerfil.bind(this);
    }

    /* Eventos */
    handleEliminarEntrada = (e) => {
        e.preventDefault();


        // Se cambia el estilo
        this.setState(() => {
            return {
                iconCompletada: iconTareaCompletada,
                estiloTarea: { textDecoration:'line-through', color: '#7d7d7d50' },
            }
        });

        // Se solicita confirmación
        const mensaje = `⚠ ELIMINAR\n\nEsto hará que se elimine de tu lista de páginas.`;
        const isCompletado = window.confirm(mensaje);

        if(isCompletado){

            // UID de la persona dueña de las Tareas
            const uidPersona = this.props.autor;

            // UID de la persona que creó la Tarea
            // const uidDefault = this.props.uid;

            // console.log(`Blog/${uidPersona}/Entrada/${this.props.idReferencia}`);

            // Si existe una portada, se elimina
            if(this.props.portada !== ""){

                // Separamos para mostrar una URL más amigable
                const idReferencia = this.props.idReferencia;
                const urlPrivada = "autor_" + uidPersona + "_fecha_";
                const urlPublica = idReferencia.replace(urlPrivada, "");

                const url = "Paginas/" + urlPublica + "/portada";
                // url = `Blog/${mUid}/foto-perfil`;

                const storage = getStorage();
                const portadaRef = ref(storage, url);

                // Delete the file
                deleteObject(portadaRef)
                .then(() => {

                    // La URL Privada sólo se envía al haber iniciado sesión
                    // const idReferencia = this.props.urlPrivada + this.props.urlPublica;
                    // Se elimina la entrada
                    const refEntrada = doc( db, `Paginas/${idReferencia}`).withConverter(objPagina.paginaConverter);
                    deleteDoc(refEntrada);

                    alert(`La página "${this.props.titulo}" ha sido eliminada.`);
                    // console.log(`La entrada ${this.props.titulo} ha sido eliminada.`);
                }).catch((error) => {
                    // Uh-oh, an error occurred!
                    console.log(`Ocurrió un error:\n\n ${error}`);
                });


            } else{
                
                // La URL Privada sólo se envía al haber iniciado sesión
                const idReferencia = this.props.urlPrivada + this.props.urlPublica;
                // Se elimina la entrada
                const refEntrada = doc( db, `Paginas/${idReferencia}`).withConverter(objPagina.paginaConverter);
                deleteDoc(refEntrada);

                alert(`La entrada "${this.props.titulo}" ha sido eliminada.`);
                console.log(`Blog/${uidPersona}/Entrada/${idReferencia}`);
            }

            // Actualizamos la vista
            // window.location.reload();
            
        }
        

    }

    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }
    
    handleVerPerfil = (e) => {
        e.preventDefault();

        // window.location.reload();
        // window.location.href("/"+this.props.nombreUsuario);
        // window.location.reload();
        
    } 

    /* Lo que mostramos en pantalla */
    render(){    

        return(

            <div hidden={!this.props.paginaExistente} className="BadgeEntrada" id={this.props.idReferencia} title={this.props.titulo}>

                <div hidden={this.props.public} className="BadgeEntrada__header">
                    <img className="icon-estado-tarea" src={this.state.iconCompletada} alt="" onClick={this.handleEliminarEntrada} title='Eliminar página'/>
                    <Link to={"/editar-pagina/"+this.props.url}><img className="icon-editar" src={iconEditarTarea} alt="" title='Editar página'/></Link>
                </div>

                <Link  to={"/"+this.props.url}>

                    <img hidden={!this.props.portada} className="BadgeEntrada__section-imagen-portada" src={this.props.portada} alt="" title={this.props.titulo}/>

                    <div className="BadgeEntrada__section-titulo" title={this.props.titulo} id={this.props.idReferencia + "_div"}>
                        {this.props.titulo}
                    </div>
                    <div className="BadgeEntrada__section-subtitulo" id={this.props.idReferencia + "_div"}>
                        {this.props.subtitulo}
                    </div>

                    <div hidden={!this.props.categoria} className="BadgeEntrada__section-categoria" id={this.props.idReferencia + "_div"} title="Categoría">
                        {this.props.categoria}
                    </div>

                    <textarea className="BadgeEntrada__section-texto" disabled={true} rows={4}
                        id={this.props.idReferencia + "_div"} defaultValue={this.props.texto} readOnly={true}/>

                    <div className="BadgeEntrada__horaProgramado">
                        {this.props.fechaString}
                    </div>                
                    {/* 
                    <Link className="BadgeEntrada__usuarioProgramado" to={"/"+this.props.nombreUsuario}>
                        por {this.props.nombrePublico}
                    </Link>
                    */}
                    <div className="BadgeEntrada__usuarioProgramado">
                        por {this.props.nombrePublico}
                    </div>

                </Link>

            </div>         
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgePagina;