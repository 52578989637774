import React from 'react';

import '../global.css';

// Objetos personales
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import Entrada from '../componentes/SesionIniciada/ObjetoEntrada.js';
import TarjetaAutor from '../componentes/TarjetaAutor.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";

import BadgeLeerEntrada from '../componentes/SesionIniciada/BadgeLeerEntrada.js';

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1515595967223-f9fa59af5a3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80";

// ------------------------

const db = getFirestore();

// Formato "día, fecha de mes de año"
// const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formatoFecha = { year: 'numeric', month: 'short', day: 'numeric' };
const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');
var objEntrada = new Entrada();


// ------------------------


class LeerEntrada extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {

            idReferencia: "",
            urlPublica: "",

            autor: "",
            portada: banner,

            horaString: "",
            fechaString: "",

            titulo: "",
            subtitulo: "🌐 Cargando...",
            texto: "",

            categoria: "",

            mUsuario: mUsuario
        };

        // const user = auth.currentUser;
        // mUid = auth.currentUser.uid;
        // UID del dueño
        // mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        this.getEntrada();
        this.getUsuario();
        
    }

    async getEntrada(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        if (this.props.match.url !== "" || this.props.match.url !== null){

            // const idReferencia = urlPrivada + urlPublica;
            const urlPrivada = "autor_" + mUid + "_fecha_";
            
            // Se obtiene el Nombre de Usuario de la URL
            const urlPadre = this.props.match.url;            
            const urlPublica = urlPadre.replace("/blog/", "");

            const idReferencia = urlPrivada + urlPublica;
           // console.log(idReferencia);

            const ref = doc(db, "Blog/" + mUid + "/Entrada", idReferencia).withConverter(Entrada.entradaConverter);
            const docSnap = getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                    
                // Se rellenan los datos
                objEntrada = snapshot.data();
                const titulo = objEntrada.titulo;
                document.title = titulo + " | Blog";

                // Convertimos Timestamp a Date
                const dateEntrada = objEntrada.date.toDate();

                /* NECESARIO para la tarjeta */
                // "día, fecha de mes de año"
                var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                // "hora:minuto am/pm"
                var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);

                // Se actualiza el estado
                this.setState(() => {
                    return {
                        idReferencia: idReferencia,
                        urlPublica: urlPublica,

                        autor: mUid,
                        portada: objEntrada.portada,

                        horaString: horaString,
                        fechaString: fechaString,

                        titulo: objEntrada.titulo,
                        subtitulo: objEntrada.subtitulo,
                        texto: objEntrada.texto,

                        categoria: objEntrada.categoria
                    }
                });

                if (objEntrada.portada === ""){
                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            portada: banner,
                        }
                    });
                }
                        
                return Promise.resolve(docSnap); 
            });
        
        }
    }
    async getUsuario(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            // mUid = auth.currentUser.uid;
            // UID del dueño
            // const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                // Se actualiza el estado
                this.setState(() => {
                    return {
                        mUsuario: mUsuario
                    }
                });
                    
                return Promise.resolve(docSnap);
            });
        }
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        // document.title = "Entrada | Blog";
        return (


            <React.Fragment>

                <div id="visor" theme={this.props.theme}>

                    {/* Sección de la Entrada */}
                    <BadgeLeerEntrada
                        theme={this.props.theme}

                        idReferencia = {this.state.idReferencia}
                        urlPublica = {this.state.urlPublica}

                        autor = {this.state.autor}
                        portada = {this.state.portada}

                        horaString= {this.state.horaString}
                        fechaString= {this.state.fechaString}

                        titulo = {this.state.titulo}
                        subtitulo = {this.state.subtitulo}
                        texto = {this.state.texto}

                        categoria = {this.state.categoria}

                        rutaFotoPerfil = {this.state.mUsuario.rutaFotoPerfil}
                        nombrePublico = {this.state.mUsuario.nombrePublico}
                        nombreUsuario = {this.state.mUsuario.nombreUsuario}
                        verificado = {this.state.mUsuario.verificado}
                    />

                    {/* Divider */}
                    <hr className="divider-naranja"  theme={this.props.theme}/>

                    {/* Sección del Autor */}
                    <div theme={this.props.theme}>
                        <h2 style={{ padding: '4% 4% 2%', color: '#00000070' }}><small>Información del autor</small></h2>
                        <TarjetaAutor
                            theme={this.props.theme}

                            verificado = {this.state.mUsuario.verificado}

                            rutaFotoPerfil = {this.state.mUsuario.rutaFotoPerfil}
                            nombrePublico = {this.state.mUsuario.nombrePublico}
                            puesto = {this.state.mUsuario.puesto}
                            biografia = {this.state.mUsuario.biografia}
                            
                            facebook = {this.state.mUsuario.redFacebook}
                            instagram = {this.state.mUsuario.redInstagram}
                            linkedin = {this.state.mUsuario.redLinkedin}
                            twitter = {this.state.mUsuario.redTwitter}
                        />
                    </div>
                </div>

            </React.Fragment>


        );
    }
}

export default LeerEntrada;