// Para el titulo de la página
const separador = " | ";
// const nombrePagina = "Adolfo Ordinola";
const nombrePagina = "Apeordi";

const MetaTitulo = (tituloString) => {

  var titulo = "";

  if (tituloString !== ""){

    titulo = tituloString + separador + nombrePagina;

  } else {

    titulo = "Apeordi";
  }

  
  return titulo;
};

export default MetaTitulo;