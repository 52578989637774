import React from 'react';

import SEO from '../../componentes/Main/SEO.js';
import DataSeo from '../../constantes/DataSeo.js';

import '../../global.css';

import BadgeEntradas from '../../componentes/SesionIniciada/BadgeEntrada.js';
import Entrada from '../../componentes/SesionIniciada/ObjetoEntrada.js';

import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";

import iconoTwitter from '../../imagenes/iconos/icon-twitter.svg';
// import iconoFacebook from '../../imagenes/iconos/icon-facebook.svg';

const banner = DataSeo.BlogImage;
const imagenBlog = "https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";
// const imagen = "";

// --------------

const db = getFirestore();
const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";


// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// --------------

class Blog extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            ListaTareas:[],
        };

        this.getListaEntradasBlog();
    }

    // ENTRADAS ----------------------
    async getListaEntradasBlog(){
        
        // Creamos una lista local
        let lista = [];

        const referenciaComentarios = collection(db, `Blog/${mUid}/Entrada`);

        // Create a query against the collection.
        const q = query(referenciaComentarios, orderBy("date", "desc")).withConverter(Entrada.entradaConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    // Variable de tipo Entrada.class
                    var objEntrada = new Entrada('','','','','','','','','','');
                    objEntrada = documento.data();

                    let idReferencia = documento.id;

                    let autor = objEntrada.autor;
                    let date = objEntrada.date;

                    // Convertimos Timestamp a Date
                    const dateEntrada = date.toDate();

                    /* NECESARIO para la tarjeta */
                    // "día, fecha de mes de año"
                    var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                    // "hora:minuto am/pm"
                    // var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);


                    // Separamos para mostrar una URL más amigable
                    const urlPrivada = "autor_" + autor + "_fecha_";
                    const urlPublica = idReferencia.replace(urlPrivada, ""); 


                    var nombrePublico = "Adolfo Ordinola";
                    var nombreUsuario = "adolordi";

                    // Creamos un objeto local
                    let objeto = {idReferencia, urlPrivada, urlPublica, objEntrada, fechaString, nombrePublico, nombreUsuario };

                    // Agregamos el objeto local a la lista local
                    lista.push(objeto);

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            idReferencia: idReferencia,
                            ListaTareas: lista
                        }
                    });
                });

            if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaTareas: lista
                        }
                    });

            }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    render() {
        return (
            <React.Fragment>

                {/* SEO */}
                <SEO
                    title={DataSeo.BlogTitle}
                    description={DataSeo.BlogDescription}
                    image={banner}
                    url={DataSeo.BlogLink}
                    name={"@apeordi"}
                    type={"article"}
                />

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Blog</h1>
                        <h2>Consejos, tips y experiencias</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenBlog} alt="Imagen de Unsplash"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>Temas del día a día</h3>
                        <br/>
                        <span>Aquí escribo a profundidad sobre temas de tecnología o sobre curiosidades que están sucediendo en el mundo.</span><br/>
                        <span><b>¿Nos dejas un comentario?</b></span><br/>
                        <br/>
                        --
                        <br/>
                        <br/>
                        <span>Si quieres que hable sobre algún tema, escríbeme por Twitter:</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/user?screen_name=apeordi"><img src={iconoTwitter} alt="Red social" title='Twitter' style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/>@apeordi</a>
                    </div>
                    
                </div>

                {/* Items del blog */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="filtro-pantalla-completa">
                        <span className='span-filtro-dia' title='Se muestran todas las entradas'>Entradas del blog</span>
                    </div>

                    {/* Entradas del blog */}
                    {this.state.ListaTareas.map((lista, i) => (

                        <BadgeEntradas
                            theme={this.props.theme}
                            
                            // key={lista.id}
                            key={i}
                            idItem={`ID${lista.idReferencia}`}
                            
                            idReferencia={lista.idReferencia}
                            urlPrivada={lista.urlPrivada}
                            urlPublica={lista.urlPublica}

                            public={true}
                            
                            autor={lista.objEntrada.autor}
                            date={lista.objEntrada.date}
                            privacidad={lista.objEntrada.privacidad}
                            // privacidad={""}
                            categoria={lista.objEntrada.categoria}

                            portada={lista.objEntrada.portada}
                            titulo={lista.objEntrada.titulo}
                            subtitulo={lista.objEntrada.subtitulo}
                            texto={lista.objEntrada.texto}
                            
                            fechaString={lista.fechaString}
                            nombrePublico={lista.nombrePublico}
                            nombreUsuario={lista.nombreUsuario} />
                    ))}

                </div>


                


            </React.Fragment>


        );
    }
}


export default Blog;