import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import SEO from "../../componentes/Main/SEO.js";
import DataSeo from "../../constantes/DataSeo.js";

// import Typed from 'react-typed';
// import ReactTyped from 'react-typed';

import "../../global.css";
import ".././estilos/BadgeMain.css";

import BadgeEntradas from "../../componentes/SesionIniciada/BadgeEntrada.js";
import BadgeMencion from "../../componentes/Main/BadgeMencion.js";
import Entrada from "../../componentes/SesionIniciada/ObjetoEntrada.js";
import Mencion from "../../componentes/Objetos/ObjetoMencion.js";
// import Usuario from '../../componentes/SesionIniciada/ObjetoUsuario.js';

import {
  getFirestore,
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  doc,
} from "firebase/firestore";
// import { getDoc, doc } from "firebase/firestore";

import imagenPrincipal from "../../imagenes/Adolfo Ordinola.png";
import iconoLinkedin from "../../imagenes/iconos/icon-linkedin.svg";
import iconoFacebook from "../../imagenes/iconos/icon-facebook.svg";
import iconoTwitter from "../../imagenes/iconos/icon-twitter.svg";


const imagenSuscripcion =
  "https://images.unsplash.com/photo-1519336305162-4b6ed6b6fc83?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
const imagenUsabilidad =
  "https://images.unsplash.com/photo-1484807352052-23338990c6c6?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";

// --------------

// var descripcion = "";

const db = getFirestore();
const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

// Formato "día, fecha de mes de año"
// const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formatoFecha = {
  weekday: "long",
  year: "numeric",
  month: "short",
  day: "numeric",
};
// const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// --------------

class Inicio extends React.Component {
  /* Datos iniciales */
  constructor(props) {
    super(props);

    // Se muestran datos provisionales
    this.state = {
      idReferencia: "",
      ListaMenciones: [],
      ListaEntradasBlog: [],
    };

    this.getListaMenciones();
    this.getListaEntradasBlog();
  }

  // ENTRADAS ----------------------
  async getListaMenciones() {
    // Creamos una lista local
    let lista = [];

    const referenciaMenciones = collection(db, `Menciones`);

    // Create a query against the collection.
    const q = query(
      referenciaMenciones,
      orderBy("date", "desc"),
      limit(6)
    ).withConverter(Mencion.mencionConverter);
    // const q = query(referenciaMenciones, where("importancia", "==", "📌 Fijar en inicio"), orderBy("date", "desc"), limit(6)).withConverter(Mencion.mencionConverter);
    const querySnapshot = getDocs(q);

    // ---------------------

    querySnapshot
      .then((snapshot) => {
        snapshot.forEach((documento) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          // Variable de tipo Entrada.class
          var objMencion = new Mencion("", "", "", "", "", "", "");
          objMencion = documento.data();

          // let idReferencia = documento.id;

          // Extraemos la URL/id
          const urlPublica = documento.id;

          let date = objMencion.date;

          // Convertimos Timestamp a Date
          const dateMencion = date.toDate();

          /* NECESARIO para la tarjeta */
          // "día, fecha de mes de año"
          var fechaString = dateMencion.toLocaleDateString(
            "es-MX",
            formatoFecha
          );

          let logotipo = objMencion.logotipo;
          let autor = objMencion.autor;
          let descripcionAutor = objMencion.descripcionAutor;

          let cita = objMencion.cita;
          let urlCita = objMencion.urlCita;

          // Creamos un objeto local
          let objeto = {
            urlPublica,
            objMencion,
            fechaString,
            logotipo,
            autor,
            descripcionAutor,
            cita,
            urlCita,
          };

          // Agregamos el objeto local a la lista local
          lista.push(objeto);

          // Se actualiza el estado
          this.setState(() => {
            return {
              ListaMenciones: lista,
            };
          });
        });

        if (snapshot.empty) {
          //No se devuelve un Objeto Tarea
          this.setState(() => {
            return {
              ListaMenciones: lista,
            };
          });
        }
      })
      .catch((error) => {
        // An error happened.
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(
          "No se pudo realizar la búsqueda: " +
            errorCode +
            "\n\n" +
            errorMessage
        );
      });
  }

  // ENTRADAS ----------------------
  async getListaEntradasBlog() {
    // Creamos una lista local
    let lista = [];

    const referenciaComentarios = collection(db, `Blog/${mUid}/Entrada`);

    // Create a query against the collection.
    // const q = query(referenciaComentarios, orderBy("date", "desc"), limit(5)).withConverter(Entrada.entradaConverter);
    const q = query(
      referenciaComentarios,
      where("privacidad", "==", "🌎 Entrada pública"),
      orderBy("date", "desc"),
      limit(3)
    ).withConverter(Entrada.entradaConverter);
    const querySnapshot = getDocs(q);

    // ---------------------

    querySnapshot
      .then((snapshot) => {
        snapshot.forEach((documento) => {
          // doc.data() is never undefined for query doc snapshots
          // console.log(doc.id, " => ", doc.data());

          // Variable de tipo Entrada.class
          var objEntrada = new Entrada("", "", "", "", "", "", "", "", "");
          objEntrada = documento.data();

          let idReferencia = documento.id;

          let autor = objEntrada.autor;
          let date = objEntrada.date;

          // Convertimos Timestamp a Date
          const dateEntrada = date.toDate();

          /* NECESARIO para la tarjeta */
          // "día, fecha de mes de año"
          var fechaString = dateEntrada.toLocaleDateString(
            "es-MX",
            formatoFecha
          );
          // "hora:minuto am/pm"
          // var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);

          // Separamos para mostrar una URL más amigable
          const urlPrivada = "autor_" + autor + "_fecha_";
          const urlPublica = idReferencia.replace(urlPrivada, "");

          var nombrePublico = "Adolfo Ordinola";
          var nombreUsuario = "blog";

          // Creamos un objeto local
          let objeto = {
            idReferencia,
            urlPrivada,
            urlPublica,
            objEntrada,
            fechaString,
            nombrePublico,
            nombreUsuario,
          };

          // Agregamos el objeto local a la lista local
          lista.push(objeto);

          /*
                    var objetoUsuario = new Usuario('','','','','','','','');
                    const ref = doc(db, "Usuarios", autor).withConverter(objetoUsuario.usuarioConverter);
                    const docSnap =  getDoc(ref);

                    // Se piden los datos
                    docSnap.then((snapshot) => {
                                                    
                        objetoUsuario = snapshot.data();
                        nombrePublico = objetoUsuario.nombrePublico;
                        nombreUsuario = objetoUsuario.nombreUsuario;

                        // Creamos un objeto local
                        let objeto = {idReferencia, autor, date, portada, titulo, subtitulo, texto, horaString, nombrePublico, nombreUsuario };

                        // Agregamos el objeto local a la lista local
                        lista.push(objeto);

                        // Se actualiza el estado
                        this.setState(() => {
                            return {
                                idReferencia: idReferencia,
                                ListaEntradasBlog: lista
                            }
                        });
                    });
                    */

          // Se actualiza el estado
          this.setState(() => {
            return {
              idReferencia: idReferencia,
              ListaEntradasBlog: lista,
            };
          });
        });

        if (snapshot.empty) {
          //No se devuelve un Objeto Tarea
          this.setState(() => {
            return {
              idReferencia: "",
              ListaEntradasBlog: lista,
            };
          });
        }
      })
      .catch((error) => {
        // An error happened.
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(
          "No se pudo realizar la búsqueda: " +
            errorCode +
            "\n\n" +
            errorMessage
        );
      });
  }

  render() {
    // document.title = this.props.title;
    return (
      <React.Fragment>

        {/* SEO */}
        <SEO
          title={DataSeo.MainTitle}
          description={DataSeo.MainDescription}
          image={DataSeo.MainImage}
          url={DataSeo.MainLink}
          name={"@apeordi"}
          type={"article"}
        />

        {/* Portada */}
        <div
          className="BadgeMain__portada"
          theme={this.props.theme}
        >
          {/* Título */}
          <div className="header-dos-tercios-pantalla">
            <h1 className="BadgeMain__titulo">
              <span className="color_naranja">A</span>dolfo
              <span className="color_naranja"> Pé</span>rez
              <span className="color_naranja"> Ordi</span>nola
            </h1>

            <div className="BadgeMain__subtitulo">Desarrollador Android y Web</div>

            <div className="BadgeMain__enlaces">
              <a
                href="https://linkedin.com/in/apeordi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icon"
                  src={iconoLinkedin}
                  alt="Icono"
                  title="LinkedIn"
                />
              </a>
              <a
                href="https://facebook.com/apeordi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icon"
                  src={iconoFacebook}
                  alt="Icono"
                  title="Facebook"
                />
              </a>
              <a
                href="https://twitter.com/intent/user?screen_name=apeordi"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className="icon"
                  src={iconoTwitter}
                  alt="Icono"
                  title="Twitter"
                />
              </a>
              <span>  |  apeordi.com</span>
            </div>
          </div>

          {/* Imagen */}
          <div className="header-un-tercio-pantalla">
            <img
              className="BadgeMain__imagen-media-pantalla"
              src={imagenPrincipal}
              alt="Imagen de inicio"
              title="Adolfo Pérez Ordinola"
            />
          </div>
        </div>

        {/* Proyectos principales */}
        <div className="header-pantalla-completa" theme={this.props.theme}>
          <div className="filtro-pantalla-completa">
            <Link
              to="/portafolio"
              className="span-filtro-dia"
              title="Ver el portafolio"
            >
              Principales proyectos
            </Link>
          </div>

          {/* Entradas del blog */}
          {this.state.ListaEntradasBlog.map((lista, i) => (
            <BadgeEntradas
              theme={this.props.theme}
              // key={lista.id}
              key={i}
              idItem={`ID${lista.idReferencia}`}
              idReferencia={lista.idReferencia}
              urlPrivada={lista.urlPrivada}
              urlPublica={lista.urlPublica}
              public={true}
              autor={lista.objEntrada.autor}
              date={lista.objEntrada.date}
              portada={lista.objEntrada.portada}
              titulo={lista.objEntrada.titulo}
              subtitulo={lista.objEntrada.subtitulo}
              categoria={lista.objEntrada.categoria}
              texto={lista.objEntrada.texto}
              fechaString={lista.fechaString}
              nombrePublico={lista.nombrePublico}
              nombreUsuario={lista.nombreUsuario}
            />
          ))}
        </div>

        <div className="divider" theme={this.props.theme} />

        {/* Textos */}
        <div className="header-pantalla-completa" theme={this.props.theme}>
          <div className="header-media-pantalla-blanca">
            <h3>Creador de contenido</h3>
            <br />
            <span>
              <b>¿Qué hago?</b>
            </span>
            <br />
            <span>Construyo apps, plataformas y comunidades GRATIS.</span>
            <br />
            <span>
              Desde entornos médicos, apuntes digitales de ingeniería y más
              contenido de valor.
            </span>
            <br />
            <br />
            <span>
              <b>¿Por qué lo hago gratis?</b>
            </span>
            <br />
            <span>
              Por que todos merecemos las mismas oportunidades para competir.
            </span>
            <br />
            <br />
            <Link to="/apuntes">Ver apuntes</Link>
            <br />
          </div>

          <img
            className="imagen-media-pantalla"
            src={imagenSuscripcion}
            alt="Imagen de Unsplash"
          />
        </div>

        {/* Textos */}
        <div className="header-pantalla-completa" theme={this.props.theme}>
          <img
            className="imagen-media-pantalla"
            src={imagenUsabilidad}
            alt="Imagen de Unsplash"
          />

          <div className="header-media-pantalla-blanca">
            <h3>Experiencia</h3>
            <br />
            <span>
              • +5 años como desarrollador <b>Android</b> (en <b>Java</b> y{" "}
              <b>Kotlin</b>).
            </span>
            <br />
            <span>
              • +2 años como desarrollador <b>Web</b> (con <b>React.js</b>).
            </span>
            <br />
            <br />
            <span>He creado desde cero proyectos internacionales.</span>
            <br />
            <Link to="/portafolio">Ver portafolio</Link>
            <br />
            <Link to="/portafolio">Ver curriculum (inglés)</Link>
            <br />
          </div>
        </div>

        <div className="divider" theme={this.props.theme} />

        {/* Menciones destacadas */}
        <div className="header-pantalla-completa" theme={this.props.theme}>
          <div className="filtro-pantalla-completa">
            <Link
              to="/menciones"
              className="span-filtro-dia"
              title="Ver todas las menciones"
            >
              Menciones destacadas
            </Link>
          </div>

          {/* Menciones de medios */}
          {this.state.ListaMenciones.map((lista, i) => (
            <BadgeMencion
              key={i}
              idItem={`ID${lista.urlPublica}`}
              // importancia={lista.objMencion.importancia}
              // date={lista.objMencion.date}
              fechaString={lista.fechaString}
              logotipo={lista.logotipo}
              autor={lista.autor}
              descripcionAutor={lista.descripcionAutor}
              cita={lista.cita}
              urlCita={lista.urlCita}
            />
          ))}
        </div>

        <div className="divider" theme={this.props.theme} />

        {/* Últimas entradas del blog */}
        <div className="header-pantalla-completa" theme={this.props.theme}>
          <div className="filtro-pantalla-completa">
            <Link to="/blog" className="span-filtro-dia" title="Ir al blog">
              Últimas entradas del blog
            </Link>
          </div>

          {/* Entradas del blog */}
          {this.state.ListaEntradasBlog.map((lista, i) => (
            <BadgeEntradas
              // key={lista.id}
              key={i}
              idItem={`ID${lista.idReferencia}`}
              idReferencia={lista.idReferencia}
              urlPrivada={lista.urlPrivada}
              urlPublica={lista.urlPublica}
              public={true}
              autor={lista.objEntrada.autor}
              date={lista.objEntrada.date}
              portada={lista.objEntrada.portada}
              titulo={lista.objEntrada.titulo}
              subtitulo={lista.objEntrada.subtitulo}
              categoria={lista.objEntrada.categoria}
              texto={lista.objEntrada.texto}
              fechaString={lista.fechaString}
              nombrePublico={lista.nombrePublico}
              nombreUsuario={lista.nombreUsuario}
            />
          ))}
        </div>

        <div className="divider" theme={this.props.theme} />
      </React.Fragment>
    );
  }
}

export default Inicio;
