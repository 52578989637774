import React from 'react'

import '../global.css';
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js'
import BadgeEditarPerfil from '../componentes/SesionIniciada/BadgeEditarPerfil.js';

import { getAuth, onAuthStateChanged } from "firebase/auth";
import { doc } from "firebase/firestore";
import { getFirestore } from 'firebase/firestore';
import { getDoc } from 'firebase/firestore';

const auth = getAuth();
const db = getFirestore();

// ------------------------

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','','','','','');

// ------------------------

onAuthStateChanged(auth, (user) => {
    if (user) {
      
        // https://firebase.google.com/docs/reference/js/firebase.User

        const mUid = auth.currentUser.uid;
        async function funcionando() {

            const ref = doc(db, "Usuarios", mUid).withConverter(mUsuario.usuarioConverter);
            const docSnap =  await getDoc(ref);

            if (docSnap.exists()) {

                // Convert to Usuario object
                mUsuario = docSnap.data();

                // Use a Usuario instance method
                // console.log("Usuario: " + mUsuario.toString());

            } else {
                console.log("No se pudo acceder");
            }
            
            return Promise.resolve(docSnap);
        }

        // Llamamos a la función
        funcionando();

    } else {

      // El usuario cerró sesión
      // alert("Hasta luego, @" + mUsuario.nombreUsuario + " 😊");

    }
});

class EditarPerfil extends React.Component{

    /* Datos iniciales */
    state = { form: {    

        admin: mUsuario.admin,
        suscrito: mUsuario.suscrito,
        verificado: mUsuario.verificado,
        
        nombrePublico: mUsuario.nombrePublico,
        nombreUsuario: mUsuario.nombreUsuario,
        
        puesto: mUsuario.puesto,
        biografia: mUsuario.biografia,
        rutaFotoPerfil: mUsuario.rutaFotoPerfil,

        redFacebook: mUsuario.redFacebook,
        redInstagram: mUsuario.redInstagram,
        redLinkedin: mUsuario.redLinkedin,
        redTwitter: mUsuario.redTwitter
    }};

    /* Eventos */
    handleChange = e => {

        this.setState({  
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    };

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>


                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla">
                        <br />
                        <span className='span-centrado-light'>Edita tu perfil</span>
                        <span>Tu <b>Nombre Público</b> es el que se mostrará a todos.</span><br/>
                        <br/>
                        <span>Puedes agregar una breve biografía para que te reconozcan tus contactos. Incluso, puedes agregar tu puesto de trabajo.</span><br/>
                        <br/>
                        <span>Recuerda que todos tus datos siempre están protegidos con servidores de Google <span role='img' aria-label='🔐'/>🔐</span><br/>
                        <br/>
                        <br/>
                        
                    </div>
                    
                    <div className="header-media-pantalla">
                        
                        <BadgeEditarPerfil
                            theme={this.props.theme}

                            admin={this.state.form.admin}
                            suscrito={this.state.form.suscrito}                            
                            verificado={this.state.form.verificado}

                            nombrePublico={this.state.form.nombrePublico}
                            nombreUsuario={this.state.form.nombreUsuario}
                            puesto={this.state.form.puesto}
                            biografia={this.state.form.biografia}
                            rutaFotoPerfil={this.state.form.rutaFotoPerfil}                                

                            redFacebook={this.state.form.redFacebook}
                            redInstagram={this.state.form.redInstagram}
                            redLinkedin={this.state.form.redLinkedin}
                            redTwitter={this.state.form.redTwitter}
                        />
                        
                    </div>

                </div>


            </React.Fragment>


        );
    }
}


export default EditarPerfil;