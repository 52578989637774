import React from 'react';
import { Link } from 'react-router-dom';

import '../Main/estilos/NavbarPublicar.css';

import iconImagenUsuario from '../../imagenes/iconos/icon_iniciar_sesion_256px.png';
import iconCrearEntrada from '../../imagenes/iconos/icon-crear-entrada.svg';
import iconCrearPagina from '../../imagenes/iconos/icon-crear-pagina.svg';
import iconCrearProyecto from '../../imagenes/iconos/icon-crear-proyecto.svg';

// Objetos personales
import Usuario from '../SesionIniciada/ObjetoUsuario.js';

// ------------------------

// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;
var rutaFotoPerfil;

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

class NavbarPublicar extends React.Component {

    constructor(props) {
        super(props)
        
        this.getUsuario();
    }

    async getUsuario(){

        /* Datos iniciales */
        mUid = auth.currentUser.uid;
        rutaFotoPerfil = "";
        
        // console.log(this.props.nombrePublico);
    
        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){
    
            mUid = auth.currentUser.uid;
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);
    
            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                rutaFotoPerfil = mUsuario.rutaFotoPerfil;
                // console.log("Ruta de imagen: \n\n" + rutaFotoPerfil);
    
                if (rutaFotoPerfil === ""){
    
                    rutaFotoPerfil = iconImagenUsuario;            
                }
                    
                return Promise.resolve(docSnap);
            });
    
        }  
      
        
        // return rutaFotoPerfil;
    };

    render() {
        return (
            <React.Fragment>
                <nav className="Navbar__publicar" theme={this.props.theme}>

                    <div className="Navbar__publicar-lista-iconos">
                            
                        <div className="Navbar__publicar-lista-iconos-top">
                            <div className="Navbar__publicar-icon-imagen"><Link to="/perfil" title="Perfil"><img src={mUsuario.rutaFotoPerfil} alt=""/></Link></div>
                            <Link className="Navbar__publicar-link" to="/crear-post">¿Qué estás pensando?</Link>
                        </div>

                        <div className="Navbar__publicar-lista-iconos-bottom">
                            <Link className="Navbar__publicar-link-bottom" to="/crear-entrada" title="Crear entrada del blog"><img className="Navbar__publicar-icon-bottom" src={iconCrearEntrada} alt=""/>Entrada</Link>
                            <Link className="Navbar__publicar-link-bottom" to="/crear-pagina" title="Crear página"><img className="Navbar__publicar-icon-bottom" src={iconCrearPagina} alt=""/>Página</Link>
                            <Link className="Navbar__publicar-link-bottom" to="/crear-proyecto" title="Crear proyecto"><img className="Navbar__publicar-icon-bottom" src={iconCrearProyecto} alt=""/>Proyecto</Link>
                            <Link className="Navbar__publicar-link-bottom" to="/crear-proyecto" title="Crear meme"><img className="Navbar__publicar-icon-bottom" src={iconCrearProyecto} alt=""/>Meme</Link>
                        </div>
                        
                    </div>
                </nav>
        </React.Fragment>
        )
    };

    handleDelete() {
        this.props.onDelete(this.props.char);
    }
}

export default NavbarPublicar;