import React from 'react';
import ReactDOM from 'react-dom';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeComentarios.css";

// Objetos personales
import Tarea from './ObjetoTarea.js';

//Iconos importados
import iconTareaIncompleta from '../../imagenes/iconos/icon-tarea-incompleta.svg'
import iconTareaCompletada from '../../imagenes/iconos/icon-tarea-completada.svg'
import iconEditarTarea from '../../imagenes/iconos/icon-editar-tarea.svg'
import iconActualizarTarea from '../../imagenes/iconos/icon-actualizar-tarea.svg'
import iconCancelarActualizacionTarea from '../../imagenes/iconos/icon-cancelar-actualizacion-tarea.svg'

// import iconCompartir from '../../imagenes/iconos/icon_compartir_128px.png'
// import iconMyBio from '../../imagenes/iconos/icon_agendapdf_512px.png'

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { setDoc, doc } from "firebase/firestore";
import { deleteDoc } from "firebase/firestore";

// ------------------------

const db = getFirestore();

var objTarea = new Tarea('','','','');

// ------------------------

class BadgeTareas extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        objTarea = new Tarea(this.props.uid, this.props.date, this.props.texto, this.props.leido);

        // Estado
        this.state = {
            iconCompletada: iconTareaIncompleta,
            estiloTarea: { textDecoration: 'none' },

            isEditando: false,
            iconEditando: iconEditarTarea,
            titleEditando: "Editar tarea",
        };

        // ID de la Tarea
        // idReferencia = this.props.idReferencia;

        // const uid = this.props.uid;
        // const mUid = this.props.idCreador;

        
        // ----------------------------        

        this.handleTareaCompletada = this.handleTareaCompletada.bind(this);
        this.handleEditarTarea = this.handleEditarTarea.bind(this);
        this.handleActualizarTarea = this.handleActualizarTarea.bind(this);
        this.handleCompartir = this.handleCompartir.bind(this);
        this.handleVerPerfil = this.handleVerPerfil.bind(this);
    }

    /* Eventos */
    handleTareaCompletada = (e) => {
        e.preventDefault();


        // Se cambia el estilo
        this.setState(() => {
            return {
                iconCompletada: iconTareaCompletada,
                estiloTarea: { textDecoration:'line-through', color: '#7d7d7d50' },
            }
        });

        // Se solicita confirmación
        const mensaje = `¿Marcar como completado?\n\nEsto hará que se elimine de tu lista de pendientes.`;
        const isCompletado = window.confirm(mensaje);

        if(isCompletado){

            // UID de la persona dueña de las Tareas
            const uidPersona = this.props.uidPersona;

            // UID de la persona que creó la Tarea
            // const uidDefault = this.props.uid;

            // Se elimina la tarea
            const ref = doc( db, `Tareas/${uidPersona}/Mensaje/${this.props.idReferencia}`).withConverter(objTarea.tareaConverter);
            deleteDoc(ref);

            // Actualizamos la vista
            // window.location.reload();
            
        }
        

    }

    handleEditarTarea = (e) => {
        e.preventDefault();
        

        // Convertimos Timestamp a Date
        const dateTarea = this.props.date.toDate();

        // Separamos el date en Hora y Fecha
        const horaString = ("0" + dateTarea.getHours()).slice(-2) + ":" + ("0" + dateTarea.getMinutes()).slice(-2);
        const fechaString = (dateTarea.getFullYear()) + "-" + ("0"+(dateTarea.getMonth()+1)).slice(-2) + "-" + ("0" + dateTarea.getDate()).slice(-2);

        // --------------------

        // Primer click, se entra al modo de edición
        if(this.state.isEditando === false){

            // Se cambia el estilo
            this.setState(() => {
                return {
                    isEditando: true,
                    iconEditando: iconCancelarActualizacionTarea,
                    titleEditando: "Cancelar actualización de tarea",
                }
            });

            // Se cambia el div
            const SeccionEditable = () => (
                <form onSubmit={this.handleActualizarTarea}>
                    <textarea className="BadgeComentarios__editar-texto" style={this.state.estiloTarea} name="tarea" id={this.props.idReferencia + "_div"} defaultValue={this.props.texto}/>
                    <h6>Hora: </h6><input className="BadgeComentarios__editar-input-fecha" type="time" name="hora" id="hora" title="Elige otra hora" defaultValue={horaString}/>
                    <h6>Fecha: </h6><input className="BadgeComentarios__editar-input-fecha" type="date" name="fecha" id="fecha" title="Elige otra fecha" defaultValue={fechaString}/>

                    {/* Segundo click, se actualizan los datos */}
                    <button className="btn btn-actualizar-tarea">Actualizar <img className="icon-actualizar" src={iconActualizarTarea} alt="" title={"Actualizar"}/></button>
                </form>
            );

            // Se renderiza
            ReactDOM.render(<SeccionEditable />, document.getElementById(this.props.idReferencia + "_div"));            
        
        } else {

            // Se cambia el estilo
            this.setState(() => {
                return {
                    isEditando: false,
                    iconEditando: iconEditarTarea,
                    titleEditando: "Editar tarea",
                }
            });

            // Se cambia el div
            const SeccionEditable = () => (
                <div className="BadgeComentarios__section-texto" style={this.state.estiloTarea} id={this.props.idReferencia + "_div"}>
                    {this.props.texto}
                </div>
            );

            // Se renderiza
            ReactDOM.render(<SeccionEditable />, document.getElementById(this.props.idReferencia + "_div"));
        }
    
    }

    handleActualizarTarea = (e) => {
        e.preventDefault();
        
        let tareaNueva = e.target.elements.tarea.value;
        let fechaNueva = e.target.elements.fecha.value;
        let horaNueva = e.target.elements.hora.value;

        // ---------------------

        // UID de la persona dueña de las Tareas
        const uidPersona = this.props.uidPersona;

        // UID de la persona que creó la Tarea
        const uidDefault = this.props.uid;

        // Creamos una variable de tipo Date
        const fecha = new Date();
                        
        // Separamos los datos de la fecha nueva
        const [anio, mes, dia] = fechaNueva.split("-");
        const value1 = Number(anio);
        const value2 = (Number(mes) - 1); // Enero inicia en 0
        const value3 = Number(dia);
        fecha.setDate(value3);
        fecha.setMonth(value2);
        fecha.setFullYear(value1);
        
        // Separamos los datos de la hora
        const [hora, minuto] = horaNueva.split(":");
        const val1 = Number(hora);
        const val2 = Number(minuto);
        fecha.setHours(val1);
        fecha.setMinutes(val2);

        // Cargamos el objeto
        objTarea = new Tarea(uidDefault, fecha, tareaNueva, false);

        // Se cargan los cambios
        const referenciaTareas = doc(db, `Tareas/${uidPersona}/Mensaje`, this.props.idReferencia).withConverter(objTarea.tareaConverter);
        setDoc(referenciaTareas, objTarea)
        .then(() => {

            // Actualizamos la vista


            // Se reinicia el estilo
            this.setState(() => {
                return {
                    isEditando: false,
                    iconEditando: iconEditarTarea,
                    titleEditando: "Editar tarea",
                }
            });

            // Se cambia el div
            const SeccionEditable = () => (
                <div className="BadgeComentarios__section-texto" style={this.state.estiloTarea} id={this.props.idReferencia + "_div"}>
                    {tareaNueva}
                </div>
            );

            // Se renderiza
            ReactDOM.render(<SeccionEditable />, document.getElementById(this.props.idReferencia + "_div"));

            // Mensaje de confirmación
            const mensaje = `¡Tarea actualizada!\n\n 📅 Muévete entre los días para ver los cambios\n ♻ O actualiza la página`;
            alert(mensaje);


        }).catch((error) => {
            // An error happened.
            console.log("No se pudo actualizar la tarea:\n" + error);
        });;

        
    }

    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }
    
    handleVerPerfil = (e) => {
        e.preventDefault();

        // window.location.reload();
        // window.location.href("/"+this.props.nombreUsuario);
        // window.location.reload();
        
    } 

    /* Lo que mostramos en pantalla */
    render(){    

        return(

            <div className="BadgeComentarios" id={this.props.idReferencia}>

                <div className="BadgeComentarios__header">
                    <img className="icon-estado-tarea" src={this.state.iconCompletada} alt="" onClick={this.handleTareaCompletada} title='Marcar como completado'/>
                    <img className="icon-editar" src={this.state.iconEditando} alt="" onClick={this.handleEditarTarea} title={this.state.titleEditando}/>
                </div>

                <div className="BadgeComentarios__section-texto" style={this.state.estiloTarea} id={this.props.idReferencia + "_div"}>
                    {this.props.texto}
                </div>

                <div hidden={this.state.isEditando} className="BadgeComentarios__horaProgramado">
                    Programado a las {this.props.horaString}
                </div>
                <Link hidden={this.state.isEditando} className="BadgeComentarios__usuarioProgramado" to={"/"+this.props.nombreUsuario}>
                    por {this.props.nombrePublico}
                </Link>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeTareas;