import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import './estilos/Navbar.css';

// import logoPrincipal from '../../imagenes/iconos/icon-logo-web.svg';
import logoPrincipal from '../../imagenes/iconos/icon-logo-apeordi.png';
import iconMenu from '../../imagenes/iconos/icon-menu.svg';

import Sidebar from './SidebarMenu.js';


// ------------------------

const Navbar = ({ setIsOpen, theme }) => (

    <nav className="Navbar" theme={theme}>

        {/* Nombre de la página */}
        <Link className="Navbar__brand" to="/" title='Ir al inicio'>
            <img className="Navbar__brand-logo" src={logoPrincipal} alt="Logo"/>
            <Link className="Navbar__fuente" to="/" title='Ir al inicio'>Apeordi</Link>
        </Link>

        <div className="lista-iconos">
            <Link className="link-menu" to="/portafolio">Portafolio</Link>
            <Link className="link-menu" to="/blog">Blog</Link>
            <Link className="link-menu" to="/sobre-mi">Sobre mí</Link>
            <Link className="link-menu-principal" to="/contacto">Contacto</Link>
            <button className="navbar-icon-oculto" onClick={() => setIsOpen(true)} title="Menú"><img src={iconMenu} alt="Botón" width="20" height="20"/></button>
        </div>
    </nav>
);

const Overlay = ({ isOpen, setIsOpen }) => (

  <div className={`overlay ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)} />

);

const ModalDerecho = ({ isOpen , setIsOpen, theme }) => (
    
    <div className={`modal-derecho ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)} theme={theme}>
        
        <Sidebar />
      
    </div>
);

export const App = (props) => {

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div>
      <Navbar setIsOpen={setIsOpen} theme={props.theme}/>
      <Overlay isOpen={isOpen} setIsOpen={setIsOpen} />

      <ModalDerecho isOpen={isOpen} setIsOpen={setIsOpen} theme={props.theme}/>
    </div>
  );
  
};