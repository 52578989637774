import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeCuentaEncontrada.css";
// import header from '../imagenes/iconos/icon_mybio_fans_512px.png';

// Objetos personales
import Usuario from '../SesionIniciada/ObjetoUsuario.js';

//Iconos importados
import iconoFacebook from '../../imagenes/iconos/icon-facebook.svg';
import iconoInstagram from '../../imagenes/iconos/icon-instagram.svg';
import iconoLinkedin from '../../imagenes/iconos/icon-linkedin.svg';
import iconoTwitter from '../../imagenes/iconos/icon-twitter.svg';

//Imagenes
import imagenPrincipal from '../../imagenes/iconos/icon_iniciar_sesion_256px.png'
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png'


// ------------------------

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario();

// ------------------------


// Declaro una variable de tipo Usuario.class
// var mSiguiendo = new Siguiendo('','');



class BadgeCuentaEncontrada extends React.Component{

    constructor(props) {
        super(props);        

        // Se muestran datos provisionales
        this.state = {
            
            verificado: mUsuario.verificado,
            nombrePublico: mUsuario.nombrePublico,
            nombreUsuario: mUsuario.nombreUsuario,
            
            biografia: mUsuario.biografia,
            rutaFotoPerfil: imagenPrincipal,

            redFacebook: mUsuario.redFacebook,
            redInstagram: mUsuario.redInstagram,
            redLinkedin: mUsuario.redLinkedin,
            redTwitter: mUsuario.redTwitter
        };

        // Si mUsuario viene CON datos
        if (mUsuario.nombrePublico !== ""){

            // Si mUsuario ya tiene foto de perfil
            if (mUsuario.rutaFotoPerfil !== ""){


                this.state = {

                    verificado: mUsuario.verificado,
                    nombrePublico: mUsuario.nombrePublico,
                    nombreUsuario: mUsuario.nombreUsuario,
                                
                    biografia: mUsuario.biografia,
                    rutaFotoPerfil: mUsuario.rutaFotoPerfil,
                };

    
            } else { // Si mUsuario aún no tiene foto de perfil


                this.state = {

                    verificado: mUsuario.verificado,
                    nombrePublico: mUsuario.nombrePublico,
                    nombreUsuario: mUsuario.nombreUsuario,
                                
                    biografia: mUsuario.biografia,
                    rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                };

            }

            // Si mUsuario.nombreUsuario viene CON datos, pero NO nomprePublico (no existe el usuario)
        }

        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
    }

    handleMensajeCuentaVerificada = (e) => {

        e.preventDefault();

        const mensaje = `Esta cuenta ha comprobado que pertenece a alguien con influencia en internet (o en la vida real)`;
        alert(mensaje);        
    }
    

    render(){
        

        return(

            <div className="BadgeCuentaEncontrada">

                <div className="BadgeCuentaEncontrada__header">
                    <img className="icon-verificado" src={iconCuentaVerificada} hidden={!this.props.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                <div className="BadgeCuentaEncontrada__section-name">
                    
                    <div className="imagen-avatar-mini">
                        <img src={this.props.rutaFotoPerfil} alt="Imagen de perfil"/>
                    </div>

                    <h5> {this.props.nombrePublico} </h5>
                    <span> @{this.props.nombreUsuario} </span>
                
            
                </div>


                <h6 hidden={!this.props.biografia} className="BadgeCuentaEncontrada__section-biografia">
                    {this.props.biografia}
                </h6>

                <div className="lista-iconos-tarjeta">
                    <a hidden={!this.props.redFacebook} href={"https://facebook.com/" + this.props.redFacebook} target="_blank" rel="noopener noreferrer"> <img className="icon-tarjeta" src={iconoFacebook} alt="Icono de Facebook"/> </a>
                    <a hidden={!this.props.redInstagram} href={"https://instagram.com/" + this.props.redInstagram} target="_blank" rel="noopener noreferrer"> <img className="icon-tarjeta" src={iconoInstagram} alt="Icono de Instagram"/> </a>
                    <a hidden={!this.props.redLinkedin} href={"https://linkedin.com/in/" + this.props.redLinkedin} target="_blank" rel="noopener noreferrer"><img className="icon-tarjeta" src={iconoLinkedin} alt="Icono de Linkedin" /> </a>
                    <a hidden={!this.props.redTwitter} href={"https://twitter.com/" + this.props.redTwitter} target="_blank" rel="noopener noreferrer"><img className="icon-tarjeta" src={iconoTwitter} alt="Icono de Twitter" /> </a>
                </div>

                <div hidden={!this.props.nombrePublico} className="BadgeCuentaEncontrada__icons">
                    <Link to={"/"+this.props.nombreUsuario} className="btn btn-cuenta-encontrada">Ver</Link>
                </div>

            </div>
            
        );

    }

}

// Exportamos este componente
export default BadgeCuentaEncontrada;