import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeAgregarItemBuscador.css";

// Objetos personales
import Buscador from '../Objetos/ObjetoBuscador.js';

// Importamos los componentes de Firebase
import { getFirestore, setDoc, doc } from "firebase/firestore";

// ------------------------

// Referencias a Firestore
const db = getFirestore();

var valorTipo = "";

// ------------------------

class BadgeAgregarItemBuscador extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        valorTipo = this.props.tipo;

        if( this.props.postNuevo === true ){

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                mostrarSeleccionFoto: true,

                textoBoton: "",
                textoBotonSubirEntrada: "Subir item",

                tipoSeleccionado: "✍🏽 Normal",
            };

        } else {

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                mostrarSeleccionFoto: true,

                textoBoton: "",
                textoBotonSubirEntrada: "Actualizar item",

                tipoSeleccionado: this.props.tipo
            };

        }

        // console.log(idReferencia);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        
        // ----------------------------

        this.handleElegirTipo = this.handleElegirTipo.bind(this);
        this.handleSubirEntrada = this.handleSubirEntrada.bind(this);

        // this.updateEstado = this.updateEstado.bind(this);
    }

    /* Eventos */

    handleElegirTipo = (e) => {
        e.preventDefault();

        valorTipo = e.target.value;
        // console.log("Valor: " + valorCategoria)
        this.setState({
            tipoSeleccionado: valorTipo
        });
        
    }

    handleSubirEntrada = (e) => {
        e.preventDefault();

        var tipoItem = e.target.elements.tipo.value;

        // const fechaNueva = new Date();
        // const horaNueva = "";

        const nameNuevo = e.target.elements.name.value;
        const firstDescriptionNuevo = e.target.elements.firstDescription.value;
        const secondDescriptionNuevo = e.target.elements.secondDescription.value;
        const thirdDescriptionNuevo = e.target.elements.thirdDescription.value;


        if (nameNuevo === null || nameNuevo === "") {
            const mensaje = `Escribe un Fármaco o Síntoma`;
            alert(mensaje);

        } else {

            var urlPublica;
            // var fecha = new Date().toDateString();
            var fecha = new Date();
            
            // Separamos los datos de la fecha
            /* const [anio, mes, dia] = fechaNueva.split("-");
            const value1 = Number(anio);
            const value2 = Number(mes) - 1; // Enero inicia en 0
            const value3 = Number(dia);
            fecha.setFullYear(value1);
            fecha.setMonth(value2);
            fecha.setDate(value3);
                
            // Separamos los datos de la hora
            const [hora, minuto] = horaNueva.split(":");
            const val1 = Number(hora);
            const val2 = Number(minuto);
            fecha.setHours(val1);
            fecha.setMinutes(val2); */

            // const date = new Date();
            if (this.props.urlPublica !== ""){

                urlPublica = this.props.urlPublica;

            } else {

                urlPublica =
                fecha.getFullYear() +
                "_" +
                (fecha.getMonth()+1) +
                "_" +
                (fecha.getDate()) +
                "_" +
                fecha.getHours() +
                "_" +
                fecha.getMinutes() +
                "_" +
                fecha.getSeconds();
            }

            // const mensaje = `Tiempo del servidor: ` + serverTimestamp();
            // console.log(fecha);

                
            const objBuscador = new Buscador(tipoItem, nameNuevo, firstDescriptionNuevo, secondDescriptionNuevo, thirdDescriptionNuevo);

                const ref = doc( db, `ItemsBuscador`, urlPublica).withConverter(objBuscador.buscadorConverter);
                setDoc(ref, objBuscador);

                this.setState({
                    valorSubida: 100,
                    subidaExitosa: true
                });
                // alert("¡Entrada agregada!");

                // Reiniciamos los datos del formulario
                var nameValue = document.getElementById("name");
                if (nameValue.value !== "") {
                    nameValue.value = "";
                }
                var firstDescriptionValue = document.getElementById("firstDescription");
                if (firstDescriptionValue.value !== "") {
                    firstDescriptionValue.value = "";
                }
                var secondDescriptionValue = document.getElementById("secondDescription");
                if (secondDescriptionValue.value !== "") {
                    secondDescriptionValue.value = "";
                }
                var thirdDescriptionValue = document.getElementById("thirdDescription");
                if (thirdDescriptionValue.value !== "") {
                    thirdDescriptionValue.value = "";
                }

                // La fecha no se reinicia para agilizar la creación de múltiples tareas

                // Reiniciamos el tipo de item
                /* var tipoValue = document.getElementById("tipo");
                if (tipoValue.value !== "") {
                    tipoValue.value = "";
                } */

            
            

        }
    };

    /* Lo que mostramos en pantalla */
    render(){

        return(

            <div>

                {/* Editable */}
                <div className="BadgeCrearMencion">


                    {/* Formulario */}
                    <form onSubmit={this.handleSubirEntrada}>

                        {/* Name */}
                        <textarea className="BadgeAgregarItemBuscador-name" type="text" id="name" defaultValue={this.props.titulo}
                            name="name" placeholder="Nombre del item" rows="1"/>

                        {/* Descripción 1 */}
                        <textarea className="BadgeAgregarItemBuscador-descripcion" type="text" id="firstDescription" defaultValue={this.props.firstDescription}
                            name="firstDescription" placeholder="Primera descripción" rows="1"/>

                        {/* Descripción 2 */}
                        <textarea className="BadgeAgregarItemBuscador-descripcion" type="text" id="secondDescription" defaultValue={this.props.secondDescription}
                            name="secondDescription" placeholder="Segunda descripción" rows="1"/>

                        {/* Descripción 3 */}
                        <textarea className="BadgeAgregarItemBuscador-descripcion" type="text" id="thirdDescription" defaultValue={this.props.thirdDescription}
                            name="thirdDescription" placeholder="Tercera descripción" rows="1"/>
                        

                        {/* Espacio (eliminar) */}
                        <div className='br' theme={this.props.theme}/>


                        {/* Opciones (barra de funciones inferior) */}
                        <div className="BadgeCrearMencion-opciones">

                            {/* Privacidad de entrada */}
                            <div className="formulario-tarea">
                                <label className="label-tarea">Tipo de item:</label>
                                <select className="menu-desplegable" id="tipo" defaultValue={this.state.tipoSeleccionado} onChange={this.handleElegirTipo}>                                    
                                    <option>Diccionario</option>
                                    <option>Vademecum</option>                                    
                                </select>
                            </div>                                    

                        </div>
                        

                        {/* Progress bar */}
                        <progress hidden={!this.state.valorSubida} value={this.state.valorSubida} max="100" style={{ width: "50%", height: "0.5rem", display: "flex", margin: "0 auto", alignContent: "center" }}/>
                        <br/>

                        {/* Enviar   hidden={this.state.subidaExitosa} */}
                        <button className="btn btn-secondary">{this.state.textoBotonSubirEntrada}</button>
                        <br />
                    
                    </form>

                    <br/>

                    {/* Progress bar de entrada enviada */}
                    {/*
                    <div hidden={!this.state.subidaExitosa} style={{ width: "100%", display: "block", margin: "0 auto 50px", alignContent: "center" }}>
                        <label className='span-centrado-simple'>¡Entrada subida con éxito!</label><br/>
                        <Link className='link-simple' to="/"><big>Regresar</big></Link>
                    </div>
                    */}
                                
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeAgregarItemBuscador;