import React from 'react';
import { Link } from 'react-router-dom';

import './estilos/SidebarAdministrador.css';

import iconVerPerfil from '../../imagenes/iconos/icon-perfil.svg';
import iconCrearEntrada from '../../imagenes/iconos/icon-crear-entrada.svg';
import iconCrearPagina from '../../imagenes/iconos/icon-crear-pagina.svg';
import iconCrearProyecto from '../../imagenes/iconos/icon-crear-proyecto.svg';
import iconMencion from '../../imagenes/iconos/icon-mencion.svg';

import iconCrearMencion from '../../imagenes/iconos/icon-crear-mencion.svg';
import iconCrearCategoria from '../../imagenes/iconos/icon-crear-categoria.svg';
import iconAgregarItemBuscador from '../../imagenes/iconos/icon-agregar-item-buscador.svg';
import iconVerItemsBuscador from '../../imagenes/iconos/icon-ver-items-buscador.svg';

// ------------------------

class SidebarAdministrador extends React.Component {


    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className="SidebarAdministrador-izquierdo">

                <div className="Sidebar-menu" id='menu-completo'>

                    <ul className="Sidebar-list">
                        
                        <p className="Sidebar__fuente">Secciones principales</p>
                        <Link className="Sidebar-link" to="/perfil"><img className="Sidebar-icon" src={iconVerPerfil} alt="Botón"/>Perfil</Link>
                        <Link className="Sidebar-link" to="/blog"><img className="Sidebar-icon" src={iconCrearEntrada} alt="Botón"/>Blog</Link>
                        <Link className="Sidebar-link" to="/paginas"><img className="Sidebar-icon" src={iconCrearPagina} alt="Botón"/>Páginas</Link>
                        <Link className="Sidebar-link" to="/proyectos"><img className="Sidebar-icon" src={iconCrearProyecto} alt="Botón"/>Proyectos</Link>
                        <Link className="Sidebar-link" to="/menciones"><img className="Sidebar-icon" src={iconMencion} alt="Botón"/>Menciones</Link>
                        <div className='divider'/>
                        <p className="Sidebar__fuente">Otros accesos directos</p>
                        <Link className="Sidebar-link" to="/crear-mencion"><img className="Sidebar-icon" src={iconCrearMencion} alt="Botón"/>Crear mención</Link>
                        <Link className="Sidebar-link" to="/categorias"><img className="Sidebar-icon" src={iconCrearCategoria} alt="Botón"/>Categorías</Link>
                        <Link className="Sidebar-link" to="/agregar-item-buscador"><img className="Sidebar-icon" src={iconAgregarItemBuscador} alt="Botón"/>Agregar item al buscador</Link>
                        <Link className="Sidebar-link" to="/items-buscador"><img className="Sidebar-icon" src={iconVerItemsBuscador} alt="Botón"/>Ver items del buscador</Link>

                    </ul>
                    
                </div>

            </div>
        );
    }
}


export default SidebarAdministrador;