import React from 'react';
import ReactDOM from 'react-dom';

import '../global.css';

// Objetos personales
import SubPagina from '../componentes/Objetos/ObjetoSubPagina.js';
import BadgeCrearPost from '../componentes/SesionIniciada/BadgeCrearPost.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
// import { getDoc, doc } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";

import NotFound from './NotFound.js';

// ------------------------

const db = getFirestore();


// Variable de tipo Entrada.class
var objSubPagina = new SubPagina('','','','','','','','','','','','','','','');

// ------------------------


class EditarPost extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            urlPublica: "",

            autor: mUid,

            horaString: "",
            fechaString: "",

            categoria: "",
            texto: "",
            
            noComments: "",
            noReposts: "",
            noLikes: "",
            noShared: "",
            noSaved: "",
            noViews: ""
        };

        this.getPost();
    }

    async getPost(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);
        
        // const idReferencia = urlPrivada + urlPublica;
        const urlPrivada = "autor_" + mUid + "_fecha_";
            
        // Se obtiene el Nombre de Usuario de la URL
        const urlPadre = this.props.match.url;            
        const urlPublica = urlPadre.replace("/editar-post/", "");

        const idReferencia = urlPrivada + urlPublica;
       // console.log(idReferencia);

        // const ref = doc(db, "Posts/" + mUid + "/Estado", idReferencia).withConverter(SubPagina.subPaginaConverter);
        // const docSnap = getDoc(ref);

        // Se piden los datos
        // docSnap.then((snapshot) => {

        const ref = collection(db, "Posts/" + mUid + "/Estado");
        // Create a query against the collection.
        const q = query(ref, where("urlPublic", "==", urlPublica)).withConverter(SubPagina.subPaginaConverter);
        const querySnapshot = getDocs(q);

        // Se piden los datos
        querySnapshot.then((snapshot) => {

            snapshot.forEach((doc) => {

                const idSubPagina = doc.id;
                
                // Se rellenan los datos
                objSubPagina = doc.data();

                // Convertimos Timestamp a Date
                const dateEntrada = objSubPagina.date.toDate();

                // Separamos el date en Hora y Fecha
                const horaString = ("0" + dateEntrada.getHours()).slice(-2) + ":" + ("0" + dateEntrada.getMinutes()).slice(-2);
                const fechaString = (dateEntrada.getFullYear()) + "-" + ("0"+(dateEntrada.getMonth()+1)).slice(-2) + "-" + ("0" + dateEntrada.getDate()).slice(-2);

                const titulo = horaString + " • " + fechaString;
                document.title = titulo + " | Editar post";

                // Se actualiza el estado
                this.setState(() => {
                    return {
                        idReferencia: idReferencia,

                        idSubPagina: idSubPagina,
                        urlPublica: urlPublica,

                        autor: mUid,

                        horaString: horaString,
                        fechaString: fechaString,

                        categoria: objSubPagina.categoria,
                        texto: objSubPagina.texto,
                        
                        noComments: objSubPagina.noComments,
                        noReposts: objSubPagina.noReposts,
                        noLikes: objSubPagina.noLikes,
                        noShared: objSubPagina.noShared,
                        noSaved: objSubPagina.noSaved,
                        noViews: objSubPagina.noViews
                    }
                });

            });
                    
            //No se devuelve un Objeto SubPagina.js
            if(snapshot.empty){

                /* Reemplazamos el div "visor" */
                const App = () => (
                    <NotFound/>
                );
                ReactDOM.render(<App />, document.getElementById('visor'));

            }
                    
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                <div id="visor" theme={this.props.theme}>
                    <BadgeCrearPost
                        theme={this.props.theme}

                        postNuevo = {false}
                        idReferencia = {this.state.idReferencia}

                        idSubPagina = {this.state.idSubPagina}
                        urlPublica = {this.state.urlPublica}
                        autor = {this.state.autor}
                        horaString= {this.state.horaString}
                        fechaString= {this.state.fechaString}
                        privacidad = {""}

                        categoria = {this.state.categoria}
                        portada = {""}
                        titulo = {""}
                        subtitulo = {""}
                        texto = {this.state.texto}

                        // Analítica
                        noComments = {this.state.noComments}
                        noReposts = {this.state.noReposts}
                        noLikes = {this.state.noLikes}
                        noShared = {this.state.noShared}
                        noSaved = {this.state.noSaved}
                        noViews = {this.state.noViews}
                    />
                </div>

            </React.Fragment>


        );
    }
}

export default EditarPost;