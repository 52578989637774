import React from 'react';
import { Link } from 'react-router-dom';

// Importamos los componentes a Firebase
import { getFirestore, doc, getDoc } from "firebase/firestore";

import "../../global.css";
import "./estilos/BadgeSiguiendo.css";
// import header from '../imagenes/iconos/icon_mybio_fans_512px.png';

import { getAuth } from "firebase/auth";

//Iconos importados
import iconWhatsApp from '../../imagenes/iconos/icon-whatsapp.svg';
import iconTelefono from '../../imagenes/iconos/icon-telefono.svg';
import iconCorreo from '../../imagenes/iconos/icon-correo.svg';


import imagenPrincipal from '../../imagenes/iconos/icon_iniciar_sesion_256px.png'
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png'

const auth = getAuth();
const db = getFirestore();

var uidDeUsuario = "";
var mUid;


var mostrarBio = false;
var mostrarNumero = false;
var mostrarCorreo = false;


// ------------------------
class Siguiendo {
    constructor (uid, date) {

        this.uid = uid;
        this.date = date;
    }
    toString() {
        return this.uid + ', ' + this.date;
    }
}

// Firestore data converter
const siguiendoConverter = {
    toFirestore: (siguiendo) => {
        return {
            uid: siguiendo.uid,
            date: siguiendo.date
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Siguiendo(data.uid, data.date);
    }
};

// Declaro una variable de tipo Usuario.class
// var mSiguiendo = new Siguiendo('','');


// ------------------------
//Referencia del objeto Usuario
class Usuario {
    constructor (suscrito, verificado, nombrePublico, nombreUsuario,
        biografia, rutaFotoPerfil, clavePais, numeroTelefono) {

        this.suscrito = suscrito;
        this.verificado = verificado;
        this.nombrePublico = nombrePublico;
        this.nombreUsuario = nombreUsuario;

        this.biografia = biografia;
        this.rutaFotoPerfil = rutaFotoPerfil;

        this.clavePais = clavePais;
        this.numeroTelefono = numeroTelefono;
    }
    toString() {
        return this.suscrito + ', ' + this.verificado + ', ' + 
        this.nombrePublico + ', ' + this.nombreUsuario + ', ' + 
        this.biografia + ', ' + this.rutaFotoPerfil + ', ' +
        this.clavePais + ', ' + this.numeroTelefono;
    }
}

// Firestore data converter
const usuarioConverter = {
    toFirestore: (usuario) => {
        return {
            suscrito: usuario.suscrito,
            verificado: usuario.verificado,

            nombrePublico: usuario.nombrePublico,
            nombreUsuario: usuario.nombreUsuario,
            
            biografia: usuario.biografia,
            rutaFotoPerfil: usuario.rutaFotoPerfil,

            clavePais: usuario.clavePais,
            numeroTelefono: usuario.numeroTelefono
        };
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new Usuario(data.suscrito, data.verificado, 
            data.nombrePublico, data.nombreUsuario,
            data.biografia, data.rutaFotoPerfil, 
            data.clavePais, data.numeroTelefono);
    }
};

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------


class BadgeSiguiendo extends React.Component{


    constructor(props) {
        super(props);


        // Cargamos el usuario actual
        const user = auth.currentUser;


        // Se muestran datos provisionales
        this.state = {

            verificado: false,
        
            nombrePublico: "",
            nombreUsuario: "cargando...",
            
            biografia: mUsuario.biografia,
            rutaFotoPerfil: imagenPrincipal,

            clavePais: "",
            numeroTelefono: "",
            correo: user.email,
        };

        mUid = this.props.idCreador;

        const referencia = doc(db, `Siguiendo/${mUid}/Personas`, this.props.uid).withConverter(siguiendoConverter);
        const documentoSnap =  getDoc(referencia);

        documentoSnap.then((snapshot) => {

            
            // snapshot.id muestra el UID asociado al usuario en Siguiendo > miUID > Personas > UID
            if(snapshot.exists()){

                uidDeUsuario = this.props.uid;

                const ref = doc(db, "Usuarios", uidDeUsuario).withConverter(usuarioConverter);
                const docSnap =  getDoc(ref);

                docSnap.then((snapshot) => {


                    // snapshot.id muestra el UID asociado al usuario en Usuarios > UID > datos    
                    mUsuario = snapshot.data();
                    

                    // Visibilidad de elementos --------

                    var bio = mUsuario.biografia;
                    var num = mUsuario.numeroTelefono;
                    var mail = user.email;
                
                    // Visibilidad de la biografía
                    if(bio !== "") {
                        mostrarBio = true;
                    }

                    // Visibilidad del número de teléfono
                    if(num !== "") {
                        mostrarNumero = true;
                    }

                    // Visibilidad del correo
                    if(mail !== ""){
                        mostrarCorreo = true;
                    }

                    // -----------

                    // Si mUsuario ya tiene foto de perfil
                    if (mUsuario.rutaFotoPerfil !== ""){


                        this.setState(() => {
                                
                                return {

                                    verificado: mUsuario.verificado,
                                    nombrePublico: mUsuario.nombrePublico,
                                    nombreUsuario: mUsuario.nombreUsuario,
                                            
                                    biografia: mUsuario.biografia,
                                    rutaFotoPerfil: mUsuario.rutaFotoPerfil,

                                    clavePais: mUsuario.clavePais,
                                    numeroTelefono: mUsuario.numeroTelefono,
                                }
                        });

            
                    } else { // Si mUsuario aún no tiene foto de perfil

                        this.setState(() => {
                                
                            return {

                                verificado: mUsuario.verificado,
                                nombrePublico: mUsuario.nombrePublico,
                                nombreUsuario: mUsuario.nombreUsuario,
                                        
                                biografia: mUsuario.biografia,
                                rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                                
                                clavePais: mUsuario.clavePais,
                                numeroTelefono: mUsuario.numeroTelefono,
                            }
                        });

                    }

                    
                    return Promise.resolve(docSnap);
                });
            }


            if(snapshot.empty){

                //No se devuelve un Objeto Usuario
                uidDeUsuario = "";
                this.setState(() => {
                            
                    return {
                        verificado: false,
                        nombrePublico: "",
                        nombreUsuario: "",
                                    
                        biografia: `Al parecer aún no sigues a nadie 🙁`,
                        rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                            
                        clavePais: "",
                        numeroTelefono: "",
                    }
                });

            }


            if(!snapshot.exists()){

                //No se devuelve un Objeto Usuario
                uidDeUsuario = "";
                this.setState(() => {
                            
                    return {
                        verificado: false,
                        nombrePublico: "",
                        nombreUsuario: "usuario",
                                    
                        biografia: `Al parecer aún no sigues a nadie 🙁`,
                        rutaFotoPerfil: imagenPrincipal, // se muestra una provisional
                            
                        clavePais: "",
                        numeroTelefono: "",
                    }
                });
                
            }


            
            
            return Promise.resolve(documentoSnap);
        });


        // ----------------------------


        
    
        // this.handleMostrarDatos = this.handleMostrarDatos.bind(this);
        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
        this.handleEscribirComentario = this.handleEscribirComentario.bind(this);
    }

    handleMensajeCuentaVerificada = (e) => {

        e.preventDefault();

        const mensaje = `Esta cuenta ha comprobado que pertenece a alguien con influencia en internet (o en la vida real)`;
        alert(mensaje);        
    }

    handleEscribirComentario = (e) => {

        e.preventDefault();

        // const mensaje = `Enviando... a @${this.state.nombreUsuario} 🤫`;
        // alert(mensaje);

        // onClick={this.handleEscribirComentario}
    }


    
    render(){
        

        return(

            <div className="BadgeSiguiendo">

                <div className="BadgeSiguiendo__header">
                    <img className="icon-verificado" src={iconCuentaVerificada} hidden={!this.state.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                <div className="BadgeSiguiendo__section-name">
                    
                    <div className="imagen-avatar-mini">
                        <img src={this.state.rutaFotoPerfil} alt="Imagen de perfil"/>
                    </div>

                    <h5> {this.state.nombrePublico} </h5>
                    <h6> @{this.state.nombreUsuario} </h6>
                
            
                </div>


                <div hidden={!mostrarBio} className="BadgeSiguiendo__section-biografia">


                    <h6>{this.state.biografia}</h6>
        
                </div>

                <div className="BadgeSiguiendo__icons">
                    
                    <a hidden={!mostrarNumero} href={"https://wa.me/" + this.state.clavePais + this.state.numeroTelefono} target="_blank" rel="noopener noreferrer"> <img className="icon" src={iconWhatsApp} alt="Icono de whatsapp"/> </a>
                    <a hidden={!mostrarNumero} href={"tel:" + this.state.numeroTelefono} target="_blank" rel="noopener noreferrer"> <img className="icon" src={iconTelefono} alt="Icono de teléfono"/> </a>
                    <a hidden={!mostrarCorreo} href={"mailto:" + this.state.correo} target="_blank" rel="noopener noreferrer"><img className="icon" src={iconCorreo} alt="Icono de correo" /> </a>
                    
                </div>

                <div className="BadgeSiguiendo__icons">

                    <Link to={"/"+this.state.nombreUsuario} className="btn btn-escribir-comentario">Ver</Link>

                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeSiguiendo;