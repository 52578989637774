import React from 'react';
import { Helmet } from 'react-helmet';

import '../global.css';

// Objetos personales
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import Pagina from '../componentes/SesionIniciada/ObjetoPagina.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
// import { getDoc, doc } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";

import BadgeLeerPagina from '../componentes/SesionIniciada/BadgeLeerPagina.js';
import SEO from '../componentes/Main/SEO.js';
// import NotFound from './NotFound.js';

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1585854467604-cf2080ccef31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1773&q=80";

// ------------------------

const db = getFirestore();

// Formato "día, fecha de mes de año"
// const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formatoFecha = { year: 'numeric', month: 'short', day: 'numeric' };
const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');
var objPagina = new Pagina('','','','','','','','','');


// ------------------------


class LeerPagina extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {
            paginaExistente: false,

            idReferencia: "",
            urlPublica: "",

            autor: "",
            portada: banner,

            horaString: "",
            fechaString: "",

            titulo: "",
            subtitulo: "🌐 Cargando...",
            texto: "",

            categoria: "",

            mUsuario: mUsuario
        };

        // const user = auth.currentUser;
        // mUid = auth.currentUser.uid;
        // UID del dueño
        // mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        this.getPagina();
        // this.getUsuario();
        
    }

    async getPagina(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        if (this.props.match.url !== "" || this.props.match.url !== null){

            // const idReferencia = urlPrivada + urlPublica;
            const urlPrivada = "autor_" + mUid + "_fecha_";
            
            // Se obtiene el Nombre de Usuario de la URL
            const urlPadre = this.props.match.url;            
            const urlPublica = urlPadre.replace("/", "");

            const idReferencia = urlPrivada + urlPublica;

            const q = query(collection(db, "Paginas"), where("url", "==", urlPublica)).withConverter(Pagina.paginaConverter);
            const querySnapshot = await getDocs(q);

            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                // console.log(doc.id, " => ", doc.data());

                // Se rellenan los datos
                objPagina = doc.data();
                const titulo = objPagina.titulo;
                document.title = titulo;


                // Convertimos Timestamp a Date
                const dateEntrada = objPagina.date.toDate();

                /* NECESARIO para la tarjeta */
                // "día, fecha de mes de año"
                var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                // "hora:minuto am/pm"
                var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);

                // Se actualiza el estado
                this.setState(() => {
                    return {
                        paginaExistente: true,
                        
                        idReferencia: idReferencia,
                        urlPublica: urlPublica,

                        autor: mUid,
                        portada: objPagina.portada,

                        privacidad: objPagina.privacidad,
                        categoria: objPagina.categoria,

                        horaString: horaString,
                        fechaString: fechaString,

                        titulo: objPagina.titulo,
                        subtitulo: objPagina.subtitulo,
                        texto: objPagina.texto
                    }
                });

                if (objPagina.portada === ""){
                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            portada: banner,
                        }
                    });
                }


                if(!doc.exists){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            paginaExistente: false
                        }
                    });
    
                }
                        
                // return Promise.resolve(doc);
            });

            
        
        }
    }
    /* async getUsuario(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            // mUid = auth.currentUser.uid;
            // UID del dueño
            // const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                // Se actualiza el estado
                this.setState(() => {
                    return {
                        mUsuario: mUsuario
                    }
                });
                    
                return Promise.resolve(docSnap);
            });
        }
    } */

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        // document.title = "Entrada | Blog";
        return (

            <React.Fragment>

                <div id="visor" theme={this.props.theme}>

                    {/* SEO */}
                    <SEO
                        title={this.state.titulo}
                        description={this.state.texto}
                        image={this.state.portada}
                        url={'/'+this.state.urlPublica}
                        name={'@apeordi'}
                        type={'article'}
                    />

                    {/* Sección de la Entrada */}
                    <BadgeLeerPagina
                        theme={this.props.theme}

                        paginaExistente={this.state.paginaExistente}

                        idReferencia = {this.state.idReferencia}
                        urlPublica = {this.state.urlPublica}

                        privacidad = {this.state.privacidad}
                        categoria = {this.state.categoria}

                        autor = {this.state.autor}
                        portada = {this.state.portada}

                        horaString= {this.state.horaString}
                        fechaString= {this.state.fechaString}

                        titulo = {this.state.titulo}
                        subtitulo = {this.state.subtitulo}
                        texto = {this.state.texto}

                        rutaFotoPerfil = {this.state.mUsuario.rutaFotoPerfil}
                        nombrePublico = {this.state.mUsuario.nombrePublico}
                        nombreUsuario = {this.state.mUsuario.nombreUsuario}
                        verificado = {this.state.mUsuario.verificado}
                    />

                </div>

            </React.Fragment>


        );
    }
}

export default LeerPagina;