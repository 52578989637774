import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import LayoutAdministrador from './LayoutAdministrador.js';
import ScrollToTop from '../Main/ScrollToTop.js';
import MetaTitulo from '../MetaTitulo.js';

// HOME
import Home from '../../paginas/Home.js';
import Perfil from '../../paginas/Perfil.js';
import Menu from '../../paginas/Menu.js';
// import PanelControl from '../../paginas/PanelControl.js';

// Navbar
import CrearPost from '../../paginas/CrearPost.js';
import CrearEntrada from '../../paginas/CrearEntrada.js';
import CrearPagina from '../../paginas/CrearPagina.js';
import CrearProyecto from '../../paginas/CrearEntrada.js';
import CrearMencion from '../../paginas/CrearMencion.js';
import AgregarItemBuscador from '../../paginas/AgregarItemBuscador.js';

// Administración - Sidebar
import Entradas from '../../paginas/principales/Blog.js';
import Paginas from '../../paginas/Administracion/Paginas.js';
import Proyectos from '../../paginas/principales/Portafolio.js';
import Menciones from '../../paginas/principales/Menciones.js';

// --
import EditarPerfil from '../../paginas/EditarPerfil.js';
import Contactos from '../../paginas/Contactos.js';

// Post
import LeerPost from '../../paginas/LeerPost.js';
import EditarPost from '../../paginas/EditarPost.js';

// Entradas
import LeerEntrada from '../../paginas/LeerEntrada.js';
import EditarEntrada from '../../paginas/EditarEntrada.js';

// Páginas
import LeerPagina from '../../paginas/LeerPagina.js';
import EditarPagina from '../../paginas/EditarPagina.js';

// Items buscador APAMED
import LeerItemBuscador from '../../paginas/LeerEntrada.js';
import EditarItemBuscador from '../../paginas/EditarEntrada.js';
import ItemsBuscador from '../../paginas/Administracion/ItemsBuscador.js';

// Otras páginas
import BuscarCuenta from '../../paginas/BuscarCuenta.js';
// import PerfilExterno from '../../paginas/PerfilExterno.js';

// Necesarias
import Privacidad from '../../paginas/principales/Privacidad.js';

// Página NO encontrada
import NotFound from '../../paginas/NotFound.js';

// Alternar entre Temas (claro y oscuro)
import "../Main/estilos/BotonThemeDark.css";
import iconoThemeLight from '../../imagenes/iconos/icon-tema-dia.svg';
import iconoThemeDark from '../../imagenes/iconos/icon-tema-noche.svg';


function App() {

    // Contexto para el helmet
    const helmetContext = {};

    // Variable de estado de tema
    const [theme, setTheme] = useState("light");

    // Variable de estado de tiempo (ocultar botón)
    const [ showButton, setShowButton] = useState(true);

    useEffect(() => {
        // Obtiene la preferencia del usuario del almacenamiento local
        const localTheme = window.localStorage.getItem("theme");

        //  ... o usa 'light' por defecto
        setTheme(localTheme || "light");
        
        // Oculta el botón circular después de 5 segundos
        setTimeout(() => {
            setShowButton(false);
        }, 5000);
    }, []);

    // Captura el evento onScroll
    document.onscroll = function() {comprobarScroll()};

    // Se llama al ocurrir un cambio en el scroll
    function comprobarScroll(){

        // Muestra el botón
        setShowButton(true);

        // Oculta el botón circular después de 5 segundos
        setTimeout(() => {
            setShowButton(false);
        }, 5000);
    };

    // Cambia entre 'light' y 'dark'
    const toggleTheme = () => {
        
        if (theme === "light") { // Establece el tema claro (light)

          setTheme("dark");
          window.localStorage.setItem("theme", "dark");
          // window.alert("dark");
          document.getElementById('imgBotonTheme').src = iconoThemeLight;

        } else { // Establece el tema oscuro (dark)

          setTheme("light");
          window.localStorage.setItem("theme", "light");
          // window.alert("light");
          document.getElementById('imgBotonTheme').src = iconoThemeDark;
        }
    };

    return (
        
        <BrowserRouter>
            
                <LayoutAdministrador theme={theme}>
                
                    <ScrollToTop />
                    {showButton && (
                        <div className='BotonThemeDark' onClick={toggleTheme} title='Cambiar tema'>
                            <img id='imgBotonTheme' src={iconoThemeDark} alt=""/>
                        </div>
                    )}
                    <Switch>
                    
                        <Route exact path="/" render={ props => <Home title={MetaTitulo("")} theme={theme} {...props} />}/>
                        <Route exact path="/entrar" render={ props => <Home title={MetaTitulo("")} theme={theme} {...props} />}/>
                        <Route exact path="/crear-cuenta" render={ props => <Home title={MetaTitulo("")} theme={theme} {...props} />}/>
                        
                        {/* Navbar */}
                        <Route exact path="/perfil" render={ props => <Perfil title={MetaTitulo("Perfil")} theme={theme} {...props} />}/>
                        <Route exact path="/editar-perfil" render={ props => <EditarPerfil title={MetaTitulo("Editar perfil")} theme={theme} {...props} />}/>
                        <Route exact path="/contactos" render={ props => <Contactos title={MetaTitulo("Contactos")} theme={theme} {...props} />}/>
                        <Route exact path="/buscar" render={ props => <BuscarCuenta title={MetaTitulo("Buscar cuenta")} theme={theme} {...props} />}/>
                        <Route exact path="/menu" render={ props => <Menu title={MetaTitulo("Menú")} theme={theme} {...props} />}/>

                        {/* Sidebar */}
                        <Route exact path="/crear-post" render={ props => <CrearPost title={MetaTitulo("Nueva publicación")} theme={theme} {...props} />}/>
                        <Route exact path="/crear-entrada" render={ props => <CrearEntrada title={MetaTitulo("Nueva entrada de blog")} theme={theme} {...props} />}/>
                        <Route exact path="/crear-pagina" render={ props => <CrearPagina title={MetaTitulo("Nueva página")} theme={theme} {...props} />}/>
                        <Route exact path="/crear-proyecto" render={ props => <CrearProyecto title={MetaTitulo("Nuevo proyecto")} theme={theme} {...props} />}/>
                        <Route exact path="/crear-mencion" render={ props => <CrearMencion title={MetaTitulo("Nueva mención")} theme={theme} {...props} />}/>
                        <Route exact path="/agregar-item-buscador" render={ props => <AgregarItemBuscador title={MetaTitulo("Agregar item buscador APAMED")} theme={theme} {...props} />}/>

                        <Route exact path="/blog" render={ props => <Entradas title={MetaTitulo("Entradas del blog")} theme={theme} {...props} />}/>
                        <Route exact path="/entradas" render={ props => <Entradas title={MetaTitulo("Entradas del blog")} theme={theme} {...props} />}/>
                        <Route exact path="/paginas" render={ props => <Paginas title={MetaTitulo("Páginas")} theme={theme} {...props} />}/>
                        <Route exact path="/proyectos" render={ props => <Proyectos title={MetaTitulo("Proyectos")} theme={theme} {...props} />}/>
                        <Route exact path="/menciones" render={ props => <Menciones title={MetaTitulo("Menciones de la página")} theme={theme} {...props} />}/>
                        <Route exact path="/items-buscador" render={ props => <ItemsBuscador title={MetaTitulo("Items del buscador")} theme={theme} {...props} />}/>


                        {/* Principales */}
                        {/* <Route exact path="/acerca-de" render={ props => <AcercaDe title={MetaTitulo("Contactos")} theme={theme} {...props} />}/> */}
                        <Route exact path="/privacidad" render={ props => <Privacidad title={MetaTitulo("Contactos")} theme={theme} {...props} />}/>

                        {/* URLs dinámicas */}
                        <Route path={"/post/:idReferencia"} render={ props => <LeerPost title={MetaTitulo("Post")} theme={theme} {...props} />}/>
                        <Route path="/editar-post/:idReferencia" render={ props => <EditarPost title={MetaTitulo("Editar Post")} theme={theme} {...props} />}/>
                        <Route path="/editar-pagina/:idReferencia" render={ props => <EditarPagina title={MetaTitulo("Editar página")} theme={theme} {...props} />}/>

                        <Route path={"/blog/:idReferencia"} render={ props => <LeerEntrada title={MetaTitulo("Blog")} theme={theme} {...props} />}/>
                        <Route path="/editar-entrada/:idReferencia" render={ props => <EditarEntrada title={MetaTitulo("Editar entrada")} theme={theme} {...props} />}/>

                        <Route path="/editar-item-buscador/:idReferencia" render={ props => <EditarItemBuscador title={MetaTitulo("Editar item buscador APAMED")} theme={theme} {...props} />}/>

                        <Route path={"/:idReferencia"} render={ props => <LeerPagina title={MetaTitulo("Cargando...")} theme={theme} {...props} /> } />
                        <Route theme={theme} component={NotFound}/>

                    </Switch>

                </LayoutAdministrador>
            
            </BrowserRouter>
    );

    
}


export default App;