import React from 'react';
import { Link } from 'react-router-dom';

import './style-post.css';

import iconOpciones from '../../../imagenes/iconos/icon-opciones.svg';

import iconEditarPost from '../../../imagenes/iconos/icon-editar-post.svg';
import iconCopiarTexto from '../../../imagenes/iconos/icon-copiar.svg';
import iconCopiarURL from '../../../imagenes/iconos/icon-vinculo-interno.svg';
import iconBorrarBloque from '../../../imagenes/iconos/icon-eliminar.svg';

import SubPagina from '../../Objetos/ObjetoSubPagina.js';
import Snackbar from '../Snackbar/index.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// ------------------------

const db = getFirestore();
var objSubPagina = new SubPagina('','','','','','','','','','','','','','','');

// ------------------------

function toggleClass(elem, className){
    if (elem.className.indexOf(className) !== -1){
      elem.className = elem.className.replace(className,'');
    }
    else{
      elem.className = elem.className.replace(/\s+/g,' ') + 	' ' + className;
    }
  
    return elem;
}
  
function toggleMenuDisplay(e){
    const dropdown = e.currentTarget.parentNode;
    const menu = dropdown.querySelector('.menu-post');
  
    toggleClass(menu,'hide');
}
  
function handleOptionSelected(e){
    toggleClass(e.target.parentNode, 'hide');
}

// Cierra el menú si el usuario hace click fuera de este
/* window.onclick = function(event) {

    if (!event.target.matches('.dropdown-icon')) {
        
        toggleMenuDisplay(event);
    }
} */
  

class MenuPost extends React.Component{

    constructor(props) {
        super(props);

        // Estado
        this.state = {
            textoMensaje: ""
        };

        this.showMenu = this.showMenu.bind(this);
        this.showValue = this.showValue.bind(this);
    }

    // EVENTOS ---------------

    /* Mostrar menú de opciones */
    showMenu = (e) => {
        e.preventDefault();
     
        toggleMenuDisplay(e);        
    }

    /* Valor seleccionado */
    showValue = (e) => {
        e.preventDefault();

        handleOptionSelected(e);

        const uidAutor = this.props.autor;
        const idElement = this.props.id;
        const portada = this.props.portada;
        const valorMenu = e.target.id;

        this.menuOpciones(uidAutor, idElement, portada, valorMenu);
    }

    // META FUNCIONES ---------------

    /* Evento del Menú de Opciones (Dropdown) */
    menuOpciones(autor, numId, portada, valor){

        if (valor === "CopyText"){
            
            // Se copia el texto
            this.copiarTexto(numId);

        } else if (valor === "CopyURL"){
            
            // Se copia la URL
            this.copiarURL(numId);

        } else if (valor === "Delete"){
            
            // Se elimina
            this.borrarBloque(autor, numId, portada);
        }
    }

    // -----------------------------

    copiarTexto(numId){

        // Se copia el texto
        const elementoTexto = document.getElementById('texto-'+ numId).textContent;
    
        navigator.clipboard.writeText(elementoTexto)
        .then(() => {
                            
            // console.log("Text copied to clipboard...");
            // alert("¡Texto copiado al portapapeles!");
            
            // Se muestra un mensaje
            this.setState(() => {
                return {
                    textoMensaje: "Texto copiado al portapapeles"
                }
            });
    
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    }
    copiarURL(numId){
    
        // Se copia el texto
        const urlPost = "https://apeordi.com/post/" + numId;
    
        navigator.clipboard.writeText(urlPost)
        .then(() => {
                    
            // console.log("Text copied to clipboard...");
            // alert("¡Enlace copiado al portapapeles!");
    
            // Se muestra un mensaje
            this.setState(() => {
                return {
                    textoMensaje: "Enlace copiado al portapapeles"
                }
            });
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    
    }
    borrarBloque(autor, numId, portada){
    
        // Se borra el documento
        const elementoTexto = document.getElementById('texto-'+numId).textContent;
    
        // Se solicita confirmación
        const mensaje = `⚠ ¿Desea eliminar el párrafo?\n\n"${elementoTexto}"`;
        const isOK = window.confirm(mensaje);
    
        if(isOK){
    
            this.borrarEnBaseDeDatos(autor, numId, portada);
        }
    }
    
    // -----------
    
    borrarEnBaseDeDatos(autor, numId, portada){
    
        // UID de la persona dueña de las Tareas
        // const uidPersona = this.props.autor;
    
        // UID de la persona que creó la Tarea
        // const uidDefault = this.props.uid;
    
        // Si existe una portada, se elimina
        if(portada !== ""){
    
            // Separamos para mostrar una URL más amigable
            const url = "Posts/" + autor + "/Estado/" + numId + "/portada";
    
            const storage = getStorage();
            const portadaRef = ref(storage, url);
    
            // Delete the file
            deleteObject(portadaRef)
            .then(() => {
    
                // Se elimina el post
                const refEntrada = doc( db, `Posts/${autor}/Estado/${numId}`).withConverter(objSubPagina.subPaginaConverter);
                deleteDoc(refEntrada);
    
                // Se borra la view del DOM
                this.borrarEnDOM(numId);
    
            }).catch((error) => {
                // Uh-oh, an error occurred!
                alert(`Ocurrió un error:\n\n ${error}`);
            });
    
    
        } else{
    
            // Se elimina el post
            const refEntrada = doc( db, `Posts/${autor}/Estado/${numId}`).withConverter(objSubPagina.subPaginaConverter);
            deleteDoc(refEntrada);
    
            // Se borra la view del DOM
            this.borrarEnDOM(numId);
        }
    }
    borrarEnDOM(numId){
    
        // Se borra de la lista
        const elementoPadre = document.getElementById('post-'+numId);
        elementoPadre.remove();
    
        // const mensaje = `Párrafo ${numId} eliminado`;
        // alert(mensaje);
    
        // Se muestra un mensaje
        this.setState(() => {
            return {
                textoMensaje: "Post eliminado"
            }
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className='dropdown'>

                {/* Ícono de menú */}
                <img className="dropdown-icon" src={iconOpciones} alt='' title='Opciones' onClick={this.showMenu}/>


                {/* Menú de opciones */}
                <div id={'menuOpciones-'+this.props.id} className="menu-post pointerCursor hide" onClick={this.showValue}>
                    
                    <Link hidden={this.props.public} className='option' title='' to={"/editar-post/"+this.props.id}><img src={iconEditarPost} alt=""/>Editar publicación</Link>
                    <div className='option' id='CopyText' title='Copiar texto al portapapeles'><img src={iconCopiarTexto} alt=""/>Copiar texto</div>
                    <div className='option' id='CopyURL' title='Copiar URL al portapapeles'><img src={iconCopiarURL} alt=""/>Copiar URL</div>
                    <div className='option' id='Delete' title='Eliminar post'><img src={iconBorrarBloque} alt=""/>Eliminar post</div>
                </div>

                <Snackbar theme={this.props.theme} mensaje={this.state.textoMensaje}/>

            </div>

        );

    }

}

export default MenuPost;