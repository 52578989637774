export default class Pagina {

    constructor (url, autor, date, privacidad, categoria, portada, titulo, subtitulo, texto) {

        this.url = url;

        this.autor = autor;
        this.date = date;
        this.privacidad = privacidad;
        this.categoria = categoria;

        this.portada = portada;
        this.titulo = titulo;
        this.subtitulo = subtitulo;
        this.texto = texto;
    }

    toString() {
        return this.url + ', ' + 
        this.autor + ', ' + this.date + ', ' + this.privacidad + ', ' + this.categoria + ', ' +
        this.portada + ', ' + this.titulo + ', ' + this.subtitulo + ', ' + this.texto;
    }

    // Firestore data converter
    paginaConverter = {
        toFirestore: (pagina) => {
            return {
                url: pagina.url,

                autor: pagina.autor,
                date: pagina.date,
                privacidad: pagina.privacidad,
                categoria: pagina.categoria,

                portada: pagina.portada,
                titulo: pagina.titulo,
                subtitulo: pagina.subtitulo,
                texto: pagina.texto
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Pagina(data.url, 
                data.autor, data.date, data.privacidad, data.categoria,
                data.portada, data.titulo, data.subtitulo, data.texto);
        }
    }
}