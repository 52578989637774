import React from 'react';
// import { Link } from 'react-router-dom';

import './style.css';

import MenuEstilos from '../Dropdown/menuTextAreaEstilos.js';
import MenuOpciones from '../Dropdown/menuTextAreaOpciones.js';

// ------------------------

// ------------------------

class Bloque extends React.Component{

    /* constructor(props) {
        super(props);
    
        // this.handleMensaje = this.handleMensaje.bind(this);
    } */


    // EVENTOS ---------------

    /* handleMensaje = (e) => {
        e.preventDefault();

        const mensaje = `Este es un mensaje pop up`;
        alert(mensaje);        
    } */

    /* Evento del teclado */
    eventKeydown() {

        // Listener para saber la tecla que se presiona ----------
        document.addEventListener('keydown', (event) => {

            // Para saber la tecla que se presiona en el teclado
            /* var keyValue = event.key;
            var codeValue = event.code;
            console.log("keyValue: " + keyValue);
            console.log("codeValue: " + codeValue); */

            // Si se presiona Enter (incluye Shift + Enter)
            if (event.key === 'Enter') {

                // Salto de línea
                this.nuevaLinea();

            } // Si se presiona Backspace (borrar caracter de atrás)
            else if (event.key === 'Backspace') {

                // Removemos una línea
                this.borrarLinea();
                
                
            } // Si se presiona Delete (borrar caracter de adelante)
            else if (event.key === 'Delete') {

                // Removemos una línea
                this.borrarLinea();
                
            }

            // console.log("Nuevo bloque");
            // this.nuevoBloque(numId);

        }, false);
        // document.removeEventListener('keydown', , false);
    }

    /* Líneas dentro de un Bloque (párrafo) */
    nuevaLinea() {

        // Obtenemos el TextArea en Focus
        var focusedElement = document.activeElement;

        // Re-size
        focusedElement.style.height = 0 + "px";
        focusedElement.style.height = (focusedElement.scrollHeight + 22) + "px";
        // console.log("ID: " + focusedElement.id + "\nTamaño: " + focusedElement.scrollHeight);
    }
    borrarLinea() {

        // Obtenemos el TextArea en Focus
        var focusedElement = document.activeElement;

        // Re-size
        focusedElement.style.height = 0 + "px";
        focusedElement.style.height = (focusedElement.scrollHeight - 22) + "px";
        // console.log("ID: " + focusedElement.id + "\nTamaño: " + focusedElement.scrollHeight);
    }

    /* Menús */
    showMenuEstilos() {

        // Obtenemos el TextArea en Focus
        var focusedElement = document.activeElement;
        var idFocus = focusedElement.id;

        // Eliminamos 'texto-' para quedarnos con el número del ID
        idFocus = idFocus.replace("texto-", "");

        // Mostramos el menú correspondiente
        document.getElementById('menuEstilos-' + idFocus).classList.toggle("show");
    }
    showMenuOpciones() {

        // Obtenemos el TextArea en Focus
        var focusedElement = document.activeElement;
        var idFocus = focusedElement.id;

        // Eliminamos 'texto-' para quedarnos con el número del ID
        idFocus = idFocus.replace("texto-", "");

        // Mostramos el menú correspondiente
        document.getElementById('menuOpciones-' + idFocus).classList.toggle("show");
    }

    // META FUNCIONES ---------------

    /* Evento del Menú de Estilos (Dropdown) */
    menuEstilos(numId, valor){
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        return(

            /* Elemento Bloque id='div-1' */
            <div id={'div-'+this.props.id} className='textarea-contenedor'>

                {/* Elemento Menú Estilos id='me-1' */}
                <MenuEstilos id={this.props.id} menuEstilos={this.menuEstilos} />

                {/* Elemento Menú Opciones id='mo-1' */}
                <MenuOpciones id={this.props.id} />

                {/* Elemento textarea id='texto-1' */}
                <textarea autoFocus id={'texto-'+this.props.id} className="textarea-texto" type="text"
                    defaultValue={this.props.texto} name="texto" rows='1' onKeyDown={this.eventKeydown()}
                    placeholder="Escribe aquí algo memorable"/>
                {/* <textarea autoFocus id={'texto-'+this.props.id} className="textarea-texto" type="text"
                    defaultValue={this.props.texto} name="texto" rows='1' onKeyDown={this.eventKeydown()}
                    placeholder="Presiona '+' o escribe '/' para ver sugerencias."/> */}

            </div>

        );

    }

}

export default Bloque;