import React from 'react';
import { Link } from 'react-router-dom';

import '../../global.css'
import './estilos/Footer.css';
// import logoPagina from '../../imagenes/iconos/icon-logo-web.svg';
import logoFacebook from '../../imagenes/iconos/icon-facebook.svg';
import logoInstagram from '../../imagenes/iconos/icon-instagram.svg';
import logoLinkedin from '../../imagenes/iconos/icon-linkedin.svg';
import logoTwitter from '../../imagenes/iconos/icon-twitter.svg';
import iconIniciarSesion from '../../imagenes/iconos/icon-iniciar-sesion.svg';

class Footer extends React.Component {

    render(){
        return(

            <footer className="footer" theme={this.props.theme}>
                <div className="footer__container">

                    {/* Sección superior: Microsoft Store, Education, Business, Company */}
                    <div className="footer__top">

                        {/* Adolfo Ordinola */}
                        <div className="company">
                            <h3 className="company__logo">Adolfo Ordinola</h3>
                            <p className="company__description">
                                Página profesional, blog y portafolio. <br/>
                                <br/>
                                Para propuestas de trabajo o de negocio, ponte en contacto por correo o en mis redes sociales.
                            </p>                            
                            <div className="footer__list-icon">
                                <a className="footer__list-link" href="https://facebook.com/apeordi" target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={logoFacebook} alt="Logo"/></a>
                                <a className="footer__list-link" href="https://instagram.com/apeordi" target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={logoInstagram} alt="Logo"/></a>
                                <a className="footer__list-link" href="https://linkedin.com/in/apeordi" target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={logoLinkedin} alt="Logo"/></a>
                                <a className="footer__list-link" href="https://twitter.com/intent/user?screen_name=apeordi" target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={logoTwitter} alt="Logo"/></a>
                            </div>
                            
                            <span className="copyright">&copy; 2020-2024 Adolfo Ordinola. All Rights Reserved.</span>

                            {/* Divider */}
                            <hr className="footer__divider" />
                        </div>

                        {/* Experiencia */}
                        <div className="footer__column">
                            <h6 className="footer__title">Experiencia</h6>
                            <div className="footer__list">
                                <Link to="/universidad" className="footer__list-link">Universidad</Link>                                
                                <Link to="/portafolio" className="footer__list-link">Proyectos</Link>
                                <Link to="/portafolio/apamed" className="footer__list-link">APAMED</Link>
                                <Link to="/portafolio/agendapdf" className="footer__list-link">AgendaPDF</Link>
                            </div>
                        </div>

                        {/* Negocios */}
                        <div className="footer__column">
                            <h6 className="footer__title">Business</h6>
                            <div className="footer__list">
                                <Link to="/contacto" className="footer__list-link">Contacto</Link>
                                <Link to="/propuestas" className="footer__list-link">Propuestas</Link>
                                <Link to="/curriculum" className="footer__list-link">Curriculum (english <span role='img' aria-label='Emoji'>🇺🇸</span>)</Link>
                                <Link to="/curriculum-spanish" className="footer__list-link">Curriculum (español <span role='img' aria-label='Emoji'>🇲🇽</span>)</Link>
                            </div>
                        </div>

                        {/* Compañía */}
                        <div className="footer__column">
                            <h6 className="footer__title">Presencia</h6>
                            <div className="footer__list">
                                <Link to="/entrevistas" className="footer__list-link">Entrevistas</Link>
                                <Link to="/menciones" className="footer__list-link">Menciones</Link>
                                <Link to="/presencia-nacional" className="footer__list-link">Presencia nacional</Link>
                                <Link to="/presencia-internacional" className="footer__list-link">Presencia internacional</Link>
                            </div>
                        </div>

                    </div>

                </div>

                {/* Sección superior: Microsoft Store, Education, Business, Company
                <div className="footer__bottom">

                    <Link className="Sidebar-link" to="/entrar"><img className="Sidebar-icon-bottom" src={iconIniciarSesion} alt="Botón"/>Entrar</Link>

                </div>
                */}

            </footer>

        );
    }
}


export default Footer;