import React from 'react';

import '../global.css';
import '../componentes/estilos/Badge.css';
import BadgeBuscarCuenta from '../componentes/SesionIniciada/BadgeBuscarCuenta.js';

class BuscarCuenta extends React.Component {    


    state = { form: {

        nombrePublico: '',
        nombreUsuario: '',
    }};

    handleChange = e =>{

        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    };


    render(){
        document.title = this.props.title;
        return(

            <React.Fragment>
                
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    
                    <BadgeBuscarCuenta/>

                    {/* Sección */}
                    <div className="header-media-pantalla">
                        <span className='span-centrado-light'>Buscar cuenta</span>
                        <span>Tu experiencia funciona mejor cuando sigues a las personas que te interesan. Busca el <b>nombre de usuario</b> de algún usuario para conectar con ellos.</span><br/>
                        <br/>
                        <span>Si aún no están en el sitio, cuéntales sobre nosotros.</span><br/>
                        <br/>
                        <span>Todas las cuentas que agregues estarán en la sección de <b>Contactos</b>.</span><br/>
                    </div>
                    

                </div>

            </React.Fragment>
        );
    }
                    
}


export default BuscarCuenta;