import React from 'react';

import "../global.css";
import "./estilos/TarjetaPerfil.css";
// import header from '../imagenes/iconos/icon_mybio_fans_512px.png';

//Iconos importados
import iconoFacebook from '../imagenes/iconos/icon-facebook.svg';
import iconoInstagram from '../imagenes/iconos/icon-instagram.svg';
import iconoLinkedin from '../imagenes/iconos/icon-linkedin.svg';
import iconoTwitter from '../imagenes/iconos/icon-twitter.svg';

// import iconFacebook from '../imagenes/iconos/icon-facebook.svg';
// import iconTelegram from '../imagenes/iconos/icon-telegram.svg';
// import iconPaypal from '../imagenes/iconos/icon-paypal.svg';

import iconCuentaVerificada from '../imagenes/iconos/icon_usuario_verificado_128px.png';


class TarjetaPerfil extends React.Component{

    constructor(props) {
        super(props);
    
        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
    }


    // EVENTOS ---------------
    
    handleMensajeCuentaVerificada = (e) => {
        e.preventDefault();

        const mensaje = `Esta cuenta ha comprobado que pertenece a alguien con influencia en internet (o en la vida real)`;
        alert(mensaje);        
    }

    
    render(){
        

        return(

            <div className="TarjetaPerfil">

                <div className="TarjetaPerfil__header">
                    <img className="icon-verificado" src={iconCuentaVerificada} hidden={!this.props.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                <div className="TarjetaPerfil__section-name">
                    
                    <div hidden={!this.props.rutaFotoPerfil} className="imagen-avatar">
                        <img src={this.props.rutaFotoPerfil} alt=""/>
                    </div>
                    
                    <h5> {this.props.nombrePublico} </h5>
                    <h4> @{this.props.nombreUsuario} </h4>
            
                </div>

                <div hidden={!this.props.biografia} className="TarjetaPerfil__section-biografia">
                    {this.props.biografia}        
                </div>

                <div className="lista-iconos-tarjeta">
                    <a hidden={!this.props.redFacebook} href={"https://facebook.com/" + this.props.redFacebook} target="_blank" rel="noopener noreferrer"> <img className="icon-tarjeta" src={iconoFacebook} alt="Icono de Facebook"/> </a>
                    <a hidden={!this.props.redInstagram} href={"https://instagram.com/" + this.props.redInstagram} target="_blank" rel="noopener noreferrer"> <img className="icon-tarjeta" src={iconoInstagram} alt="Icono de Instagram"/> </a>
                    <a hidden={!this.props.redLinkedin} href={"https://linkedin.com/in/" + this.props.redLinkedin} target="_blank" rel="noopener noreferrer"><img className="icon-tarjeta" src={iconoLinkedin} alt="Icono de Linkedin" /> </a>
                    <a hidden={!this.props.redTwitter} href={"https://twitter.com/" + this.props.redTwitter} target="_blank" rel="noopener noreferrer"><img className="icon-tarjeta" src={iconoTwitter} alt="Icono de Twitter" /> </a>
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default TarjetaPerfil;