import React, { useState, useEffect } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import Layout from './Layout.js';
import ScrollToTop from './ScrollToTop.js';
import MetaTitulo from '../MetaTitulo.js';


// HOME
import Inicio from '../../paginas/principales/Inicio.js';
import Menciones from '../../paginas/principales/Menciones.js';

// Navbar
import Portafolio from '../../paginas/principales/Portafolio.js';
import Blog from '../../paginas/principales/Blog.js';
import SobreMi from '../../paginas/principales/SobreMi.js';
import Contacto from '../../paginas/principales/Contacto.js'; // Footer

// Otras páginas
import LeerPost from '../../paginas/LeerPost.js';
import LeerEntrada from '../../paginas/LeerEntrada.js';
import Biografia from '../../paginas/principales/Adolfo.js';

// Páginas
import LeerPagina from '../../paginas/LeerPagina.js';
// import PaginaExterna from '../../paginas/PaginaExterna.js';
import Apuntes from '../../paginas/principales/Apuntes.js';

// Necesarias
import Privacidad from '../../paginas/principales/Privacidad.js';

// Equipo
// import FortinTech from '../../paginas/principales/FortinTech.js';
// import Armando from '../../paginas/principales/Armando.js';

// Administración
import CrearCuenta from '../../paginas/principales/CrearCuenta.js';
import IniciarSesion from '../../paginas/principales/IniciarSesion.js';
import RecuperarCuenta from '../../paginas/principales/RecuperarCuenta.js';

// Página NO encontrada
import NotFound from '../../paginas/NotFound.js';

// Alternar entre Temas (claro y oscuro)
import "./estilos/BotonThemeDark.css";
import iconoThemeLight from '../../imagenes/iconos/icon-tema-dia.svg';
import iconoThemeDark from '../../imagenes/iconos/icon-tema-noche.svg';

function App() {

    // Variable de estado de tema
    const [theme, setTheme] = useState("light");

    // Variable de estado de tiempo (ocultar botón)
    const [ showButton, setShowButton] = useState(true);

    useEffect(() => {
        // Obtiene la preferencia del usuario del almacenamiento local
        const localTheme = window.localStorage.getItem("theme");

        //  ... o usa 'light' por defecto
        setTheme(localTheme || "light");
        
        // Oculta el botón circular después de 5 segundos
        setTimeout(() => {
            setShowButton(false);
        }, 5000);
    }, []);

    // Captura el evento onScroll
    document.onscroll = function() {comprobarScroll()};

    // Se llama al ocurrir un cambio en el scroll
    function comprobarScroll(){

        // Muestra el botón
        setShowButton(true);

        // Oculta el botón circular después de 5 segundos
        setTimeout(() => {
            setShowButton(false);
        }, 5000);
    };

    // Cambia entre 'light' y 'dark'
    const toggleTheme = () => {
        
        if (theme === "light") { // Establece el tema claro (light)

          setTheme("dark");
          window.localStorage.setItem("theme", "dark");
          // window.alert("dark");
          document.getElementById('imgBotonTheme').src = iconoThemeLight;

        } else { // Establece el tema oscuro (dark)

          setTheme("light");
          window.localStorage.setItem("theme", "light");
          // window.alert("light");
          document.getElementById('imgBotonTheme').src = iconoThemeDark;
        }
    };

    return (

        <BrowserRouter>
            
            <Layout theme={theme}>

                    
                <ScrollToTop/>
                {showButton && (
                    <div className='BotonThemeDark' onClick={toggleTheme} title='Cambiar tema'>
                        <img id='imgBotonTheme' src={iconoThemeDark} alt=""/>
                    </div>
                )}
                <Switch>
                    
                        <Route exact path="/" render={ props => <Inicio theme={theme} {...props} />}/>

                        <Route exact path="/portafolio" render={ props => <Portafolio theme={theme} {...props} />}/>
                        <Route exact path="/menciones" render={ props => <Menciones theme={theme} {...props} />}/>
            
                        <Route exact path="/blog" render={ props => <Blog theme={theme} {...props} />}/>
                        <Route exact path="/sobre-mi" render={ props => <SobreMi theme={theme} {...props} />}/>
                        <Route exact path="/contacto" render={ props => <Contacto theme={theme} {...props} />}/>
                        

                        <Route exact path="/biografia" render={ props => <Biografia title={MetaTitulo("Biografía")} theme={theme} {...props} />}/>

                        <Route exact path="/acerca-de" render={ props => <SobreMi title={MetaTitulo("Acerca de")} theme={theme} {...props} />}/>
                        <Route exact path="/privacidad" render={ props => <Privacidad title={MetaTitulo("Privacidad")} theme={theme} {...props} />}/>

                        {/* Apuntes */}
                        <Route exact path="/apuntes" render={ props => <Apuntes title={MetaTitulo("Apuntes")} theme={theme} {...props} />}/>

                        
                        <Route exact path="/crear-cuenta" render={ props => <CrearCuenta title={MetaTitulo("Crear cuenta")} theme={theme} {...props} />}/>
                        <Route exact path="/entrar" render={ props => <IniciarSesion title={MetaTitulo("Entrar")} {...props} theme={theme} />}/>
                        <Route exact path="/recuperar-cuenta" render={ props => <RecuperarCuenta title={MetaTitulo("Recuperar cuenta")} theme={theme} {...props} />}/>

                        <Route exact path="/perfil" component={IniciarSesion}/>
                        <Route exact path="/editar-perfil" component={Inicio}/>

                        <Route exact path="/sitemap.txt"/>
                        <Route exact path="/server"/>

                        {/* Páginas externas
                        <Route path="/hb" render={ props => <PaginaExterna title={MetaTitulo("Página externa")} theme={theme} {...props} /> } />
                        */}

                        {/* URLs dinámicas */}
                        <Route path={"/post/:idReferencia"} render={ props => <LeerPost title={MetaTitulo("Post")} theme={theme} {...props} />}/>
                        <Route path={"/blog/:idReferencia"} render={ props => <LeerEntrada title={MetaTitulo("Blog")} theme={theme} {...props} />}/>
                        <Route path={"/:idReferencia"} render={ props => <LeerPagina title={MetaTitulo("Cargando...")} theme={theme} {...props} /> } />
                        <Route render={ props => <NotFound title={"Página no encontrada"} theme={theme} {...props} />}/>

                </Switch>

            </Layout>
            
        </BrowserRouter>
    );

    
}


export default App;