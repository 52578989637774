import React from 'react';
// import { Link } from 'react-router-dom';

import '../global.css';
import imagenPrincipal from '../imagenes/inicio-door-hub.svg'

class NotFound extends React.Component {

    render() {
        document.title = this.props.title;
        return (
            <React.Fragment>
                <div className="header-pantalla-completa" style={{ margin: 'auto', padding: '5%', minHeight:'80vh' }} theme={this.props.theme}>
    
                    <img className="imagen-media-pantalla" src={imagenPrincipal} alt="Imagen"/>
                        
                    <div className="header-media-pantalla">
                        <h1>ERROR 404</h1> 
                        <h2>Página no encontrada <span role='img' aria-label='Emoji'>🙁</span></h2>
                        <br/>
                        {/* El elemento Link rompe la web al llamarla desde una consulta del Objeto SubPagina.js */}
                        {/* <Link className="btn btn-primary" to="/">Ir al inicio</Link> */}
                    </div>
    
                </div>
    
            </React.Fragment>
    
    
        );
    }
}

export default NotFound;