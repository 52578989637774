import React from 'react';

import './estilos/BotonScrollTop.css';

import icono from '../../imagenes/iconos/icon-subir.svg';


// Se llama al ocurrir un cambio en el scroll
function comprobarScroll(){

    const boton = document.getElementById('botonScrollTop');

    // Si se ha desplazado, se muestra el botón (en px)
    if (document.body.scrollTop > 700 || document.documentElement.scrollTop > 700) {
        boton.className = 'BotonScrollTop';    
    } else { // Si está en 0, se oculta el botón
        boton.className = 'BotonScrollTop-oculto';
    }

}


class BotonScrollTop extends React.Component {

    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Captura el evento onScroll
        document.onscroll = function() {comprobarScroll()};        
        
        // ----------------------------

        this.handleSubir = this.handleSubir.bind(this);
    }

    /* Eventos */
    handleSubir(event){
        event.preventDefault();

        // Se sube al Top
        window.scrollTo(0, 0);
        // console.log("Position en px: " + window.scrollY);
    }


    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div id='botonScrollTop' className='BotonScrollTop-oculto' onClick={this.handleSubir} title='Subir'>

                <img src={icono} alt=""/>

            </div>
        );
    }
}


export default BotonScrollTop;