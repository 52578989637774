export default class Siguiendo {

    constructor (uid, date) {

        this.uid = uid;
        this.date = date;
    }

    toString() {
        return this.uid + ', ' + this.date;
    }

    // Firestore data converter
    siguiendoConverter = {
        toFirestore: (siguiendo) => {
            return {
                uid: siguiendo.uid,
                date: siguiendo.date
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Siguiendo(data.uid, data.date);
        }
    }

    
}