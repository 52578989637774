import React from 'react'
import { Link } from 'react-router-dom';

import '../global.css';

// Objetos personales
// import { App as NavbarPublicar } from '../componentes/SesionIniciada/NavbarPublicar.js';
import NavbarPublicar from '../componentes/SesionIniciada/NavbarPublicar.js';
import SidebarAdministrador from '../componentes/Administracion/SidebarAdministrador.js';
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import SubPagina from '../componentes/Objetos/ObjetoSubPagina.js';
import BadgePosts from '../componentes/SesionIniciada/BadgePosts.js';

// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, query, /* where, */ orderBy, limit } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;

// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// Creamos una lista global
let lista = [];

// ------------------------

class Home extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // const user = auth.currentUser;
        mUid = auth.currentUser.uid;
        // console.log("UID: "+mUid);


        // Se muestran datos provisionales
        this.state = {

            mensajeTareas: "",
            idReferencia: "",
            ListaPosts: lista
        };

        // mUsuario = this.props.mUsuario;
        // console.log(this.props.nombrePublico);

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            mUid = auth.currentUser.uid;
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                    
                return Promise.resolve(docSnap);
            });

        }

        this.getPosts();

        // Handles's
        
    }

    // ENTRADAS --------------------
    async getPosts(){
        
        // Creamos una lista local
        let lista = [];

        const referenciaPosts = collection(db, `Posts/${mUid}/Estado`);

        // Create a query against the collection.
        const q = query(referenciaPosts, orderBy("date", "desc"), limit(5)).withConverter(SubPagina.subPaginaConverter);
        // const q = query(referenciaPosts, orderBy("date", "desc")).withConverter(SubPagina.subPaginaConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    // Variable de tipo Entrada.class
                    var objSubPagina = new SubPagina('','','','','','','','','','','','','','','');
                    objSubPagina = documento.data();

                    let idReferencia = documento.id;
                    let idSubPagina = documento.id;

                    let autor = objSubPagina.autor;
                    let date = objSubPagina.date;

                    let categoria = objSubPagina.categoria;
                    let portada = objSubPagina.portada;
                    let texto = objSubPagina.texto;

                    // Analítica
                    var noComments = objSubPagina.noComments;
                    if (noComments === 0){ noComments = ""}

                    var noReposts = objSubPagina.noReposts;
                    if (noReposts === 0){ noReposts = ""}

                    var noLikes = objSubPagina.noLikes;
                    if (noLikes === 0){ noLikes = ""}

                    var noShared = objSubPagina.noShared;
                    if (noShared === 0){ noShared = ""}

                    var noSaved = objSubPagina.noSaved;
                    if (noSaved === 0){ noSaved = ""}

                    // Convertimos Timestamp a Date
                    const dateEntrada = date.toDate();

                    /* NECESARIO para la tarjeta */
                    // "día, fecha de mes de año"
                    var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                    // "hora:minuto am/pm"
                    // var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);
                    

                    // Separamos para mostrar una URL más amigable
                    const urlPrivada = "autor_" + autor + "_fecha_";
                    // const urlPublica = idReferencia.replace(urlPrivada, "");
                    const urlPublica = objSubPagina.urlPublic;


                    var nombrePublico = mUsuario.nombrePublico;
                    var nombreUsuario = mUsuario.nombreUsuario;

                    // Creamos un objeto local
                    let objeto = {
                        idReferencia, idSubPagina, urlPrivada, urlPublica, autor, 
                        date, categoria, portada, texto, fechaString,
                        noComments, noReposts, noLikes, noShared, noSaved,
                        nombrePublico, nombreUsuario };

                    // Agregamos el objeto local a la lista local
                    lista.push(objeto);                

                    /*
                    var objetoUsuario = new Usuario('','','','','','','','');
                    const ref = doc(db, "Usuarios", autor).withConverter(objetoUsuario.usuarioConverter);
                    const docSnap =  getDoc(ref);

                    // Se piden los datos
                    docSnap.then((snapshot) => {
                                                    
                        objetoUsuario = snapshot.data();
                        nombrePublico = objetoUsuario.nombrePublico;
                        nombreUsuario = objetoUsuario.nombreUsuario;

                        // Creamos un objeto local
                        let objeto = {idReferencia, autor, date, portada, titulo, subtitulo, texto, horaString, nombrePublico, nombreUsuario };

                        // Agregamos el objeto local a la lista local
                        lista.push(objeto);

                        // Se actualiza el estado
                        this.setState(() => {
                            return {
                                idReferencia: idReferencia,
                                ListaTareas: lista
                            }
                        });
                    });
                    */

                    // Obtenemos cuántas entradas hay
                    var numeroEntradas = lista.length;
                    // Texto debajo de las entradas
                    const mensajeNumeroTareas = `Últimas ${numeroEntradas} entradas del blog`;

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            mensajeTareas: mensajeNumeroTareas,
                            idReferencia: idReferencia,
                            ListaPosts: lista
                        }
                    });
                });

            if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaPosts: lista
                        }
                    });

            }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    /* Eventos */

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (

            <React.Fragment>

                {/* Últimas entradas del blog */}
                <div className="header-pantalla-completa-sin-bordes" theme={this.props.theme}>

                        {/* Menú Home de administrador */}
                        {/* <SidebarAdministrador theme={this.props.theme}/> */}

                        {/* Acceso rápido para crear una entrada */}
                        <NavbarPublicar theme={this.props.theme}/>

                        {/* Entradas del blog */}
                        {this.state.ListaPosts.map((lista, i) => (

                            <BadgePosts
                                theme={this.props.theme}

                                // key={lista.id}
                                key={i}
                                idItem={`ID${lista.idReferencia}`}
                                
                                id={lista.id}
                                idReferencia={lista.idReferencia}
                                idSubPagina={lista.idSubPagina}
                                urlPrivada={lista.urlPrivada}
                                urlPublica={lista.urlPublica}

                                public={false}

                                autor={lista.autor}
                                date={lista.date}
                                
                                categoria={lista.categoria}
                                portada={lista.portada}
                                texto={lista.texto}

                                noComments={lista.noComments}
                                noReposts={lista.noReposts}
                                noLikes={lista.noLikes}
                                noShared={lista.noShared}
                                noSaved={lista.noSaved}
                                
                                fechaString={lista.fechaString}
                                rutaFotoPerfil={mUsuario.rutaFotoPerfil}
                                verificado={mUsuario.verificado}
                                nombrePublico={lista.nombrePublico}
                                nombreUsuario={lista.nombreUsuario}
                            />
                        ))}

                </div>

                <div className='br' theme={this.props.theme}/>
                
                {/* Número de entradas */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    <Link to='/blog'>- Ver todas las publicaciones -</Link>
                </div>

                <div className='br' theme={this.props.theme}/>


            </React.Fragment>


        );
    }

}

export default Home;