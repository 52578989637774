import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeEntrada.css";

// Objetos personales
import Entrada from './ObjetoEntrada.js';

//Iconos importados
import iconTareaIncompleta from '../../imagenes/iconos/icon-tarea-incompleta.svg'
import iconTareaCompletada from '../../imagenes/iconos/icon-tarea-completada.svg'
import iconBorrarPortada from '../../imagenes/iconos/icon-borrar-imagen.svg'
import iconEditarTarea from '../../imagenes/iconos/icon-editar-tarea.svg'
// import iconActualizarTarea from '../../imagenes/iconos/icon-actualizar-tarea.svg'
// import iconCancelarActualizacionTarea from '../../imagenes/iconos/icon-cancelar-actualizacion-tarea.svg'

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// ------------------------

const db = getFirestore();

var objEntrada = new Entrada('','','','');

// ------------------------

class BadgeEntrada extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // objTarea = new Entrada(this.props.uid, this.props.date, this.props.texto, this.props.leido);

        // ID de la Tarea
        // idReferencia = this.props.idReferencia;

        // const uid = this.props.uid;
        // const mUid = this.props.idCreador;

        if (this.props.privacidad === "🔐 Entrada privada"){

            // Estado
            this.state = {
                iconCompletada: iconTareaIncompleta,
                iconPortadaEliminada: iconBorrarPortada,
                estiloPrivacidad: { color: '#333333', background: '#5aff8920' }
            };

        } else if (this.props.privacidad === "🌎 Entrada pública"){

            // Estado
            this.state = {
                iconCompletada: iconTareaIncompleta,
                iconPortadaEliminada: iconBorrarPortada,
                estiloPrivacidad: { color: '#333333', background: '#56a8ff20' }
            };

        } else {

            // Estado
            this.state = {
                iconCompletada: iconTareaIncompleta,
                iconPortadaEliminada: iconBorrarPortada
            };
        }
        
        // ----------------------------        

        this.handleEliminarEntrada = this.handleEliminarEntrada.bind(this);
        this.handleEliminarPortada = this.handleEliminarPortada.bind(this);
        this.handleCompartir = this.handleCompartir.bind(this);
    }

    /* Eventos */
    handleEliminarEntrada = (e) => {
        e.preventDefault();

        // Se solicita confirmación
        const mensaje = `⚠ ELIMINAR\n\nEsto hará que se elimine de tu lista de entradas.`;
        const isCompletado = window.confirm(mensaje);

        if(isCompletado){

            // UID de la persona dueña de las Tareas
            const uidPersona = this.props.autor;

            // UID de la persona que creó la Tarea
            // const uidDefault = this.props.uid;

            // console.log(`Blog/${uidPersona}/Entrada/${this.props.idReferencia}`);

            // Si existe una portada, se elimina
            if(this.props.portada !== ""){

                // Separamos para mostrar una URL más amigable
                const idReferencia = this.props.idReferencia;
                const urlPrivada = "autor_" + uidPersona + "_fecha_";
                const urlPublica = idReferencia.replace(urlPrivada, "");

                const url = "Blog/" + uidPersona + "/Entrada/" + urlPublica + "/portada";
                // url = `Blog/${mUid}/foto-perfil`;

                const storage = getStorage();
                const portadaRef = ref(storage, url);

                // Delete the file
                deleteObject(portadaRef)
                .then(() => {

                    // La URL Privada sólo se envía al haber iniciado sesión
                    // const idReferencia = this.props.urlPrivada + this.props.urlPublica;
                    // Se elimina la entrada
                    const refEntrada = doc( db, `Blog/${uidPersona}/Entrada/${idReferencia}`).withConverter(objEntrada.entradaConverter);
                    deleteDoc(refEntrada);

                    // Se cambia el estilo
                    this.setState(() => {
                        return {
                            iconCompletada: iconTareaCompletada,
                            estiloTarea: { textDecoration:'line-through', color: '#7d7d7d50' },
                        }
                    });

                    // console.log(`La entrada ${this.props.titulo} ha sido eliminada.`);
                    alert(`La entrada "${this.props.titulo}" ha sido eliminada.`);

                }).catch((error) => {
                    // Uh-oh, an error occurred!
                    console.log(`Ocurrió un error:\n\n ${error}`);
                });


            } else{
                
                // La URL Privada sólo se envía al haber iniciado sesión
                const idReferencia = this.props.urlPrivada + this.props.urlPublica;
                // Se elimina la entrada
                const refEntrada = doc( db, `Blog/${uidPersona}/Entrada/${idReferencia}`).withConverter(objEntrada.entradaConverter);
                deleteDoc(refEntrada);

                // Se cambia el estilo
                this.setState(() => {
                    return {
                        iconCompletada: iconTareaCompletada,
                        estiloTarea: { textDecoration:'line-through', color: '#7d7d7d50' },
                    }
                });

                // console.log(`La entrada ${this.props.titulo} ha sido eliminada.`);
                alert(`La entrada "${this.props.titulo}" ha sido eliminada.`);
            }

            // Actualizamos la vista
            // window.location.reload();
            
        }
        

    }

    handleEliminarPortada = (e) => {
        e.preventDefault();

        // Se solicita confirmación
        const mensaje = `⚠ ELIMINAR PORTADA\n\nEsto hará que se elimine la imagen de portada.`;
        const isCompletado = window.confirm(mensaje);

        if(isCompletado){

            // UID de la persona dueña de las Tareas
            const uidPersona = this.props.autor;

            // UID de la persona que creó la Tarea
            // const uidDefault = this.props.uid;

            // console.log(`Blog/${uidPersona}/Entrada/${this.props.idReferencia}`);

            // Si existe una portada, se elimina
            if(this.props.portada !== ""){

                // Separamos para mostrar una URL más amigable
                const idReferencia = this.props.idReferencia;
                const urlPrivada = "autor_" + uidPersona + "_fecha_";
                const urlPublica = idReferencia.replace(urlPrivada, "");

                const url = "Blog/" + uidPersona + "/Entrada/" + urlPublica + "/portada";
                // url = `Blog/${mUid}/foto-perfil`;

                const storage = getStorage();
                const portadaRef = ref(storage, url);

                // Delete the file
                deleteObject(portadaRef)
                .then(() => {

                    // const rutaFotoPortada = "";
                    // const objEntrada = new Entrada(rutaFotoPortada);

                    // La URL Privada sólo se envía al haber iniciado sesión
                    // const idReferencia = this.props.urlPrivada + this.props.urlPublica;
                    // Se elimina la entrada
                    const refEntrada = doc( db, `Blog/${uidPersona}/Entrada/${idReferencia}`);
                    updateDoc(refEntrada, {portada: ""});


                    // Se cambia el estilo
                    this.setState(() => {
                        return {
                            iconPortadaEliminada: iconTareaCompletada
                        }
                    });

                    // console.log(`La entrada ${this.props.titulo} ha sido eliminada.`);
                    alert(`La imagen de portada de "${this.props.titulo}" ha sido eliminada.`);

                }).catch((error) => {
                    // Uh-oh, an error occurred!
                    console.log(`Ocurrió un error:\n\n ${error}`);
                });


            } else {
                alert(`La entrada "${this.props.titulo}" no tiene imagen de portada.`);
            }
            
        }
        

    }

    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }

    /* Lo que mostramos en pantalla */
    render(){    

        return(

            <div className="BadgeEntrada" id={this.props.idReferencia} title={this.props.titulo}>

                <div hidden={this.props.public} className="BadgeEntrada__header">
                    <img className="icon-estado-tarea" src={this.state.iconCompletada} alt="" onClick={this.handleEliminarEntrada} title='Eliminar entrada'/>
                    <img hidden={!this.props.portada} className="icon-estado-tarea" src={this.state.iconPortadaEliminada} alt="" onClick={this.handleEliminarPortada} title='Eliminar imagen de portada'/>
                    <Link to={"/editar-entrada/"+this.props.urlPublica}><img className="icon-editar" src={iconEditarTarea} alt="" title='Editar entrada'/></Link>
                </div>

                <Link  to={"/blog/"+this.props.urlPublica}>

                    {/* Imagen de portada */}
                    <img hidden={!this.props.portada} className="BadgeEntrada__section-imagen-portada" src={this.props.portada} alt="" title={this.props.titulo}/>

                    <div>
                        {/* Privacidad */}
                        <div hidden={!this.props.privacidad} className="BadgeEntrada__section-categoria" title="Privacidad" style={this.state.estiloPrivacidad}>
                            {this.props.privacidad}
                        </div>

                        {/* Categoría */}
                        <div hidden={!this.props.categoria} className="BadgeEntrada__section-categoria" id={this.props.idReferencia + "_div"} title="Categoría">
                            {this.props.categoria}
                        </div>
                    </div>

                    {/* Título */}
                    <div className="BadgeEntrada__section-titulo" title={this.props.titulo} style={this.state.estiloTarea}>
                        {this.props.titulo}
                    </div>

                    {/* Texto */}
                    <p className="BadgeEntrada__section-texto" id={this.props.idReferencia + "_div"} /* style={this.state.estiloTarea} */ >{this.props.texto}</p>
                    {/* <textarea className="BadgeEntrada__section-texto" disabled={true} rows={4} style={this.state.estiloTarea}
                        id={this.props.idReferencia + "_div"} defaultValue={this.props.texto} readOnly={true}/>
                    */}

                    {/* Fecha */}
                    <div className="BadgeEntrada__horaProgramado">
                        {this.props.fechaString}
                    </div>

                    {/* Autor */}
                    <div className="BadgeEntrada__usuarioProgramado">
                        por {this.props.nombrePublico}
                    </div>

                </Link>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeEntrada;