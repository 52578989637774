export default class Buscador {
    
    constructor ( tipo, name, firstDescription, secondDescription, thirdDescription ) {

        this.tipo = tipo;
        this.name = name;

        this.firstDescription = firstDescription;
        this.secondDescription = secondDescription;
        this.thirdDescription = thirdDescription;
    }

    toString() {
        return this.tipo + ', ' + this.name + ', ' +
        this.firstDescription + ', ' + this.secondDescription + ', ' + this.thirdDescription;
    }

    // Firestore data converter
    buscadorConverter = {
        toFirestore: (buscador) => {
            return {

                tipo: buscador.tipo,
                name: buscador.name,

                firstDescription: buscador.firstDescription,
                secondDescription: buscador.secondDescription,
                thirdDescription: buscador.thirdDescription
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Buscador(data.tipo, data.name,
                data.firstDescription, data.secondDescription, data.thirdDescription);
        }
    }
}