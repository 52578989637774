import React from 'react';

import '../../global.css';
import '../../componentes/estilos/Badge.css';
import BadgeRecuperarCuenta from '../../componentes/Main/BadgeRecuperarCuenta.js';

const banner = "https://images.unsplash.com/photo-1453134765485-7f9a71a803d5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1767&q=80";

class RecuperarCuenta extends React.Component {    


    state = { form: {

        nombrePublico: '',
        nombreUsuario: '',
        correo: '',
        contraseña: '',
    }};

    handleChange = e =>{

        /*1er Forma de conservar la persistencia
        const nextForm = this.state.form;
        nextForm[e.target.name] = e.target.value;*/

        //1er Forma de conservar la persistencia
        //this.setState(form: nextForm,);

        //2da Forma de conservar la persistencia

        //Dejo caer todos los valores del form anteriormente
        //Y sobreescribimos el que ya estaba guardado

        this.setState({  
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    };


    render(){
        document.title = this.props.title;
        return(

            <React.Fragment>
                
                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Recuperar cuenta</h1>
                        <h2>Ingresa tu correo para continuar</h2>
                    </div>
                </div>

                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className='header-media-pantalla'>
                        <span className='span-centrado'>Recupera tu cuenta</span>
                        <span>Si ya tienes una cuenta pero olvidaste tu contraseña, recuperar el acceso muy simple.</span><br/>
                        <br/>                        
                        <span>Sólo tienes que poner el correo de tu cuenta y se te enviará un email para reiniciar tu contraseña.</span><br/>
                        <br/>
                        <span>En menos de 2 minutos podrás acceder de nuevo.</span><br/>
                        <br/>
                        <span><b>No olvides revisar tu carpeta de SPAM (correo no deseado).</b></span><br/>
                    </div>

                    <div className="header-media-pantalla">

                            <BadgeRecuperarCuenta
                                nombreUsuario={this.state.form.nombreUsuario}
                                nombrePublico={this.state.form.nombrePublico}
                                avatarUrl="https://es.gravatar.com/userimage/189320568/c417c2e9be1c13cd0a4e94a5f8a0ba69.jpg?size=200"

                                correo={this.state.form.correo}
                                contraseña={this.state.form.contraseña}/>
                            <br/>        
                                        
                    </div>
                </div>

            </React.Fragment>
        );
    }
                    
}


export default RecuperarCuenta;