import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeCrearMencion.css";

// Objetos personales
import Mencion from '../Objetos/ObjetoMencion.js';

// Importamos los componentes de Firebase
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

// ------------------------

// Referencias a Firestore
const db = getFirestore();
const storage = getStorage();

// Para seleccionar la foto
var rutaLogotipo = "";
var file;
var urlNuevoLogotipo = "";
var referenciaStorage;

var valorImportancia = "";

// ------------------------

class BadgeCrearMencion extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        rutaLogotipo = this.props.logotipo;
        valorImportancia = this.props.importancia;

        if( this.props.postNuevo === true ){

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                picture: banner,
                mostrarSeleccionFoto: true,

                textoBoton: "",
                textoBotonSubirEntrada: "Subir mención",

                importanciaSeleccionada: "✍🏽 Normal",
            };

        } else {

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                picture: this.props.logotipo,
                mostrarSeleccionFoto: true,

                textoBoton: "",
                textoBotonSubirEntrada: "Actualizar mención",

                importanciaSeleccionada: this.props.importancia
            };

        }

        // console.log(idReferencia);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        
        // ----------------------------

        this.handleMostrarPortada = this.handleMostrarPortada.bind(this);
        this.handleElegirFoto = this.handleElegirFoto.bind(this);
        this.handleElegirImportancia = this.handleElegirImportancia.bind(this);
        this.handleSubirEntrada = this.handleSubirEntrada.bind(this);

        // this.updateEstado = this.updateEstado.bind(this);
    }

    /* Eventos */
    handleMostrarPortada(event){
        event.preventDefault();

        // Para que no se cargue la foto seleccionada
        urlNuevoLogotipo = "";

        if(this.props.postNuevo){

            this.setState({
                picture: banner,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        } else {

            this.setState({
                picture: this.props.logotipo,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        }
    }

    handleElegirFoto(event){

        file = event.target.files[0];
        if (file !== null){

            const objectURL = URL.createObjectURL(file);
            // Para que se cargue la foto seleccionada
            urlNuevoLogotipo = "Imagen cargada";

            this.setState({
                valorSubida: 0,
                picture: objectURL,
                mostrarSeleccionFoto: false,
                textoBoton: "Restablecer"
            });
        }
        
    }

    handleElegirImportancia = (e) => {
        e.preventDefault();

        valorImportancia = e.target.value;
        // console.log("Valor: " + valorCategoria)
        this.setState({
            importanciaSeleccionada: valorImportancia
        });
        
    }

    handleSubirEntrada = (e) => {
        e.preventDefault();

        // var privacidad = this.state.privacidadSeleccionada;
        var importancia = e.target.elements.importancia.value;

        rutaLogotipo = this.props.logotipo;

        const autorNuevo = e.target.elements.autor.value;
        const descripcionAutorNueva = e.target.elements.descripcionAutor.value;
        const citaNueva = e.target.elements.cita.value;
        const urlCitaNueva = e.target.elements.urlCita.value;


        if (autorNuevo === null || autorNuevo === "") {
            const mensaje = `Escribe un Autor`;
            alert(mensaje);

        } else if (descripcionAutorNueva === null || descripcionAutorNueva === "") {
            const mensaje = `Escribe algo sobre el autor`;
            alert(mensaje);

        } else if (citaNueva === null || citaNueva === "") {
            const mensaje = `Escribe la cita que habla sobre ti`;
            alert(mensaje);

        } else if (urlCitaNueva === null || urlCitaNueva === "") {
            const mensaje = `Escribe la URL de la mención del medio`;
            alert(mensaje);

        } else {

            var urlPublica;
            var fecha = new Date();

            // const date = new Date();
            if (this.props.urlPublica !== ""){

                urlPublica = this.props.urlPublica;

            } else {

                urlPublica =
                fecha.getFullYear() +
                "_" +
                (fecha.getMonth()+1) +
                "_" +
                (fecha.getDate()) +
                "_" +
                fecha.getHours() +
                "_" +
                fecha.getMinutes() +
                "_" +
                fecha.getSeconds();
            }

            // const mensaje = `Tiempo del servidor: ` + serverTimestamp();
            // console.log(fecha);

            if (urlNuevoLogotipo !== ""){

                // Agregar file.name para tener múltiples fotos en la referencia de usuario
                const url = "Menciones/"+ urlPublica + "/logotipo";

                // url = `Blog/${mUid}/foto-perfil`;
                referenciaStorage = ref(storage, url);

                // Se sube el archivo
                const uploadTask = uploadBytesResumable(referenciaStorage, file);
                // Register three observers:
                // 1. 'state_changed' observer, called any time the state changes
                // 2. Error observer, called on failure
                // 3. Completion observer, called on successful completion
                uploadTask.on('state_changed',
                (snapshot) => {
                    // Observe state change events such as progress, pause, and resume
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    console.log('Upload is ' + progress + '% done');
    
                    this.setState({
                        valorSubida: progress
                    });
    
                    switch (snapshot.state) {
                        case 'paused':
                            console.log('Upload is paused');
                        break;
                        case 'running':
                            console.log('Upload is running');
                        break;
                        default:
                            console.log('Pasó algo más');
                        break;
                    }
                },
                (error) => {
                    
                    const mensaje = `Error cargando portada: ` + error.message ;
                    console.log(mensaje);
                },
                () => {
    
    
                    // Handle successful uploads on complete
                    // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
    
                        rutaLogotipo = downloadURL;
                        console.log(`Se subió a: ${referenciaStorage.fullPath}`);

                        const objMencion = new Mencion(importancia, fecha, rutaLogotipo, autorNuevo, descripcionAutorNueva, citaNueva, urlCitaNueva);

                        const ref = doc( db, `Menciones`, urlPublica).withConverter(objMencion.mencionConverter);
                        setDoc(ref, objMencion);

                        this.setState({
                            valorSubida: 100,
                            subidaExitosa: true
                        });
                        // alert("¡Entrada agregada!");

                        // Reiniciamos la URL del logotipo
                        urlNuevoLogotipo = "";
                        
                    });
                });
    
            } else {
    
                
                const objMencion = new Mencion(importancia, fecha, rutaLogotipo, autorNuevo, descripcionAutorNueva, citaNueva, urlCitaNueva);

                const ref = doc( db, `Menciones`, urlPublica).withConverter(objMencion.mencionConverter);
                setDoc(ref, objMencion);

                this.setState({
                    valorSubida: 100,
                    subidaExitosa: true
                });
                // alert("¡Entrada agregada!");

                // Reiniciamos los datos del formulario
                /* var tituloValue = document.getElementById("titulo");
                if (tituloValue.value !== "") {
                    tituloValue.value = "";
                }
                var subtituloValue = document.getElementById("subtitulo");
                if (subtituloValue.value !== "") {
                    subtituloValue.value = "";
                }
                var textoValue = document.getElementById("texto");
                if (textoValue.value !== "") {
                    textoValue.value = "";
                } */

                // Reiniciamos la URL del logotipo
                urlNuevoLogotipo = "";

                // La fecha no se reinicia para agilizar la creación de múltiples tareas

                // Reiniciamos los datos de la hora
                /* var horaValue = document.getElementById("hora");
                if (horaValue.value !== "") {
                    horaValue.value = "";
                } */
    
            }
            
            

        }
    };

    /* Lo que mostramos en pantalla */
    render(){

        return(

            <div>

                {/* Editable */}
                <div className="BadgeCrearMencion">

                    {/* Portada */}
                    <div className="BadgeCrearMencion-portada">

                        <img hidden={!this.state.picture} className="BadgeCrearMencion-imagen-portada" src={this.state.picture} alt="Logotipo del autor" />
                        <img hidden={this.state.picture} className="BadgeCrearMencion-imagen-portada" src={this.props.portada} alt="Puedes subir el logotipo oficial del autor" />

                        <div className="BadgeCrearMencion-header-contenido-portada">
                            
                            {/* Seleccionar foto */}
                            <input 
                                className="ocultar-vista" type="file" id="fileElem"
                                accept="image/*" onChange={this.handleElegirFoto}/>
                            <label hidden={!this.state.mostrarSeleccionFoto} className="btn btn-seleccionar-foto" htmlFor="fileElem">Seleccionar logotipo</label>
                            <button hidden={!this.state.textoBoton} className="btn btn-mostrar-foto" onClick={this.handleMostrarPortada}>{this.state.textoBoton}</button>
                        </div>
                    </div>


                    {/* Formulario */}
                    <form onSubmit={this.handleSubirEntrada}>

                        {/* Autor */}
                        <textarea className="BadgeCrearMencion-titulo" type="text" id="autor" defaultValue={this.props.titulo}
                            name="autor" placeholder="Nombre del medio" rows="1"/>
                        {/* Descripción del autor */}
                        <textarea className="BadgeCrearMencion-subtitulo" type="text" id="descripcionAutor" defaultValue={this.props.subtitulo}
                            name="descripcionAutor" placeholder="Descripción del medio" rows="1"/>
                        

                        {/* Cita */}
                        <textarea className="BadgeCrearMencion-cita" type="text" id="cita" defaultValue={this.props.subtitulo}
                            name="cita" placeholder="Cita del medio" rows="7"/>
                        {/* URL de la cita */}
                        <textarea className="BadgeCrearMencion-subtitulo" type="text" id="urlCita" defaultValue={this.props.subtitulo}
                            name="urlCita" placeholder="URL de la cita" rows="1"/>
                        

                        {/* Espacio (eliminar) */}
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>

                        {/* Opciones de la entrada */}
                        <div className='divider' theme={this.props.theme}/>
                        <div className='filtro-pantalla-completa' theme={this.props.theme}>
                            <span className='span-filtro-dia' theme={this.props.theme}>Opciones de la página</span>
                        </div>


                        {/* Opciones (barra de funciones inferior) */}
                        <div className="BadgeCrearMencion-opciones">

                            {/* Privacidad de entrada */}
                            <div className="formulario-tarea">
                                <label className="label-tarea">Nivel de importancia:</label>
                                <select className="menu-desplegable" id="importancia" defaultValue={this.state.importanciaSeleccionada} onChange={this.handleElegirImportancia}>                                    
                                    <option><span role='img' aria-label='Emoji'>✍🏽</span> Normal</option>
                                    <option><span role='img' aria-label='Emoji'>📌</span> Fijar en inicio</option>                                    
                                </select>
                            </div>                                    

                        </div>
                        

                        {/* Progress bar */}
                        <progress hidden={!this.state.valorSubida} value={this.state.valorSubida} max="100" style={{ width: "50%", height: "0.5rem", display: "flex", margin: "0 auto", alignContent: "center" }}/>
                        <br/>

                        {/* Enviar */}
                        <button hidden={this.state.subidaExitosa} className="btn btn-secondary">{this.state.textoBotonSubirEntrada}</button>
                        <br />
                    
                    </form>

                    <br/>

                    {/* Progress bar de entrada enviada */}
                    <div hidden={!this.state.subidaExitosa} style={{ width: "100%", display: "block", margin: "0 auto 50px", alignContent: "center" }}>
                        <label className='span-centrado-simple'>¡Entrada subida con éxito!</label><br/>
                        <Link className='link-simple' to="/"><big>Regresar</big></Link>
                    </div>
                                
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeCrearMencion;