import React from 'react';

import SEO from '../../componentes/Main/SEO.js';
import DataSeo from '../../constantes/DataSeo.js';

import '../../global.css';
import '../estilos/PaginaContacto.css';

//Iconos importados
import iconoFacebook from '../../imagenes/iconos/icon-facebook.svg';
import iconoInstagram from '../../imagenes/iconos/icon-instagram.svg';
import iconoLinkedin from '../../imagenes/iconos/icon-linkedin.svg';
import iconoTwitter from '../../imagenes/iconos/icon-twitter.svg';

// import imagenPrincipal from '../../imagenes/Adolfo Ordinola.png';
// import TarjetaAutor from '../../componentes/TarjetaAutor.js';
// import BadgeEnviarCorreo from '../../componentes/Main/BadgeEnviarCorreo.js';
import BadgeContacto from '../../componentes/Main/BadgeContacto.js';

const banner = DataSeo.ContactoImage;
// const imagenContacto = "https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2070&q=80";
// const imagen = "";


class Contacto extends React.Component{

    render() {
        return (
            <React.Fragment>

                {/* SEO */}
                <SEO
                    title={DataSeo.ContactoTitle}
                    description={DataSeo.ContactoDescription}
                    image={banner}
                    url={DataSeo.ContactoLink}
                    name={"@apeordi"}
                    type={"article"}
                />

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Contacto</h1>
                        <h2>Redes sociales y correo</h2>
                    </div>
                </div>
                

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="header-media-pantalla-blanca">
                        <br/>
                        <br/>
                        <h3>Adolfo Ordinola</h3>
                        <br/>
                        <span><b>CEO y cofundador de APAMED</b></span><br/>
                        <span>Desarrollador de software en Android y Web con +5 años de experiencia.</span><br/>
                        <br/>
                        <span><b>¿Tiene algo en mente?</b></span><br/>
                        <span><small>Para propuestas de trabajo o ideas, déjame un mensaje. <br/><i>Atiendo personalmente cada mensaje que envía</i>.</small></span><br/>
                        <br/>
                        <span><b>¿Prefiere algo más informal?</b></span><br/>
                        <span>Encuéntrame en todos lados como @apeordi</span><br/>
                        <div className="TarjetaAutor__list-icons">
                            <a href={"https://facebook.com/apeordi"} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoFacebook} alt="Red social" title='Facebook'/></a>
                            <a href={"https://instagram.com/apeordi"} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoInstagram} alt="Red social" title='Instagram'/></a>
                            <a href={"https://linkedin.com/in/apeordi"} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoLinkedin} alt="Red social" title='LinkedIn'/></a>
                            <a href={"https://twitter.com/intent/user?screen_name=apeordi"} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoTwitter} alt="Red social" title='Twitter'/></a>
                        </div>
                    </div>
                    
                    <div className="header-media-pantalla">
                        <BadgeContacto/>
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Contacto;