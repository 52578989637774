import React from 'react';

import './style.css';

// ------------------------

class Snackbar extends React.Component{

    /* constructor(props) {
        super(props);
    
        this.handleMensaje = this.handleMensaje.bind(this);
    } */


    // EVENTOS ---------------

    /* handleMensaje = (e) => {
        e.preventDefault();

        const notification = document.querySelector('.notification');
        
        notification.classList.remove('none');
        notification.classList.toggle('hide');
    } */

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className='snackbar-index' hidden={!this.props.mensaje}>
                <figure className="notification">
                    <div className="notification__body">
                        <div className="notification__description">
                            <div className="icon__wrapper">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" 
                                    viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor"
                                    fill="none" strokeLinecap="round" strokeLinejoin="round">

                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M5 12l5 5l10 -10"></path>
                                </svg>
                            </div>                    
                            {this.props.mensaje}
                        </div>
                    </div>
                    <div className="notification__progress"></div>
                </figure>
            </div>
        );

    }

}

export default Snackbar;