import React from 'react';
import { Link } from 'react-router-dom';

import '../Main/estilos/NavbarBottom.css';
import '../Administracion/estilos/Sidebar.css';

// import logoPrincipal from '../../imagenes/iconos/icon-logo-web.svg';

import iconInicio from '../../imagenes/iconos/icon-home.svg';
import iconPerfil from '../../imagenes/iconos/icon-perfil.svg';
import iconContactos from '../../imagenes/iconos/icon-contactos.svg';
import iconMenu from '../../imagenes/iconos/icon-menu-2.svg';


// ------------------------


const Navbar = () => (

    <nav className="Navbar__bottom">

        <div className="Navbar__bottom-lista-iconos">

            <Link className="Navbar__bottom-link" to="/"><img className="Navbar__bottom-icon" src={iconInicio} alt="Botón"/>Inicio</Link>
            <Link className="Navbar__bottom-link" to="/perfil"><img className="Navbar__bottom-icon" src={iconPerfil} alt="Botón"/>Perfil</Link>
            <Link className="Navbar__bottom-link" to="/contactos"><img className="Navbar__bottom-icon" src={iconContactos} alt="Botón"/>Contactos</Link>
            <Link className="Navbar__bottom-link" to="/menu"><img className="Navbar__bottom-icon" src={iconMenu} alt="Botón" width="18" height="18"/>Menú</Link>

        </div>
        
    </nav>
);

/* const buttonInicio = () => {

    window.scrollTo(0, 0);

}; */

export const NavbarBottom = () => {

    return (
        <div>
            <Navbar />
        </div>
    );
  
};