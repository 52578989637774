import React from 'react';

import '../global.css';

// Objetos personales
import Pagina from '../componentes/SesionIniciada/ObjetoPagina.js';
import BadgeCrearPagina from '../componentes/SesionIniciada/BadgeCrearPagina.js';

// Importamos los componentes de Firebase
import { doc, getFirestore } from "firebase/firestore";
// import { getDoc, doc } from "firebase/firestore";
import { collection, query, where, getDocs } from "firebase/firestore";


// ------------------------

const db = getFirestore();


// Variable de tipo Entrada.class
var objPagina = new Pagina('','','','','','','','','');

// ------------------------


class EditarPagina extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            urlPublica: "",

            url: "",

            privacidad: "",
            categoria: "",

            autor: mUid,
            portada: "",

            horaString: "",
            fechaString: "",

            titulo: "",
            subtitulo: "",
            texto: ""
        };

        this.getPagina();
    }

    async getPagina(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        // const idReferencia = urlPrivada + urlPublica;
        const urlPrivada = "autor_" + mUid + "_fecha_";
            
        // Se obtiene el Nombre de Usuario de la URL
        const urlPadre = this.props.match.url;            
        const urlPublica = urlPadre.replace("/editar-pagina/", "");

        // const idReferencia = urlPrivada + urlPublica;
       // console.log(idReferencia);

        const q = query(collection(db, "Paginas"), where("url", "==", urlPublica)).withConverter(Pagina.paginaConverter);
        const querySnapshot = await getDocs(q);

        // Se piden los datos
        querySnapshot.forEach((doc) => {
                
            // Se rellenan los datos
            const idReferencia = doc.id;
            objPagina = doc.data();
            const titulo = objPagina.titulo;
            document.title = titulo + " | Editar página";

            // Convertimos Timestamp a Date
            const dateEntrada = objPagina.date.toDate();

            // Separamos el date en Hora y Fecha
            const horaString = ("0" + dateEntrada.getHours()).slice(-2) + ":" + ("0" + dateEntrada.getMinutes()).slice(-2);
            const fechaString = (dateEntrada.getFullYear()) + "-" + ("0"+(dateEntrada.getMonth()+1)).slice(-2) + "-" + ("0" + dateEntrada.getDate()).slice(-2);

            // Se actualiza el estado
            this.setState(() => {
                return {
                    idReferencia: idReferencia,
                    urlPublica: urlPublica,

                    url: objPagina.url,

                    privacidad: objPagina.privacidad,
                    categoria: objPagina.categoria,

                    autor: mUid,
                    portada: objPagina.portada,

                    horaString: horaString,
                    fechaString: fechaString,

                    titulo: objPagina.titulo,
                    subtitulo: objPagina.subtitulo,
                    texto: objPagina.texto
                }
            });
                    
            // return Promise.resolve(doc); 
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                <div id="visor" theme={this.props.theme}>
                    <BadgeCrearPagina
                        theme={this.props.theme}

                        postNuevo = {false}
                        idReferencia = {this.state.idReferencia}
                        urlPublica = {this.state.urlPublica}

                        url = {this.state.url}

                        privacidad = {this.state.privacidad}
                        categoria = {this.state.categoria}
                        
                        autor = {this.state.autor}
                        portada = {this.state.portada}

                        horaString= {this.state.horaString}
                        fechaString= {this.state.fechaString}

                        titulo = {this.state.titulo}
                        subtitulo = {this.state.subtitulo}
                        texto = {this.state.texto}
                    />
                </div>

            </React.Fragment>


        );
    }
}

export default EditarPagina;