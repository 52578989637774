import React from 'react';

import './style-textarea.css';

import iconOpciones from '../../../imagenes/iconos/icon-opciones.svg';

import iconMoverArriba from '../../../imagenes/iconos/icon-mover-arriba.svg';
import iconMoverAbajo from '../../../imagenes/iconos/icon-mover-abajo.svg';
import iconCopiarTexto from '../../../imagenes/iconos/icon-copiar.svg';
import iconBorrarBloque from '../../../imagenes/iconos/icon-eliminar.svg';

import SubPagina from '../../Objetos/ObjetoSubPagina.js';
import Snackbar from '../Snackbar/index.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// ------------------------

const db = getFirestore();
var objSubPagina = new SubPagina('','','','','','','','','','','','','','','');

// ------------------------

function toggleClass(elem, className){
    if (elem.className.indexOf(className) !== -1){
      elem.className = elem.className.replace(className,'');
    }
    else{
      elem.className = elem.className.replace(/\s+/g,' ') + 	' ' + className;
    }
  
    return elem;
}
  
function toggleMenuDisplay(e){
    const dropdown = e.currentTarget.parentNode;
    const menu = dropdown.querySelector('.menu');
    // const icon = dropdown.querySelector('.dropdown-icon');
  
    toggleClass(menu,'hide');
    // toggleClass(icon,'rotate-90');
}
  
function handleOptionSelected(e){
    toggleClass(e.target.parentNode, 'hide');
    
    // const icon = document.querySelector('.dropdown-icon');
    // toggleClass(icon,'rotate-90');
}

// Cierra el menú si el usuario hace click fuera de este
/* window.onclick = function(event) {

    if (!event.target.matches('.dropdown-icon')) {
        
        toggleMenuDisplay(event);
    }
} */
  

class MenuTextAreaOpciones extends React.Component{

    constructor(props) {
        super(props);

        // Estado
        this.state = {
            textoMensaje: ""
        };

        this.showMenu = this.showMenu.bind(this);
        this.showValue = this.showValue.bind(this);
    }

    // EVENTOS ---------------

    /* Mostrar menú de opciones */
    showMenu = (e) => {
        e.preventDefault();
     
        toggleMenuDisplay(e);        
    }

    /* Valor seleccionado */
    showValue = (e) => {
        e.preventDefault();

        handleOptionSelected(e);

        const uidAutor = this.props.autor;
        const idElement = this.props.id;
        const urlContenido = this.props.urlContenido;
        const valorMenu = e.target.id;

        this.menuOpciones(uidAutor, idElement, urlContenido, valorMenu);
    }

    // META FUNCIONES ---------------

    /* Evento del Menú de Opciones (Dropdown) */
    menuOpciones(autor, numId, urlContenido, valor){

        if (valor === "MoveUp"){
            
            // Se mueve un lugar para arriba
            // this.moverArriba(numId);

        } else if (valor === "MoveDown"){
            
            // Se mueve un lugar para abajo
            // this.moverAbajo(numId);
            
        } else if (valor === "CopyText"){
            
            // Se copia el texto
            this.copiarTexto(numId);

        } else if (valor === "Delete"){
            
            // Se elimina
            this.borrarBloque(autor, numId, urlContenido);
        }
    }

    // -----------------------------

    copiarTexto(numId){

        // Se copia el texto al portapapeles
        const elementoTexto = document.getElementById('texto-'+ numId);
        const textoBloque = elementoTexto.value;
    
        navigator.clipboard.writeText(textoBloque)
        .then(() => {
            
            // Se muestra un mensaje
            this.setState(() => {
                return {
                    textoMensaje: "Texto copiado al portapapeles"
                }
            });
    
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    }
    borrarBloque(autor, numId, urlContenido){


        // Se borra el elemento        
        const elementoTexto = document.getElementById('texto-'+numId);
        const textoBloque = elementoTexto.value;

        // Se solicita confirmación
        const mensaje = `⚠ ¿Desea eliminar el párrafo No.${numId}?\n\n"${textoBloque}"`;
        const isOK = window.confirm(mensaje);

        if(isOK){

            // Removemos el elemento de la base de datos
            // this.borrarEnBaseDeDatos(autor, numId, urlContenido);
            this.borrarVisualmente(numId);

        }
    }
    
    // -----------
    
    borrarEnBaseDeDatos(autor, numId, urlContenido){
    
        // UID de la persona dueña de las Tareas
        // const uidPersona = this.props.autor;
    
        // UID de la persona que creó la Tarea
        // const uidDefault = this.props.uid;
    
        // Si existe una portada, se elimina
        if(urlContenido !== ""){
    
            // Separamos para mostrar una URL más amigable
            // const url = "MetaPaginas/" + autor + "/Blog/" + numId + "/urlContenido";
            const tipoPagina = "Blog";
            const urlPagina = "urlDeLaPaginaCreada";
            const url = "MetaPaginas/" + tipoPagina + "/" + urlPagina + "/" + numId;
    
            const storage = getStorage();
            const portadaRef = ref(storage, url);
    
            // Se elimina el contenido (imagen, archivo, audio, etc.)
            deleteObject(portadaRef)
            .then(() => {
    
                // Se elimina el apuntador
                const refEntrada = doc( db, `MetaPaginas/${autor}/Blog/${numId}`).withConverter(objSubPagina.subPaginaConverter);
                deleteDoc(refEntrada);
    
                // Se borra la view del DOM
                this.borrarVisualmente(numId);
    
            }).catch((error) => {
                // Uh-oh, an error occurred!
                alert(`Ocurrió un error:\n\n ${error}`);
            });
    
    
        } else{
    
            // Se elimina el apuntador
            const refEntrada = doc( db, `MetaPaginas/${autor}/Blog/${numId}`).withConverter(objSubPagina.subPaginaConverter);
            deleteDoc(refEntrada);
    
            // Se borra la view del DOM
            this.borrarVisualmente(numId);
        }
    }
    borrarVisualmente(numId){
    
        // Removemos el elemento del DOM
        const elementoPadre = document.getElementById('div-'+numId);
        elementoPadre.remove();
    
        // Se muestra un mensaje
        this.setState(() => {
            return {
                textoMensaje: "Bloque eliminado"
            }
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className='dropdown'>

                {/* Ícono de menú */}
                <img className="dropdown-icon" src={iconOpciones} alt='' title='Opciones' onClick={this.showMenu}/>


                {/* Menú de opciones */}
                <div id={'menuOpciones-'+this.props.id} className="menu pointerCursor hide" onClick={this.showValue}>
                    
                    <div className='option' id='MoveUp' title='Mover un bloque arriba.'><img src={iconMoverArriba} alt=""/>Mover arriba</div>
                    <div className='option' id='MoveDown' title='Mover un bloque abajo.'><img src={iconMoverAbajo} alt=""/>Mover abajo</div>
                    <div className='option' id='CopyText' title='Copiar texto al portapapeles.'><img src={iconCopiarTexto} alt=""/>Copiar texto</div>
                    <div className='option' id='Delete' title='Borrar bloque.'><img src={iconBorrarBloque} alt=""/>Borrar bloque</div>
                    
                </div>

                <Snackbar theme={this.props.theme} mensaje={this.state.textoMensaje}/>

            </div>

        );

    }

}

export default MenuTextAreaOpciones;