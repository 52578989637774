import React from 'react';
import ReactDOM from 'react-dom';

import '../global.css';

// Objetos personales
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import SubPagina from '../componentes/Objetos/ObjetoSubPagina.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";
import { collection, getDocs, query, where } from "firebase/firestore";

import NotFound from './NotFound.js';
import BadgeLeerPost from '../componentes/SesionIniciada/BadgeLeerPost.js';

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1515595967223-f9fa59af5a3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80";

// ------------------------

const db = getFirestore();

// Formato "día, fecha de mes de año"
// const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formatoFecha = { year: 'numeric', month: 'short', day: 'numeric' };
const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');
var objSubPagina = new SubPagina();


// ------------------------


class LeerPost extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {

            idReferencia: "",
            urlPublica: "",

            autor: "",
            portada: banner,

            horaString: "",
            fechaString: "",

            fullDateString: "📌 Cargando...",

            titulo: "",
            subtitulo: "",
            texto: "",

            categoria: "",

            mUsuario: mUsuario
        };

        // const user = auth.currentUser;
        // mUid = auth.currentUser.uid;
        // UID del dueño
        // mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        this.getPost();
        this.getUsuario();
        
    }

    async getPost(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        if (this.props.match.url !== "" || this.props.match.url !== null){

            // const idReferencia = urlPrivada + urlPublica;
            const urlPrivada = "autor_" + mUid + "_fecha_";
            
            // Se obtiene el Nombre de Usuario de la URL
            const urlPadre = this.props.match.url;            
            const urlPublica = urlPadre.replace("/post/", "");

            const idReferencia = urlPrivada + urlPublica;
           // console.log(idReferencia);

            // const ref = doc(db, "Posts/" + mUid + "/Estado", idReferencia).withConverter(SubPagina.subPaginaConverter);
            // const docSnap = getDoc(ref);

            // Se piden los datos
            // docSnap.then((snapshot) => {

            const ref = collection(db, "Posts/" + mUid + "/Estado");
            // Create a query against the collection.
            const q = query(ref, where("urlPublic", "==", urlPublica)).withConverter(SubPagina.subPaginaConverter);
            const querySnapshot = getDocs(q);

            // Se piden los datos
            querySnapshot.then((snapshot) => {

                snapshot.forEach((doc) => {

                    // Se rellenan los datos
                    objSubPagina = doc.data();

                    // Convertimos Timestamp a Date
                    const dateEntrada = objSubPagina.date.toDate();

                    /* NECESARIO para la tarjeta */
                    // "día, fecha de mes de año"
                    var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                    // "hora:minuto am/pm"
                    var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);

                    const fechaCompleta = horaString + " • " + fechaString;
                    // document.title = fechaCompleta + " | Post de @" + mUsuario.nombreUsuario;
                    document.title = objSubPagina.texto + " | Post de @" + mUsuario.nombreUsuario;

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            idReferencia: idReferencia,
                            urlPublica: urlPublica,

                            autor: mUid,
                            portada: objSubPagina.galeria,

                            horaString: horaString,
                            fechaString: fechaString,

                            fullDateString: fechaCompleta,

                            categoria: objSubPagina.categoria,
                            texto: objSubPagina.texto,

                            noComments: objSubPagina.noComments,
                            noReposts: objSubPagina.noReposts,
                            noLikes: objSubPagina.noLikes,
                            noShared: objSubPagina.noShared,
                            noSaved: objSubPagina.noSaved,
                            noViews: objSubPagina.noViews
                        }
                    });

                    if (objSubPagina.galeria === []){
                        // Se actualiza el estado
                        this.setState(() => {
                            return {
                                portada: banner,
                            }
                        });
                    }
                });
                    
                //No se devuelve un Objeto SubPagina.js
                if(snapshot.empty){

                    /* Reemplazamos el div "visor" */
                    const App = () => (
                        <NotFound/>
                    );
                    ReactDOM.render(<App />, document.getElementById('visor'));

                }

            });
        
        }
    }
    async getUsuario(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            // mUid = auth.currentUser.uid;
            // UID del dueño
            // const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();

                // const fechaCompleta = this.state.horaString + " • " + this.state.fechaString;
                // document.title = fechaCompleta + " | Post de @" + mUsuario.nombreUsuario;

                // Se actualiza el estado
                this.setState(() => {
                    return {
                        mUsuario: mUsuario
                    }
                });
                    
                return Promise.resolve(docSnap);
            });
        }
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        // document.title = "Entrada | Blog";
        return (


            <React.Fragment>

                <div id="visor" theme={this.props.theme}>

                    {/* Sección de la Entrada */}
                    <BadgeLeerPost
                        theme = {this.props.theme}

                        idReferencia = {this.state.idReferencia}

                        urlPublica = {this.state.urlPublica}
                        autor = {this.state.autor}
                        
                        horaString = {this.state.horaString}
                        fechaString = {this.state.fechaString}
                        fullDateString = {this.state.fullDateString}
                        privacidad = {""}

                        categoria = {this.state.categoria}
                        portada = {this.state.portada}
                        titulo = {""}
                        subtitulo = {""}
                        texto = {this.state.texto}

                        // Analítica
                        noComments = {this.state.noComments}
                        noReposts = {this.state.noReposts}
                        noLikes = {this.state.noLikes}
                        noShared = {this.state.noShared}
                        noSaved = {this.state.noSaved}
                        noViews = {this.state.noViews}

                        // Sobre el autor
                        rutaFotoPerfil = {this.state.mUsuario.rutaFotoPerfil}
                        nombrePublico = {this.state.mUsuario.nombrePublico}
                        nombreUsuario = {this.state.mUsuario.nombreUsuario}
                        verificado = {this.state.mUsuario.verificado}
                    />


                </div>

            </React.Fragment>


        );
    }
}

export default LeerPost;