import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';

import '../Main/estilos/Navbar.css';

import logoPrincipal from '../../imagenes/iconos/icon-logo-apeordi.png';

import iconMenu from '../../imagenes/iconos/icon-menu.svg';
import iconMenuPaginas from '../../imagenes/iconos/icon-opciones.svg';
// import iconCrear from '../../imagenes/iconos/icon-crear.svg';
import iconImagenUsuario from '../../imagenes/iconos/icon_iniciar_sesion_256px.png';

// Objetos personales
import Usuario from '../SesionIniciada/ObjetoUsuario.js';
// import Sidebar from './SidebarAdministrador.js';
import SidebarCrear from './SidebarCrear.js';
import SidebarCuenta from './SidebarCuenta.js';
import SidebarAdministrador from './SidebarAdministrador.js';


// ------------------------

// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;
var rutaFotoPerfil;

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

const getUsuario = () => {

    /* Datos iniciales */
    mUid = auth.currentUser.uid;
    rutaFotoPerfil = "";
    
    // console.log(this.props.nombrePublico);

    // Si mUsuario NO TIENE datos (se solicitan a la BD)
    if (mUsuario.nombrePublico === ""){

        mUid = auth.currentUser.uid;
        const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
        const docSnap =  getDoc(ref);

        // Se piden los datos
        docSnap.then((snapshot) => {
            
            // Se rellenan los datos
            mUsuario = snapshot.data();
            rutaFotoPerfil = mUsuario.rutaFotoPerfil;
            // console.log("Ruta de imagen: \n\n" + rutaFotoPerfil);

            if (rutaFotoPerfil === ""){

                rutaFotoPerfil = iconImagenUsuario;            
            }
                
            return Promise.resolve(docSnap);
        });

    }  
  
    
    // return rutaFotoPerfil;
};

const Navbar = ({ setIsOpen, setIsOpenMIzq, setIsOpenMDer, theme }) => (

    <nav className="Navbar" theme={theme}>

        {/*
        <div className="lista-iconos">
            <button onClick={() => setIsOpenMIzq(true)} title="Menú" className="navbar-icon"><img src={iconMenu} alt="Botón" width="20" height="20"/></button>
        </div> */}

        {/* Nombre de la página */}
        <Link className="Navbar__brand" to="/" title='Ir al inicio'>
            <img className="Navbar__brand-logo" src={logoPrincipal} alt="Logo"/>
            <Link className="Navbar__fuente" to="/" title='Ir al inicio'>Apeordi</Link>
        </Link>

        <div className="lista-iconos">
            <button onClick={() => setIsOpenMIzq(true)} title="Administrador de colecciones" className="navbar-icon"><img src={iconMenuPaginas} alt="Botón" width="20" height="20"/></button>
            {/* <button onClick={() => setIsOpenMDer(true)} title="Crear" className="navbar-icon"><img src={iconCrear} alt="Botón" width="26" height="26"/></button> */}
            <button onClick={() => setIsOpen(true)} title="Menú" className="navbar-icon"><img src={iconMenu} alt="Botón" width="20" height="20"/></button>
            {/* <div onClick={() => setIsOpen(true)} title="Cuenta" className="navbar-icon-imagen"><img src={mUsuario.rutaFotoPerfil} alt=""/></div> */}
        </div>
    </nav>
);

const ModalIzq = ({ isOpenMIzq, setIsOpenMIzq, theme }) => (
    
    <div className={`modal-izquierdo ${isOpenMIzq ? "open" : ""}`} onClick={() => setIsOpenMIzq(false)} theme={theme}>
        <SidebarAdministrador/>
    </div>
);
const OverlayIzq = ({ isOpenMIzq, setIsOpenMIzq }) => (
    <div className={`overlay ${isOpenMIzq ? "open" : ""}`} onClick={() => setIsOpenMIzq(false)}/>
);

const ModalDer  = ({ isOpenMDer, setIsOpenMDer, theme }) => (
    
  <div className={`modal-derecho ${isOpenMDer ? "open" : ""}`} onClick={() => setIsOpenMDer(false)} theme={theme}>
        <SidebarCrear />    
  </div>
);
const OverlayDer = ({ isOpenMDer, setIsOpenMDer }) => (
    <div className={`overlay ${isOpenMDer ? "open" : ""}`} onClick={() => setIsOpenMDer(false)}/>
);

const ModalCuenta = ({ isOpen, setIsOpen, theme }) => (
    
    <div className={`modal-derecho ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)} theme={theme}>
  
          <SidebarCuenta />
      
    </div>
);
const Overlay = ({ isOpen, setIsOpen }) => (
    <div className={`overlay ${isOpen ? "open" : ""}`} onClick={() => setIsOpen(false)}/>
  );

function NavbarAdministrador(props) {

    getUsuario();

    const [isOpenMIzq, setIsOpenMIzq] = useState(false);
    const [isOpenMDer, setIsOpenMDer] = useState(false);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div>

            {/*             
            <ModalCuenta isOpen={isOpen} setIsOpen={setIsOpen}/>
            */}

            <Navbar setIsOpen={setIsOpen} setIsOpenMIzq={setIsOpenMIzq} setIsOpenMDer={setIsOpenMDer} theme={props.theme}/>

            <OverlayIzq isOpenMIzq={isOpenMIzq} setIsOpenMIzq={setIsOpenMIzq}/>
            <ModalIzq isOpenMIzq={isOpenMIzq} setIsOpenMIzq={setIsOpenMIzq} theme={props.theme}/>
            
            <OverlayDer isOpenMDer={isOpenMDer} setIsOpenMDer={setIsOpenMDer}/>
            <ModalDer isOpenMDer={isOpenMDer} setIsOpenMDer={setIsOpenMDer} theme={props.theme}/>

            <Overlay isOpen={isOpen} setIsOpen={setIsOpen}/>
            <ModalCuenta isOpen={isOpen} setIsOpen={setIsOpen} theme={props.theme}/>
        </div>
    );
  
};

export default NavbarAdministrador;