import React, { useState } from 'react';

import "./estilos/BadgeContacto.css";

function ContactForm() {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();

    // console.log({ name, email, message });

    // Se ejecuta
    document.location.href = "mailto:contacto@apeordi.com?subject="
    + encodeURIComponent(subject)
    + "&body=" + encodeURIComponent(message + "\n\n\n-- Enviado desde " + email + " por " + name);

  };

  return (
    <form className='BadgeContacto-form' onSubmit={handleSubmit}>
        <h2 className='BadgeContacto-h2'> Envíe un email </h2>
        <label className='BadgeContacto-label'>
            Nombre:
            <input
                className='BadgeContacto-input'
                type="text"
                pattern="[A-Za-z]{3,50}" required
                value={name}
                onChange={(event) => setName(event.target.value)}
            />
        </label>
        <label className='BadgeContacto-label'>
            Email:
            <input
                className='BadgeContacto-input'
                type="email"
                pattern="[A-Za-z0-9._@]{4,40}" required
                value={email}
                onChange={(event) => setEmail(event.target.value)}
            />
        </label>
        <label className='BadgeContacto-label'>
            Asunto:
            <input
                className='BadgeContacto-input'
                type="text"
                value={subject}
                onChange={(event) => setSubject(event.target.value)}
            />
        </label>
        <br />
        <label className='BadgeContacto-label'>
            Mensaje:
            <textarea
                className='BadgeContacto-textarea'
                rows={5}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
            />
        </label>
        <br />
        <button className='BadgeContacto-button' type="submit">Enviar</button>
    </form>
  );
}

export default ContactForm;