import React from 'react';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import LayoutDeUsuario from './LayoutDeUsuario.js';
import ScrollToTop from '../Main/ScrollToTop.js';

//Importamos nuestros componentes personalizados
// import Inicio from '../../paginas/Inicio';
import Perfil from '../../paginas/Perfil.js';
import EditarPerfil from '../../paginas/EditarPerfil.js';
import Comentarios from '../../paginas/Comentarios.js';
import Contactos from '../../paginas/Contactos.js';

import BuscarCuenta from '../../paginas/BuscarCuenta.js';
import PerfilExterno from '../../paginas/PerfilExterno.js';

// import AcercaDe from '../../paginas/principales/AcercaDe.js';
import Privacidad from '../../paginas/principales/Privacidad.js';
import FortinTech from '../../paginas/principales/FortinTech.js';
import Adolfo from '../../paginas/principales/Adolfo.js';
import Armando from '../../paginas/principales/Armando.js';
import NotFound from '../../paginas/NotFound.js';

// El HOME será el Perfil
// import Home from '../../paginas/Home';


function App() {

    return (
        
        <BrowserRouter>
        
            <LayoutDeUsuario>
            
                <ScrollToTop />
                <Switch>
                
                    <Route exact path="/" component={Perfil}/>
                    <Route exact path="/inicio" component={Perfil}/>
                    <Route exact path="/iniciar-sesion" component={Perfil}/>
                    <Route exact path="/crear-cuenta" component={Perfil}/>
                    <Route exact path="/buscar" component={BuscarCuenta}/>
                    
                    <Route exact path="/editar-perfil" component={EditarPerfil}/>
                    <Route exact path="/comentarios" component={Comentarios}/>
                    <Route exact path="/contactos" component={Contactos}/>

                    {/* <Route exact path="/acerca-de" component={AcercaDe}/> */}
                    <Route exact path="/privacidad" component={Privacidad}/>

                    <Route exact path="/fortin-tech" component={FortinTech}/>
                    <Route exact path="/fortín-tech" component={FortinTech}/>
                    <Route exact path="/adolfo" component={Adolfo}/>
                    <Route exact path="/armando" component={Armando}/>

                    <Route path={"/:nombreUsuario"} render={ props => <PerfilExterno {...props} /> } />
                    <Route component={NotFound}/>

                </Switch>

            </LayoutDeUsuario>
        
        </BrowserRouter>
        
    );

    
}


export default App;