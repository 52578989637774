import React from 'react';

// Importamos los componentes a Firebase
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";

// Objetos personales
import Usuario from '../SesionIniciada/ObjetoUsuario.js';
import BadgeCuentaEncontrada from '../SesionIniciada/BadgeCuentaEncontrada.js';

import "../../global.css";
import "../estilos/Badge.css";

import imagenPrincipal from '../../imagenes/iconos/icon_iniciar_sesion_256px.png'

const db = getFirestore();

var uidUsuario = "";

// ------------------------

// Declaro una variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');
// var mUsuario = new Usuario();

// ------------------------

class BadgeBuscarCuenta extends React.Component{

    /* handleResetPassword(auth, actionCode, continueUrl, lang) {
        // Localize the UI to the selected language as determined by the lang
        // parameter.
      
        // Verify the password reset code is valid.
        verifyPasswordResetCode(auth, actionCode).then((email) => {
          const accountEmail = email;
      
          // Muestra la pantalla de reinicio con el correo electrónico del usuario 
          // y solicita al usuario la nueva contraseña.
          const newPassword = "...";
      
          // Guarda la nueva password.
          confirmPasswordReset(auth, actionCode, newPassword).then((resp) => {
            // Se ha confirmado el restablecimiento de la contraseña y se ha actualizado la nueva contraseña.
      
            // Muestre un enlace a la aplicación o inicie sesión directamente con el usuario 
            // si la página pertenece al mismo dominio que la aplicación:
            // auth.signInWithEmailAndPassword(accountEmail, newPassword);
      
            // Si hay una URL de continuación disponible, muestre un botón que, al hacer clic, 
            // redirige al usuario a la aplicación a través de continueUrl con un 
            // estado adicional determinado a partir de los parámetros de esa URL.
    
          }).catch((error) => {
            // Ocurrió un error durante la confirmación. 
            // Es posible que el código haya caducado o que la contraseña sea demasiado débil.
          });
        }).catch((error) => {
          // Código de acción no válido o caducado. 
          // Pida al usuario que intente restablecer la contraseña nuevamente.
        });
    } */

    constructor(props) {
        super(props);

        // Si ya hay datos
        if (mUsuario.nombrePublico!==null && mUsuario.nombrePublico!==""){

            // Se muestran
            this.state = {

                verificado: mUsuario.verificado,
                nombrePublico: mUsuario.nombrePublico,
                nombreUsuario: mUsuario.nombreUsuario,
                
                biografia: mUsuario.biografia,
                rutaFotoPerfil: mUsuario.rutaFotoPerfil,
        
                redFacebook:mUsuario.redFacebook,
                redInstagram:mUsuario.redInstagram,
                redLinkedin:mUsuario.redLinkedin,
                redTwitter:mUsuario.redTwitter
            };

        } else { // Si no

            // Se muestran datos provisionales
            this.state = {

                verificado: false,
                nombrePublico: "",
                nombreUsuario: "usuario",
                
                biografia: "",
                rutaFotoPerfil: imagenPrincipal,
        
                redFacebook:mUsuario.redFacebook,
                redInstagram:mUsuario.redInstagram,
                redLinkedin:mUsuario.redLinkedin,
                redTwitter:mUsuario.redTwitter
            };
        }
        
        this.handleSubmit = this.handleSubmit.bind(this);
    }

  
    // Botón para Buscar Cuenta
    handleSubmit = (e) => {

        e.preventDefault();
    
        const userName = e.target.elements.userName.value;
    
        if(userName!==null && userName!==""){

            const referenciaUsuarios = collection(db, "Usuarios");

            // Create a query against the collection.
            const q = query(referenciaUsuarios, where("nombreUsuario", "==", userName)).withConverter(mUsuario.usuarioConverter);
            const querySnapshot = getDocs(q);

            querySnapshot
            .then((snapshot) => {

                snapshot.forEach((doc) => {

                    // doc.data() is never undefined for query doc snapshots
                    
                    // console.log(doc.id, " => ", doc.data());
                    uidUsuario = doc.id;
                    mUsuario = doc.data();

                    // Se rellenan los datos
                    this.setState(() => {                                    
                        return {
                            verificado: mUsuario.verificado,
                            nombrePublico: mUsuario.nombrePublico,
                            nombreUsuario: mUsuario.nombreUsuario,
                                    
                            biografia: mUsuario.biografia,

                            redFacebook: mUsuario.redFacebook,
                            redInstagram: mUsuario.redInstagram,
                            redLinkedin: mUsuario.redLinkedin,
                            redTwitter: mUsuario.redTwitter
                        }
                    });

                    // Si mUsuario ya tiene foto de perfil
                    if (mUsuario.rutaFotoPerfil !== ""){

                        this.setState(() => {                                
                            return {
                                rutaFotoPerfil: mUsuario.rutaFotoPerfil
                            }
                        });
            
                    } else { // Si mUsuario aún no tiene foto de perfil

                        this.setState(() => {                                
                            return {
                                // se muestra una provisional
                                rutaFotoPerfil: imagenPrincipal 
                            }
                        });
                        
                    }

                        
    
                });

                if(snapshot.empty){

                    //No se devuelve un Objeto Usuario
                    uidUsuario = "";
                    this.setState(() => {
                                
                        return {

                            verificado: false,
                            nombrePublico: "",
                            nombreUsuario: userName,
                                        
                            biografia: `Al parecer aún no existe el usuario: @${userName}`,
                            // se muestra una provisional
                            rutaFotoPerfil: imagenPrincipal,

                            redFacebook: "",
                            redInstagram: "",
                            redLinkedin: "",
                            redTwitter: ""
                        }
                    });

                }

            })
            .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
            });


        } else{

            const mensaje = `Escriba un nombre de usuario para buscarlo.`;
            alert(mensaje);

        }

            
    }


    // Lo que se visualiza en pantalla
    render(){
        //this.props;

        return(

            <div className='header-pantalla-completa'>

                {/* Sección */}
                <div className='header-imagen-media-pantalla'>

                    <br/>
                    <form onSubmit={this.handleSubmit} className="Badge__formulario">


                        <div className="formulario">
                            <label>Nombre de usuario</label>
                            <input className="form-control" type="text" id="userName"
                            name="userName" placeholder="Sin el @"/>
                        </div>

                        <button className="btn btn-login">Buscar</button>

                    </form>

                </div>

                {/* Sección */}
                <div className='header-media-pantalla'>
                    <BadgeCuentaEncontrada
                        uidUsuario={uidUsuario}
                        
                        verificado={this.state.verificado}
                        nombrePublico={this.state.nombrePublico}
                        nombreUsuario={this.state.nombreUsuario}

                        rutaFotoPerfil={this.state.rutaFotoPerfil}
                        biografia={this.state.biografia}
                        
                        redFacebook={this.state.redFacebook}
                        redInstagram={this.state.redInstagram}
                        redLinkedin={this.state.redLinkedin}
                        redTwitter={this.state.redTwitter}
                        />
                </div>


                
            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeBuscarCuenta;