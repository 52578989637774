import React from 'react';

import '../global.css';

// Objetos personales
import BadgeCrearMencion from '../componentes/SesionIniciada/BadgeCrearMencion.js';

// Imagen de portada provisional
// const banner = "https://images.unsplash.com/photo-1504711434969-e33886168f5c?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";

// UID del dueño
// const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";


// ------------------------


class CrearMencion extends React.Component{



    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (
            <React.Fragment>

                <div id="visor" theme={this.props.theme}>
                    <BadgeCrearMencion
                        theme={this.props.theme}

                        postNuevo = {true}                        
                        
                        urlPublica = {""}

                        importancia = {""}
                        date = {""}

                        logotipo = {""}
                        autor = {""}
                        descripcionAutor = {""}
                        cita = {""}
                        urlCita = {""}
                    />
                </div>

            </React.Fragment>


        );
    }
}

export default CrearMencion;