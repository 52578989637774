import React from 'react';

import { NavbarBottom as NavbarDeUsuario } from './NavbarDeUsuario.js';
import Footer from '../Main/Footer.js';
import "../../global.css";

function Layout (props) {

    

    
    return (
        
        <React.Fragment>


            
            {props.children}
            <NavbarDeUsuario />
            <Footer />

            

        </React.Fragment>
    );
}

export default Layout;