import React from 'react';

import SEO from '../../componentes/Main/SEO.js';
import DataSeo from '../../constantes/DataSeo.js';

import '../../global.css';

const banner = DataSeo.ApuntesImage;
const imagenMisApuntes = "https://images.unsplash.com/photo-1605462979380-48f1ea3b3ff1?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
// const imagen = "";

class Portafolio extends React.Component{

    render() {
        return (
            <React.Fragment>

                {/* SEO */}
                <SEO
                    title={DataSeo.ApuntesTitle}
                    description={DataSeo.ApuntesDescription}
                    image={banner}
                    url={DataSeo.ApuntesLink}
                    name={"@apeordi"}
                    type={"article"}
                />

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">                        
                        <h1>Apuntes</h1>
                        <h2>Accede a mis apuntes, notas y resúmenes de clases de la universidad</h2>                        
                    </div>
                </div>


                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenMisApuntes} alt="Imagen de Unsplash"/>

                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué apuntes y resúmenes tengo?</h3>
                        <span>Mira mis principales notas, tareas y series que documento:</span><br/>
                        <br/>
                        <h4><b>Hidráulica Básica</b></h4>
                        <span>Según el temario de la UNAM del año 2016.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://hb.apeordi.com">Ver apuntes</a><br/>
                        <br/>
                        <br/>
                        <span>También puedes donar para seguir compartiendo mis notas <a target="_blank" rel="noopener noreferrer" href="https://paypal.me/adolfoordinola?country.x=MX&locale.x=es_XC">PayPal ♥️</a>.</span><br/>
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Portafolio;