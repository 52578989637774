import React from 'react';
import { Link } from 'react-router-dom';

import '../../global.css';

import banner from '../../imagenes/portadas/OpenGraph Armando Ordinola.png';
import iconTwitter from '../../imagenes/iconos/icon-twitter.svg';
import iconFacebook from '../../imagenes/iconos/icon-facebook.svg';

import imagenArmando from '../../imagenes/Armando Ordinola.png';
const imagenMisProyectos = "https://images.unsplash.com/photo-1579389083078-4e7018379f7e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
// const imagen = "";

class Armando extends React.Component{

    render() {
        return (


            <React.Fragment>

                {/* Banner */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada"/>

                    <div className="header-contenido-portada">
                        <h1>Armando Ordinola</h1>
                        <h2>About me</h2>
                    </div>
                </div>

                {/* Portada */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenArmando} alt="Imagen de Adolfo Ordinola" style={{ aspectRatio:'1', height: '100%', width: '100%', padding:'25%', borderRadius:'350px' }}/>
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Quién soy?</h3>
                        <br/>
                        <span className="span-texto-normal">Médico egresado de la Facultad de Medicina de la UNAM.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Soy el actual CEO de <Link to="/fortin-tech">Fortín Tech</Link>, junto a mi hermano, <Link to="/adolfo">Adolfo</Link>.</span><br/>
                        <br/>
                        --
                        <br/>
                        <br/>
                        <span className="span-texto-normal">En 2017 creé mi primera app, <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.fortinlabs.apamed">APAMED</a>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">En 2019 vendí mi primer servicio como asesor de proyectos al H. Ayuntamiento de Tila, Chiapas.</span><br/>
                        <br/>
                        <img src={iconTwitter} alt="Icono de Twitter" style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/user?screen_name=Arpeor">@Arpeor</a>
                        <img src={iconFacebook} alt="Icono de Facebook" style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/DrOrdinola">@DrOrdinola</a>
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué otras cosas he creado?</h3>
                        <span className="span-texto-normal">Tengo un par de proyectos interesantes.</span><br/>
                        <br/>
                        <h4><b>• APAMED</b></h4>
                        <span className="span-texto-normal">Plataforma de capacitación para médicos, enfermeros y odontólogos gratis. Presente en más de 50 países.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://apamedoficial.com/acerca-de">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• Control de Obras</b></h4>
                        <span className="span-texto-normal">Bitácora inteligente para ingenieros civiles y arquitectos. Basada en la nube. Ideal para equipos de trabajo.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://adolfoordinola.com/proyectos/control-flows">+ Leer más</a><br/>
                        <br/>
                        <span className="span-texto-normal">También puedes visitar mi <a target="_blank" rel="noopener noreferrer" href="https://apamedoficial.com/dr-armando-ordinola">página profesional</a>.</span><br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenMisProyectos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Armando;