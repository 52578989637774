import React from 'react';

// import Navbar from './Navbar.js';
import { App as Navbar } from './Navbar.js';
import Footer from './Footer.js';
import BotonScrollTop from './BotonScrollTop.js';

import "../../global.css";

function Layout (props) {


    return (
        
        <React.Fragment>

            <Navbar theme={props.theme} />
            {props.children}
            <BotonScrollTop theme={props.theme} />
            <Footer theme={props.theme} />

        </React.Fragment>
    );
}

export default Layout;