import React from 'react';
import { Link } from 'react-router-dom';

import '../Administracion/estilos/Sidebar.css';

// ------------------------

import iconPortafolio from '../../imagenes/iconos/icon-portafolio.svg';
import iconBlog from '../../imagenes/iconos/icon-blog.svg';
import iconSobreMi from '../../imagenes/iconos/icon-info.svg';
import iconContacto from '../../imagenes/iconos/icon-contacto.svg';

import iconIniciarSesion from '../../imagenes/iconos/icon-iniciar-sesion.svg';

// ------------------------

class SidebarMenu extends React.Component {


    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className="Sidebar">

                <div className="Sidebar-menu" id='menu-completo'>

                    <ul className="Sidebar-list">

                        <p className="Sidebar__fuente">Menú</p>
                        <Link className="Sidebar-link" to="/portafolio"><img className="Sidebar-icon" src={iconPortafolio} alt="Botón"/>Portafolio</Link>
                        <Link className="Sidebar-link" to="/blog"><img className="Sidebar-icon" src={iconBlog} alt="Botón"/>Blog</Link>
                        <Link className="Sidebar-link" to="/sobre-mi"><img className="Sidebar-icon" src={iconSobreMi} alt="Botón"/>Sobre mí</Link>
                        <Link className="Sidebar-link" to="/contacto"><img className="Sidebar-icon" src={iconContacto} alt="Botón"/>Contacto</Link>

                        <Link className="Sidebar-link-bottom" to="/entrar"><img className="Sidebar-icon-bottom" src={iconIniciarSesion} alt="Botón"/>Entrar</Link>

                    </ul>
                    
                </div>

            </div>
        );
    }
}


export default SidebarMenu;