import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgePost.css";

// Objetos personales

// Iconos importados
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png';

import iconComentar from '../../imagenes/iconos/icon-comentar.svg';
import iconRepostear from '../../imagenes/iconos/icon-repostear.svg';
import iconNoMeGusta from '../../imagenes/iconos/icon-no-me-gusta.svg';
import iconMeGusta from '../../imagenes/iconos/icon-me-gusta.svg';
import iconCompartir from '../../imagenes/iconos/icon-compartir.svg';
import iconGuardar from '../../imagenes/iconos/icon-compartir.svg';

// Menú de opciones del Post
import MenuOpciones from '../Contenido/Dropdown/menuPost.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, increment, updateDoc } from "firebase/firestore";

// ------------------------

const db = getFirestore();

// ------------------------

class BadgePost extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // Estado
        this.state = {
            // estiloTextoEntrada: { color: '#536471' }
            // estiloTextoEntrada: { color: '#536471' },

            iconComentar: iconComentar,
            estiloComentar: { opacity: 0.50 },
            numeroComentarios: this.props.noComments,

            iconRepostear: iconRepostear,
            estiloRepostear: { opacity: 0.50 },
            numeroRepost: this.props.noReposts,

            iconLike: iconNoMeGusta,
            estiloLike: { opacity: 0.50 },
            numeroLikes: this.props.noLikes,

            iconCompartir: iconCompartir,
            estiloCompartir: { opacity: 0.50 },
            numeroCompartidas: this.props.noShared,

            iconGuardar: iconGuardar,
            estiloGuardar: { opacity: 0.50 },
            numeroGuardados: this.props.noSaved,

            textoMensaje: ""
        };
        
        // ----------------------------

        // this.handleEliminarPost = this.handleEliminarPost.bind(this);
        this.handleMeGusta = this.handleMeGusta.bind(this);
        this.handleCompartir = this.handleCompartir.bind(this);
    }

    /* Eventos */

    handleMeGusta = (e) => {
        e.preventDefault();

        // UID de la persona dueña de las Tareas
        const uidPersona = this.props.autor;

        // Obtenemos la referencia
        const idReferencia = this.props.idReferencia;

        // Se actualiza la entrada
        const refEntrada = doc( db, `Posts/${uidPersona}/Estado/${idReferencia}`);
        updateDoc(refEntrada, {noLikes: increment(1)});


        // Se cambia el estilo
        this.setState(() => {
            return {
                iconLike: iconMeGusta,
                estiloLike: { opacity: 1, color: '#f91880' },
                numeroLikes: this.state.numeroLikes + 1
            }
        });

    }

    handleCompartir = (e) => {
        e.preventDefault();

        const urlEntrada = `https://apeordi.com/post/${this.props.urlPublica}`;

        // Copiar al portapapeles
        /* navigator.clipboard.writeText(urlEntrada)
        .then(() => {
            
            // console.log("Text copied to clipboard...");
            alert("¡Enlace copiado al portapapeles!");
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        }); */


        const datos = {
            title: 'Apeordi',
            text: ` - Mira mi último post en ${urlEntrada}`,
            // text: ` | Post de Adolfo Ordinola`,
            // files,
        }
      
        // Si el navegador lo soporta y el dispositivo lo soporta
        if (navigator.canShare && navigator.canShare(datos)) {
      
            try {

                // UID de la persona dueña de las Tareas
                const uidPersona = this.props.autor;

                // Obtenemos la referencia
                const idReferencia = this.props.idReferencia;

                // Se actualiza el contador de la entrada (no. compartidas)
                const refEntrada = doc( db, `Posts/${uidPersona}/Estado/${idReferencia}`);
                updateDoc(refEntrada, {noShared: increment(1)});

                // Se comparte el enlace
                navigator.share(datos);

                // Se cambia el estilo
                this.setState(() => {
                    return {
                        iconCompartir: iconCompartir,
                        estiloCompartir: { opacity: 1, color: '#56a8ff' },
                        numeroCompartidas: this.state.numeroCompartidas + 1
                    }
                });
      
            } catch (err) {
      
              if (err.name !== 'AbortError') {
                console.error(err.name, err.message)
              }
      
            }
      
        } else { // El navegador no lo soporta o el dispositivo lo no soporta
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', datos);
        }

    }

    /* Lo que mostramos en pantalla */
    render(){    

        return(

            <div className="BadgePost" theme={this.props.theme} id={'post-' + this.props.idReferencia} title={this.props.titulo}>

                {/* Header */}
                <div className="BadgePost__header">
                    {/* Autor */}
                    <div className="BadgePost__autor">
                        <Link className="BadgePost__autor-imagen" to="/perfil" title={this.props.nombreUsuario}><div className="BadgePost__autor-imagen"><img src={this.props.rutaFotoPerfil} alt=""/></div></Link>
                        <div>
                            <Link className="BadgePost__autor-nombre-publico" to="/perfil" title={this.props.nombreUsuario}>{this.props.nombrePublico}</Link>
                            <img className="BadgePost__autor-verificado" src={iconCuentaVerificada} hidden={!this.props.verificado} alt="Estado de cuenta verificada"/>
                            <Link className="BadgePost__autor-nombre-usuario" to="/perfil" title={this.props.nombreUsuario}>@{this.props.nombreUsuario}</Link>
                            {/* Fecha */}
                            <div className="BadgePost__fecha">
                                {this.props.fechaString}
                            </div>
                        </div>
                    </div>
                    {/* Elemento Menú Opciones del Post id='mop-1' */}
                    <MenuOpciones hidden={this.props.public} id={this.props.idReferencia} autor={this.props.autor} portada={this.props.portada} />
                </div>

                <Link  to={"/post/"+this.props.urlPublica}>

                    {/* Etiquetas */}
                    <div>

                        {/* Categoría */}
                        <div hidden={!this.props.categoria} className="BadgePost__section-categoria" id={this.props.idReferencia + "_div"} title="Categoría">
                            {this.props.categoria}
                        </div>

                    </div>

                    {/* Texto */}
                    <p className="BadgePost__section-texto" theme={this.props.theme} id={'texto-' + this.props.idReferencia} /* style={this.state.estiloTextoEntrada} */ >{this.props.texto}</p>

                    {/* Imagen de portada */}
                    <img hidden={!this.props.portada} className="BadgePost__section-imagen-portada" src={this.props.portada} alt="" title={this.props.titulo}/>

                </Link>

                {/* Footer */}
                <div className="BadgePost__footer">
                    <span className="BadgePost__footer-numero" style={this.state.estiloComentar}><button className="BadgePost__footer-icon" title="Comentar"><img src={iconComentar} alt="" width="22" height="22"/></button>{this.state.numeroComentarios}</span>
                    <span className="BadgePost__footer-numero" style={this.state.estiloRepostear}><button className="BadgePost__footer-icon" title="Compartir en tu perfil"><img src={iconRepostear} alt="" width="22" height="22"/></button>{this.state.numeroRepost}</span>
                    <span className="BadgePost__footer-numero" style={this.state.estiloLike}><button onClick={this.handleMeGusta} className="BadgePost__footer-icon" title="Me gusta"><img src={this.state.iconLike} alt="" width="22" height="22"/></button>{this.state.numeroLikes}</span>
                    <span className="BadgePost__footer-numero" style={this.state.estiloCompartir}><button onClick={this.handleCompartir} className="BadgePost__footer-icon" title="Compartir enlace"><img src={iconCompartir} alt="" width="22" height="22"/></button>{this.state.numeroCompartidas}</span>
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgePost;