import React from 'react';
import { Link } from 'react-router-dom';

import BadgeCrearCuenta from '../../componentes/Main/BadgeCrearCuenta.js';

const banner = "https://images.unsplash.com/photo-1599008633840-052c7f756385?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";

class CrearCuenta extends React.Component {

    state = { form: {

        nombrePublico: '',
        nombreUsuario: '',
        telefono: '',
        contraseña: '',
    }};

    handleChange = e =>{

        /*1er Forma de conservar la persistencia
        const nextForm = this.state.form;
        nextForm[e.target.name] = e.target.value;*/

        //1er Forma de conservar la persistencia
        //this.setState(form: nextForm,);

        //2da Forma de conservar la persistencia

        //Dejo caer todos los valores del form anteriormente
        //Y sobreescribimos el que ya estaba guardado

        this.setState({  
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            
        });
    };

    render(){
        document.title = this.props.title;
        return(

            <React.Fragment>
                
                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Crear cuenta</h1>
                        <h2>Accede a todos los recursos</h2>
                    </div>
                </div>

                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className='header-media-pantalla'>
                        <span className='span-centrado'>Crear cuenta</span>
                        <span>El <b>Nombre Público</b> es con el que aparecerás. Puedes usar tu nombre real (de preferencia), uno corto u otro diferente. Siempre puedes cambiarlo más adelante.</span><br/>
                        <br/>
                        <span>Regístrate de preferencia con un <b>Nombre de Usuario</b> corto (para que sea fácil de recordar y de encontrar por tus seguidores).</span><br/>
                        <br/>
                        <span>Tus datos están protegidos con servidores de Google todo el tiempo.</span><br/>
                        <br/>
                        <span>Al crear una cuenta aceptas los <Link to="/privacidad">términos y condiciones</Link> de privacidad.</span><br/>
                    </div>

                    
                    <div className="header-media-pantalla">
                        
                        <BadgeCrearCuenta
                            nombrePublico={this.state.form.nombrePublico}
                            nombreUsuario={this.state.form.nombreUsuario}
                            telefono={this.state.form.telefono}
                                    
                            correo={this.state.form.correo}
                            contraseña={this.state.form.contraseña}/>
                        <br/>        
                                    
                    </div>
                        
                        
                </div>
            </React.Fragment>
        );
    }
}


export default CrearCuenta;