import React from 'react';

import SEO from '../../componentes/Main/SEO.js';
import DataSeo from '../../constantes/DataSeo.js';

import '../../global.css';

const banner = DataSeo.PortafolioImage;
const imagenMisProyectos = "https://images.unsplash.com/photo-1513530534585-c7b1394c6d51?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1771&q=80";
// const imagen = "";

class Portafolio extends React.Component{

    render() {
        return (
            <React.Fragment>

                {/* SEO */}
                <SEO
                    title={DataSeo.PortafolioTitle}
                    description={DataSeo.PortafolioDescription}
                    image={banner}
                    url={DataSeo.PortafolioLink}
                    name={"@apeordi"}
                    type={"article"}
                />

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">                        
                        <h1>Portafolio</h1>
                        <h2>Proyectos profesionales</h2>                        
                    </div>
                </div>


                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenMisProyectos} alt="Imagen de Unsplash"/>

                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué cosas he creado?</h3>
                        <span>Mi principales proyectos profesionales son:</span><br/>
                        <br/>
                        <h4><b>• APAMED</b></h4>
                        <span>Plataforma de capacitación para médicos, enfermeros y odontólogos gratis. Presente en más de 50 países.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://apamedoficial.com/acerca-de">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• Control de Obras</b></h4>
                        <span>Bitácora inteligente para ingenieros civiles y arquitectos. Basada en la nube. Ideal para equipos de trabajo.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://apeordi.com/proyectos/control-flows">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• ¿Qué red social se cayó?</b></h4>
                        <span>Bot en Twitter que te avisa cuando una red social, plataforma o app se cae. Útil para no estresarte con tu módem.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/user?screen_name=QuienSeCayo">+ Leer más</a><br/>
                        <br/>
                        <span>También puedes visitar mi <a target="_blank" rel="noopener noreferrer" href="https://apeordi.com/blog">blog personal</a>.</span><br/>
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Portafolio;