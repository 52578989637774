import React from 'react';

import "../../global.css";
import "./estilos/BadgeMencion.css";

// Objetos personales
import Mencion from '../Objetos/ObjetoMencion.js';

//Iconos importados
import iconVerificado from '../../imagenes/iconos/icon_usuario_verificado_128px.png'

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, updateDoc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// ------------------------

const db = getFirestore();

var objMencion = new Mencion('','','','','','','');

// ------------------------

class BadgeMencion extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // objTarea = new Entrada(this.props.uid, this.props.date, this.props.texto, this.props.leido);

        // ID de la Tarea
        // idReferencia = this.props.idReferencia;

        // const uid = this.props.uid;
        // const mUid = this.props.idCreador;
        
        // ----------------------------        

        this.handleCompartir = this.handleCompartir.bind(this);
    }

    /* Eventos */

    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }

    /* Lo que mostramos en pantalla */
    render(){    

        return(

            <div className="BadgeMencion" id={this.props.idItem} title={this.props.autor}>

                <div>

                    {/* Logotipo del Autor */}
                    <img className="BadgeMencion__section-logotipo" src={this.props.logotipo} alt="" title={this.props.autor}/>

                    {/* Autor */} {/* Icono de verificado */}
                    <div className="BadgeMencion__section-titulo" title={this.props.autor}>
                        {this.props.autor} <img className="BadgeMencion__header-icon" src={iconVerificado} alt="" title={"Medio verificado"}/>
                    </div>
                    

                    {/* Descripción del autor */}
                    <div className="BadgeMencion__section-categoria" id={this.props.idItem + "_div"} title="Categoría">
                            {this.props.descripcionAutor}
                    </div>

                    {/* Cita del Autor */}
                    <p className="BadgeMencion__section-texto" id={this.props.idReferencia + "_div"}>{this.props.cita}</p>

                    {/* URL de la cita */}
                    <a className="BadgeMencion__section-urlCita" href={this.props.urlCita} target='_blank' rel='noreferrer' title='Ver cita en internet'>
                        Ver cita
                    </a>

                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeMencion;