import React from 'react';
import { Link } from 'react-router-dom';

// Objetos personales
import Usuario from '../SesionIniciada/ObjetoUsuario.js';

// import { getStorage, ref , uploadBytes  } from "firebase/storage";
import { getAuth, updateProfile  } from "firebase/auth";

// Importamos los componentes a Firebase
import { doc, setDoc } from "firebase/firestore";
import { getFirestore, collection, getDocs, query, where } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

import "../../global.css";
import ".././estilos/Badge.css";

import imagenPrincipal from '../.././imagenes/iconos/icon_iniciar_sesion_256px.png'

// Referencias a Firestore
const db = getFirestore();
const storage = getStorage();

// Para seleccionar la foto
var rutaFotoPerfil;
var mUid;
var file;
var url;
var referenciaStorage;

class BadgeEditarPerfil extends React.Component{
    

    constructor(props) {
        super(props);

        rutaFotoPerfil = this.props.rutaFotoPerfil;

        if (rutaFotoPerfil !== ""){

            this.state = {
                valorSubida: 0,
                picture: rutaFotoPerfil
            }

        } else {

            this.state = {
                valorSubida: 0,
                picture: imagenPrincipal
            }
        }

        this.handleUpload = this.handleUpload.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUpload(event){

        mUid = getAuth().currentUser.uid;
        file = event.target.files[0];
        
        // Agregar file.name para tener múltiples fotos en la referencia de usuario
        // url = "Usuarios/" + mUid + "/" + file.name;

        url = `Usuarios/${mUid}/foto-perfil`;
        referenciaStorage = ref(storage, url);

        const objectURL = URL.createObjectURL(file);

        this.setState({
            valorSubida: 0,
            picture: objectURL
        });
    }
    
    handleSubmit = (e) => {
        e.preventDefault();

        const usuarioActual = this.props.nombreUsuario;
        const nuevoNombreUsuario = e.target.elements.nombreUsuario.value;

        // Primero se verifica el Nombre de Usuario
        if (usuarioActual !== nuevoNombreUsuario){

            // Antes de crear la cuenta, se verifica si ya existe el Nombre de Usuario
            const referenciaUsuarios = collection(db, "Usuarios");

            // Variable de tipo Usuario.class
            const mUsuario = new Usuario('','','','','','','','','','','','');

            // Create a query against the collection.
            const q = query(referenciaUsuarios, where("nombreUsuario", "==", nuevoNombreUsuario)).withConverter(mUsuario.usuarioConverter);
            const querySnapshot = getDocs(q);

            querySnapshot
            .then((snapshot) => {

                snapshot.forEach((doc) => {
                    // doc.data() is never undefined for query doc snapshots

                    // uidUsuario = doc.id;
                    // mUsuario = doc.data();

                    //Si el Objeto Usuario YA existe (Nombre de Usuario registrado)
                    const mensajeUsuarioExistente = `El usuario @${nuevoNombreUsuario} ya existe.\n\nUtiliza otro nombre de usuario.`;
                    alert(mensajeUsuarioExistente);

                });

                //Si el Objeto Usuario aún no existe (Nombre de Usuario no registrado)
                if (snapshot.empty) {

                    this.actualizarDatos(e);
                  
                }

                return Promise.resolve(snapshot);
            })
            .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo comprobar la existencia del usuario: " + errorCode + "\n\n" + errorMessage);
            });

        } else {

            this.actualizarDatos(e);
        }
            
    }

    async actualizarDatos(e){
        e.preventDefault();

        const admin = this.props.admin;
        const suscrito = this.props.suscrito;
        const verificado = this.props.verificado;
    
        const nombrePublico = e.target.elements.nombrePublico.value;
        const nombreUsuario = e.target.elements.nombreUsuario.value;
        const puesto = e.target.elements.puesto.value;
        const biografia = e.target.elements.biografia.value;

        const redFacebook = e.target.elements.facebook.value;
        const redInstagram = e.target.elements.instagram.value;
        const redLinkedin = e.target.elements.linkedin.value;
        const redTwitter = e.target.elements.twitter.value;
    
        const auth = getAuth();       
        const mUid = auth.currentUser.uid;

        if (url != null){

            const uploadTask = uploadBytesResumable(referenciaStorage, file);
            // Register three observers:
            // 1. 'state_changed' observer, called any time the state changes
            // 2. Error observer, called on failure
            // 3. Completion observer, called on successful completion
            uploadTask.on('state_changed',
            (snapshot) => {
                // Observe state change events such as progress, pause, and resume
                // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                console.log('Upload is ' + progress + '% done');

                this.setState({
                    valorSubida: progress
                });

                switch (snapshot.state) {
                    case 'paused':
                        console.log('Upload is paused');
                    break;
                    case 'running':
                        console.log('Upload is running');
                    break;
                    default:
                        console.log('Pasó algo más');
                    break;
                }
            },
            (error) => {
                
                const mensaje = `Error cargando foto: ` + error.message ;
                console.log(mensaje);
            },
            () => {


                // Handle successful uploads on complete
                // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                    rutaFotoPerfil = downloadURL;
                    console.log(`Se subió a: ${referenciaStorage.fullPath}`);

                    updateProfile(auth.currentUser, {
                        displayName: nombreUsuario , photoURL: rutaFotoPerfil
                    }).then(() => {
                        // Profile updated!
                        console.log("Me actualicé :D");
                    }).catch((error) => {
                            // An error occurred
                            console.log("No me actualicé D:");
                    });

                    // Variable de tipo Usuario.class
                    const mUsuario = new Usuario(admin, suscrito, verificado, 
                        nombrePublico, nombreUsuario, puesto, biografia, rutaFotoPerfil, 
                        redFacebook, redInstagram, redLinkedin, redTwitter);
                
                    const referenciaUsuario = doc(db, "Usuarios", mUid).withConverter(mUsuario.usuarioConverter);
                    setDoc(referenciaUsuario, mUsuario).then((docRef) => {

                        this.setState({
                            valorSubida: 100
                        });
                        // El Usuario se actualizó con éxito
                        const mensaje = `¡Tu perfil se ha actualizado!`;
                        alert(mensaje);

                        window.location.reload();
                    });
                    

                });

            });

        } else {

            // Variable de tipo Usuario.class
            const mUsuario = new Usuario(admin, suscrito, verificado, 
                nombrePublico, nombreUsuario, puesto, biografia, rutaFotoPerfil, 
                redFacebook, redInstagram, redLinkedin, redTwitter);
        
            const referenciaUsuario = doc(db, "Usuarios", mUid).withConverter(mUsuario.usuarioConverter);
            setDoc(referenciaUsuario, mUsuario).then((docRef) => {

                this.setState({
                    valorSubida: 100
                });
                // El Usuario se actualizó con éxito
                const mensaje = `¡Tu perfil se ha actualizado!`;
                alert(mensaje);

                window.location.reload();
            });

        }
    }



    // Lo que se visualiza en pantalla
    render(){
        //this.props;

        return(

            <div className="Badge_crear_cuenta">

                {/* Header */}
                <div className="Badge__header__crear_cuenta"/>

                {/* Seleccionar foto */}
                <div className="Badge__section-name_crear_cuenta">       
                    
                    <div className="contenedor-imagen-avatar">
                        <img src={this.state.picture} alt="Imagen de perfil"/>
                    </div>

                    <input 
                        className="ocultar-vista" type="file" id="fileElem"
                        accept="image/*" onChange={this.handleUpload}/>
                    <label className="btn btn-mini" htmlFor="fileElem">Seleccionar foto</label>
            
                </div>

                {/* Formulario */}
                <form onSubmit={this.handleSubmit} className="Badge__formulario">

                    <div className="formulario">
                        <label>Nombre público</label>
                        <input className="form-control" type="text" defaultValue={this.props.nombrePublico}
                        name="nombrePublico" placeholder="Nombre y apellido (o marca)" />
                    </div>

                    <div className="formulario">
                        <label>Nombre de usuario</label>
                        <input className="form-control" type="text" defaultValue={this.props.nombreUsuario}
                        name="nombreUsuario" placeholder="Sin el @" pattern="[A-Za-z0-9._]{5,20}" title="Un Nombre de Usuario válido consiste de 5 a 20 caracteres (puedes usar letras, números, punto o guión bajo)" required/>
                    </div>

                    <div className="formulario">
                        <label>Puesto de trabajo</label>
                        <input className="form-control" type="text" defaultValue={this.props.puesto}
                        name="puesto" placeholder="Opcional"/>
                    </div>

                    <div className="formulario">
                        <label>Biografía</label>
                        <textarea className="form-control" type="text" defaultValue={this.props.biografia}
                        name="biografia" placeholder="Opcional" style={{ height: "120px", resize: "none" }}/>
                    </div>

                    <br/>
                    <br/>

                    <span><b>Opcional (pero recomendado):</b></span>

                    <div className="formulario">
                        <label>Facebook</label>
                        <input className="form-control" type="text" defaultValue={this.props.redFacebook}
                        name="facebook" placeholder="Usuario (sin @)"/>
                    </div>

                    <div className="formulario">
                        <label>Instagram</label>
                        <input className="form-control" type="text" defaultValue={this.props.redInstagram}
                        name="instagram" placeholder="Usuario (sin @)"/>
                    </div>

                    <div className="formulario">
                        <label>LinkedIn</label>
                        <input className="form-control" type="text" defaultValue={this.props.redLinkedin}
                        name="linkedin" placeholder="Usuario (sin @)"/>
                    </div>

                    <div className="formulario">
                        <label>Twitter</label>
                        <input className="form-control" type="text" defaultValue={this.props.redTwitter}
                        name="twitter" placeholder="Usuario (sin @)"/>
                    </div>


                    <button className="btn btn-secondary" >Actualizar perfil</button>

                </form>
                <br />

                {/* Progress bar */}
                <progress value={this.state.valorSubida} max="100"></progress>
                <br/>

                {/* Botón */}
                <Link className="link-simple" to="/">Regresar</Link>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeEditarPerfil;