import React from 'react'
import { Link } from 'react-router-dom';
// import ReactDOM from 'react-dom';

// Para crear imágenes
import * as htmlToImage from 'html-to-image';
// import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

// Descargar imágenes
import download from 'downloadjs';

import '../global.css';

// Objetos personales
import TarjetaPerfil from '../componentes/TarjetaPerfil.js';
// import { App as NavbarPublicar } from '../componentes/SesionIniciada/NavbarPublicar.js';
import NavbarPublicar from '../componentes/SesionIniciada/NavbarPublicar.js';
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import Entrada from '../componentes/SesionIniciada/ObjetoEntrada.js';
import Siguiendo from '../componentes/SesionIniciada/ObjetoSiguiendo.js';
import BadgeEntradas from '../componentes/SesionIniciada/BadgeEntrada.js';

// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, collection, getDocs, query, /* where, */ orderBy, /* limit */ } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;

// Sobre los fans
var numeroFans = 0;
var mensajeFans = "";

// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

// Cierra el menú de compartir si el usuario hace click fuera de este
window.onclick = function(event) {
    if (!event.target.matches('.menu-desplegable-compartir')) {

      var dropdowns = document.getElementsByClassName("opciones-menu");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show')) {
          openDropdown.classList.remove('show');
        }
      }
    }
}

// ------------------------

class Perfil extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // const user = auth.currentUser;
        mUid = auth.currentUser.uid;
        // console.log("UID: "+mUid);


        // Se muestran datos provisionales
        this.state = {

            mensajeTareas: "",
            idReferencia: "",
            ListaTareas:[]
        };

        // mUsuario = this.props.mUsuario;
        // console.log(this.props.nombrePublico);

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            mUid = auth.currentUser.uid;
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                    
                return Promise.resolve(docSnap);
            });

        }

        this.getListaFans();
        this.getListaEntradasBlog();

        // Handles's
        this.handleMensajeSobreFans = this.handleMensajeSobreFans.bind(this);
        this.handleActualizarPagina = this.handleActualizarPagina.bind(this);
        
        // Compartir
        this.handleCompartir = this.handleCompartir.bind(this);

        this.handleCompartirInstaStory = this.handleCompartirInstaStory.bind(this);
        this.handleCompartirTwitter = this.handleCompartirTwitter.bind(this);
        this.handleDescargarFoto = this.handleDescargarFoto.bind(this);
        this.handleCopiarEnlace = this.handleCopiarEnlace.bind(this);

        
    }
    
    // FANS ------------------------
    async getListaFans(){

        const referenciaFans = collection(db, `Fans/${mUid}/Personas`);

        // Create a query against the collection.
        const q = query(referenciaFans).withConverter(Siguiendo.siguiendoConverter);
        const querySnapshot = getDocs(q);

        // Obtenemos cuántas personas siguen esta cuenta
        numeroFans = (await querySnapshot).size;

        // Dependiendo del número es lo que se muestra
        if(numeroFans === 0){

            mensajeFans = `Aún no tienes seguidores`;
        } else if (numeroFans === 1){

            mensajeFans = `1 persona te está siguiendo`;
        } else {

            mensajeFans = `${numeroFans} seguidores`;
        }

    }

    // ENTRADAS --------------------
    async getListaEntradasBlog(){
        
        // Creamos una lista local
        let lista = [];

        const referenciaComentarios = collection(db, `Blog/${mUid}/Entrada`);

        // Create a query against the collection.
        // const q = query(referenciaComentarios, orderBy("date", "desc"), limit(5)).withConverter(Entrada.entradaConverter);
        const q = query(referenciaComentarios, orderBy("date", "desc")).withConverter(Entrada.entradaConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    // Variable de tipo Entrada.class
                    var objEntrada = new Entrada('','','','','','','','','','');
                    objEntrada = documento.data();

                    let idReferencia = documento.id;

                    let autor = objEntrada.autor;
                    let date = objEntrada.date;

                    let privacidad = objEntrada.privacidad;
                    let categoria = objEntrada.categoria;

                    let portada = objEntrada.portada;
                    let titulo = objEntrada.titulo;
                    let subtitulo = objEntrada.subtitulo;
                    let texto = objEntrada.texto;

                    // Convertimos Timestamp a Date
                    const dateEntrada = date.toDate();

                    /* NECESARIO para la tarjeta */
                    // "día, fecha de mes de año"
                    var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                    // "hora:minuto am/pm"
                    // var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);
                    

                    // Separamos para mostrar una URL más amigable
                    const urlPrivada = "autor_" + autor + "_fecha_";
                    const urlPublica = idReferencia.replace(urlPrivada, "");


                    var nombrePublico = mUsuario.nombrePublico;
                    var nombreUsuario = mUsuario.nombreUsuario;

                    // Creamos un objeto local
                    let objeto = {idReferencia, urlPrivada, urlPublica, autor, date, privacidad, categoria, portada, titulo, subtitulo, texto, fechaString, nombrePublico, nombreUsuario };

                    // Agregamos el objeto local a la lista local
                    lista.push(objeto);                

                    /*
                    var objetoUsuario = new Usuario('','','','','','','','');
                    const ref = doc(db, "Usuarios", autor).withConverter(objetoUsuario.usuarioConverter);
                    const docSnap =  getDoc(ref);

                    // Se piden los datos
                    docSnap.then((snapshot) => {
                                                    
                        objetoUsuario = snapshot.data();
                        nombrePublico = objetoUsuario.nombrePublico;
                        nombreUsuario = objetoUsuario.nombreUsuario;

                        // Creamos un objeto local
                        let objeto = {idReferencia, autor, date, portada, titulo, subtitulo, texto, horaString, nombrePublico, nombreUsuario };

                        // Agregamos el objeto local a la lista local
                        lista.push(objeto);

                        // Se actualiza el estado
                        this.setState(() => {
                            return {
                                idReferencia: idReferencia,
                                ListaTareas: lista
                            }
                        });
                    });
                    */

                    // Obtenemos cuántas entradas hay
                    var numeroEntradas = lista.length;
                    // Texto debajo de las entradas
                    const mensajeNumeroTareas = `Últimas ${numeroEntradas} entradas del blog`;

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            mensajeTareas: mensajeNumeroTareas,
                            idReferencia: idReferencia,
                            ListaTareas: lista
                        }
                    });
                });

            if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaTareas: lista
                        }
                    });

            }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    /* Eventos */
    handleMensajeSobreFans = (e) => {

        e.preventDefault();

        const mensaje = `Personas que te han agregado a su lista de contactos`;
        alert(mensaje);
    }

    handleActualizarPagina = (e) => {
        e.preventDefault();

        window.location.reload();
    }
    
    handleCompartir = (e) => {
        e.preventDefault();

        document.getElementById("menuCompartir").classList.toggle("show");
    }

    handleCompartirInstaStory(e){
        e.preventDefault();

        var node = document.getElementById('perfilLocal');
        // htmlToImage.toJpeg(node, { quality: 1 })
        htmlToImage.toBlob(node).then(async function (blob) {

            const nombreImagen = `Perfil de ` + mUsuario.nombreUsuario;

            // Incluso para compartir solo un archivo, debe enviarse como una matriz de archivos.
            const files = [new File([blob], `${nombreImagen}.png`, { type: blob.type })];
            
            const datos = {
                title: 'Adolfo Ordinola',
                text: `| Comenta mi perfil en adolfoordinola.com/${mUsuario.nombreUsuario}`,
                files
            }

            // console.log("Creando datos... \n\n" + blob);

            // Si el navegador lo soporta y el dispositivo lo soporta
            if (navigator.canShare && navigator.canShare(datos)) {

              try {

                await navigator.share(datos);
                // console.log("Compartiendo...");

              } catch (err) {

                if (err.name !== 'AbortError') {
                  console.error(err.name, err.message)
                }

              }

            } else { // El navegador no lo soporta o el dispositivo lo no soporta
              console.warn('El navegador no lo soporta o el dispositivo lo no soporta', datos);
            }

        });
        
    }

    handleCompartirTwitter(e){
        e.preventDefault();

        var node = document.getElementById('perfilParaTwitter');
        // console.log("Construyendo datos...");

        htmlToImage.toBlob(node).then(function (blob) {

            const nombreImagen = `Perfil de ` + mUsuario.nombreUsuario;

            // Incluso para compartir solo un archivo, debe enviarse como una matriz de archivos.
            const files = [new File([blob], `${nombreImagen}.png`, { type: blob.type })];
            
            const datos = {
                title: 'Adolfo Ordinola',
                text: `| Comenta mi perfil en adolfoordinola.com/${mUsuario.nombreUsuario}`,
                files
            }

            // console.log("Creando datos... \n\n" + blob);

            // Si el navegador lo soporta y el dispositivo lo soporta
            if (navigator.canShare && navigator.canShare(datos)) {

              try {

                navigator.share(datos);
                // console.log("Compartiendo...");

              } catch (err) {

                if (err.name !== 'AbortError') {
                  console.error(err.name, err.message)
                }

              }

            } else { // El navegador no lo soporta o el dispositivo lo no soporta
              console.warn('El navegador no lo soporta o el dispositivo lo no soporta', datos);
            }

        });
    
    }

    handleDescargarFoto(e){
        e.preventDefault();

        var node = document.getElementById('perfilLocal');
        // htmlToImage.toJpeg(node, { quality: 1 })
        htmlToImage.toPng(node)
        .then(function (dataUrl) {

            const nombreImagen = `Perfil de ` + mUsuario.nombreUsuario;
            download(dataUrl, `${nombreImagen}.png`);            
        });


        /* htmlToImage.toBlob(node)
        .then(function (blob) {
            if (window.saveAs) {
                window.saveAs(blob, 'my-node.png');
                console.log("window.saveAs");
            } else {
                
                // FileSaver.saveAs(blob, 'my-node.png');
                console.log("donwload");
                const nombreImagen = `Perfil de Adolfo Ordinola`;
                download(blob, `${nombreImagen}.png`);
            }
        }); */

    }

    handleCopiarEnlace(e){
        e.preventDefault();

        const urlPerfil = `https://apeordi.com/${mUsuario.nombreUsuario}`;

        navigator.clipboard.writeText(urlPerfil)
        .then(() => {
            
            // console.log("Text copied to clipboard...");
            alert("¡Enlace copiado al portapapeles!");
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    }
    

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (

            <React.Fragment>

                <div className="header-pantalla-completa"  theme={this.props.theme}>
                    
                    {/* Vista de perfil */}
                    <div className="header-dos-tercios-pantalla">

                        <div id='perfilParaTwitter' className="perfilParaTwitter">
                            <div id='perfilLocal'>
                                <TarjetaPerfil
                                    theme={this.props.theme}

                                    admin={mUsuario.admin}
                                    suscrito={mUsuario.suscrito}                            
                                    verificado={mUsuario.verificado}

                                    nombrePublico={mUsuario.nombrePublico}
                                    nombreUsuario={mUsuario.nombreUsuario}
                                    puesto={mUsuario.puesto}
                                    biografia={mUsuario.biografia}
                                    rutaFotoPerfil={mUsuario.rutaFotoPerfil}

                                    redFacebook={mUsuario.redFacebook}
                                    redInstagram={mUsuario.redInstagram}
                                    redLinkedin={mUsuario.redLinkedin}
                                    redTwitter={mUsuario.redTwitter}
                                />
                            </div>
                        </div>

                        <br/>
                        <span className="link-fans" onClick={this.handleMensajeSobreFans}><b>{mensajeFans}</b></span>

                        {/* <Link className="menu-desplegable-compartir" to='/editar-perfil' >Editar perfil</Link> */}
                        <div className="dropdown">
                            
                            <button className="menu-desplegable-compartir" onClick={this.handleCompartir}>Compartir perfil</button>

                            <div className='opciones-menu' id='menuCompartir'>
                                <button onClick={this.handleCompartirTwitter}>Compartir foto</button>
                                <button onClick={this.handleDescargarFoto}>Descargar foto</button>
                                <button onClick={this.handleCopiarEnlace}>Copiar enlace</button>
                            </div>
                        </div>

                    </div>

                    {/* Instrucciones */}
                    <div className="header-un-tercio-pantalla">

                        <span className="span-centrado-light">Home</span>
                        <span>Aquí verás un resumen de:</span><br/>
                        <br/>
                        <span>• Los seguidores que tienes.</span><br/>
                        <span>• Tus entradas en el blog.</span><br/>
                        <span>• Tu vista de perfil.</span><br/>                       
                        
                    </div>

                    {/* Acceso rápido para crear una entrada */}
                    <NavbarPublicar/>

                </div>

                {/* Últimas entradas del blog */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                        <div className="filtro-pantalla-completa">
                            <span className='span-filtro-dia'>{this.state.mensajeTareas}</span>
                        </div>
                    

                        {/* Entradas del blog */}
                        {this.state.ListaTareas.map((lista) => (

                            <BadgeEntradas
                                theme={this.props.theme}

                                key={lista.id}
                                idItem={`ID${lista.idReferencia}`}
                                
                                idReferencia={lista.idReferencia}
                                urlPrivada={lista.urlPrivada}
                                urlPublica={lista.urlPublica}

                                public={false}

                                autor={lista.autor}
                                date={lista.date}
                                privacidad={lista.privacidad}
                                categoria={lista.categoria}

                                portada={lista.portada}
                                titulo={lista.titulo}
                                subtitulo={lista.subtitulo}
                                texto={lista.texto}
                                
                                fechaString={lista.fechaString}
                                nombrePublico={lista.nombrePublico}
                                nombreUsuario={lista.nombreUsuario} />
                        ))}

                </div>

                {/* Número de entradas */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    <Link to='/blog'>- Ver todas las entradas -</Link>
                </div>

                <br theme={this.props.theme}/>


            </React.Fragment>


        );
    }
}

export default Perfil;