import React from 'react';
import { Link } from 'react-router-dom';

import '../../global.css';

import banner from '../../imagenes/portadas/OpenGraph Fortin Tech.png';
import iconPlayStore from '../../imagenes/iconos/icon-color-google-play.svg';

import imagenFortinTech from '../../imagenes/FortinTech.png';
import imagenFamiliaPerezOrdinola from '../../imagenes/FamiliaPerezOrdinola.jpg';

const imagenIdea = "https://images.unsplash.com/photo-1599008633587-95b5da16d29f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";
const imagenDesarrollo = "https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
const imagenMisProyectos = "https://images.unsplash.com/photo-1621570168855-e9651220e831?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";
// const imagen = "";

class Adolfo extends React.Component{

    render() {
        return (


            <React.Fragment>

                {/* Banner */}
                <div className="header-portada"  theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada"/>

                    <div className="header-contenido-portada">
                        <h1>Fortín Tech</h1>
                        <h2>© All Rights Reserved</h2>
                    </div>
                </div>

                {/* Portada */}

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenFortinTech} alt="Imagen de Fortín Tech" style={{ height: '100%', width: '100%', padding:'25%', borderRadius:'350px' }}/>
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Quiénes somos?</h3>
                        <br/>
                        <span className="span-texto-normal">Somos una empresa de tecnología que crea soluciones digitales.</span><br/>
                        <span className="span-texto-normal">Es decir, <b>facilitamos el trabajo de profesionistas y de personas productivas</b>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Creamos apps, páginas de internet, plataformas y comunidades GRATIS. Desde entornos médicos, apuntes digitales de ingeniería y más contenido de valor.</span><br/>
                        <br/>
                        <h3>¿Por qué lo hacemos gratis?</h3><br/>
                        <span className="span-texto-normal">Por que construimos herramientas que nosotros también usamos y que normalmente son de pago en otros lados.</span><br/>
                        <br/>
                        <h4><a className="link-normal" target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/dev?id=5525597372904808892"><img src={iconPlayStore} alt="Icono de Twitter" style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/> Apps en Play Store</a></h4>
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Cómo surgió Fortín Tech?</h3>
                        <br/>
                        <span className="span-texto-normal">Todo ocurrió ante la necesidad de utilizar herramientas que aún no existían para trabajar profesionalmente que cumplieran con:</span><br/>
                        <br/>
                        <span className="span-texto-normal">• Facilidad para trabajar en equipos.</span><br/>
                        <span className="span-texto-normal">• Alta accesibilidad.</span><br/>
                        <span className="span-texto-normal">• De bajo costo.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Fue ahí que dijimos: <b>si no existen, ¿porqué esperar a que las inventen? Las hagamos.</b></span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenIdea} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenDesarrollo} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>

                    <div className="header-media-pantalla-blanca">
                        <h3>¿Cómo fue el desarrollo?</h3>
                        <br/>
                        <span className="span-texto-normal">Fue algo colaboritivo dentro de la <b>Familia Pérez Ordinola</b>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Comenzamos a enfocarnos en crear soluciones desde el 2017, pero fue durante la pandemia (2020-2022) que comenzamos a acelerar la producción de nuevas soluciones.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Si quieres conocer todo el desarrollo, dejaremos un documentando posteriormente.</span><br/>
                        <br/>                         
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué otras cosas hemos creado?</h3>
                        <br/>
                        <h4><b>• APAMED</b></h4>
                        <span className="span-texto-normal">Plataforma gratuita de capacitación para médicos, enfermeros y odontólogos; Con presencia en más de 50 países.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://apamedoficial.com/acerca-de">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• Control de Obras</b></h4>
                        <span className="span-texto-normal">Bitácora inteligente para ingenieros civiles y arquitectos. Basada en la nube. Ideal para equipos de trabajo de campo.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://adolfoordinola.com/proyectos/control-flows">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• MyBio.fans</b></h4>
                        <span className="span-texto-normal">Plataforma de reseñas anónimas para interactuar entre usuarios. Como alternativa al invasivo NGL. Basada en la privacidad.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://mybio.fans/">+ Leer más</a><br/>
                        <br/>
                        <span className="span-texto-normal">También puedes visitar nuestras <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/dev?id=5525597372904808892">apps para Android</a> 🌎</span><br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenMisProyectos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenFamiliaPerezOrdinola} alt="Imagen de Unsplash" style={{ aspectRatio: '1', height: '250px', width: '250px', borderRadius:'40px', margin:'auto', display:'flex', alignItems:'center', opacity:'0.50' }} />
                    </div>

                    <div className="header-media-pantalla-blanca">
                        <h3>¿Quiénes lideran Fortín Tech?</h3>
                        <br/>
                        <span className="span-texto-normal">El rumbo general se dicta por la <b>Familia Pérez Ordinola</b>. Pero, los representantes públicos son:</span><br/>
                        <br/>
                        <span className="span-texto-normal">• <Link className="link-normal" to="/adolfo">Adolfo Ordinola</Link>, CEO</span><br/>
                        <span className="span-texto-normal">• <Link className="link-normal" to="/armando">Armando Ordinola</Link>, CEO</span><br/>
                        <br/>
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Adolfo;