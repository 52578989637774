const DataSeo = {

    // Inicio
    MainTitle: 'Adolfo Ordinola | Android Developer & Web Designer',
    // MainDescription: 'Desarrollador Front-End</b> especializado en React JS. Creación, prototipado y desarrollo de productos digitales centrados en el usuario. Apasionado por el emprendimiento. Estoy en constante aprendizaje.',
    // MainDescription: 'CEO de APAMED, una empresa dedicada a crear soluciones innovadoras para el sector médico. Apasionado emprendedor, siempre buscando nuevos retos y oportunidades para mejorar el mundo con mi trabajo.',
    MainDescription: 'Apasionado emprendedor. Siempre buscando nuevos retos y oportunidades para mejorar el mundo con mi trabajo.',
    MainLink: 'https://apeordi.com',
    MainImage: 'https://images.unsplash.com/photo-1423784346385-c1d4dac9893a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80',

    // Apuntes
    ApuntesTitle: 'Apuntes | Apeordi',
    ApuntesDescription: 'Accede a mis apuntes, notas y resúmenes de clases de la universidad.',
    ApuntesLink: 'https://apeordi.com/apuntes',
    ApuntesImage: 'https://images.unsplash.com/photo-1630452516047-3f673430057a?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=4800',

    // Portafolio
    PortafolioTitle: 'Portafolio | Apeordi',
    PortafolioDescription: 'Conoce todos mis proyectos profesionales y colaboraciones importantes.',
    PortafolioLink: 'https://apeordi.com/portafolio',
    PortafolioImage: 'https://images.unsplash.com/photo-1520607162513-77705c0f0d4a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80',

    // Blog
    BlogTitle: 'Blog | Apeordi',
    BlogDescription: 'Publicaciones sobre temas de tecnología, noticias del mundo Tech y experiencias de vida.',
    BlogLink: 'https://apeordi.com/blog',
    BlogImage: 'https://images.unsplash.com/photo-1572016047668-5b5e909e1605?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',

    // Sobre mí
    SobreMiTitle: 'Sobre mí | Apeordi',
    SobreMiDescription: 'Conóceme, léeme, entiende mi origen para llegar a donde estoy.',
    SobreMiLink: 'https://apeordi.com/sobre-mi',
    SobreMiImage: 'https://images.unsplash.com/photo-1512428559087-560fa5ceab42?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',

    // Contacto
    ContactoTitle: 'Contacto | Apeordi',
    ContactoDescription: 'Conoce mis redes sociales o envíame un correo.',
    ContactoLink: 'https://apeordi.com/contacto',
    ContactoImage: 'https://images.unsplash.com/photo-1499914567823-c240485cb7d1?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80',

    // Menciones
    MencionesTitle: 'Menciones | Apeordi',
    MencionesDescription: 'Sitios oficiales, noticieros y medios de comunicación que han mencionado mi trabajo.',
    MencionesLink: 'https://apeordi.com/menciones',
    MencionesImage: 'https://images.unsplash.com/photo-1503428593586-e225b39bddfe?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80',

    // Ejemplo
    Title: ' | Apeordi',
    Description: '',
    Link: 'https://apeordi.com/',
    Image: '',
}
  
export default DataSeo;