import React from 'react';

import "../../global.css";
import "./estilos/BadgeLeerPagina.css";
import NotFound from '../../paginas/NotFound.js';

//Iconos importados
// import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png';

// ------------------------

class BadgeLeerPagina extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        // console.log(idReferencia);

        // const mUid = this.props.autor;
        
        // ----------------------------

        this.handleCompartir = this.handleCompartir.bind(this);
    }

    /* Eventos */
    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }

    
    /* Lo que mostramos en pantalla */
    render(){

        return(

            <div >

                <div hidden={!this.props.paginaExistente} className="BadgeLeerPagina">

                    {/* Portada */}
                    <div className="BadgeLeerPagina-portada">

                        {/* Imagen de Portada*/}
                        <img className="BadgeLeerPagina-imagen-portada" src={this.props.portada} alt="Imagen de portada" />

                        {/* Título y Subtítulo*/}
                        <div className="header-contenido-portada">
                            <h1>{this.props.titulo}</h1>
                            <h2>{this.props.subtitulo}</h2>
                        </div>

                    </div>

                    {/* Hora y Fecha */}
                    <span className="BadgeLeerPagina-fecha">{this.props.fechaString} - {this.props.horaString} </span>

                    {/* Texto */}
                    <p className="BadgeLeerPagina-texto">{this.props.texto}</p>

                </div>

                <NotFound hidden={this.props.paginaExistente} title={"Página no encontrada"} theme={this.props.theme}/>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeLeerPagina;