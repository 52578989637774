import React from 'react';
import { Link } from 'react-router-dom';

import './estilos/Sidebar.css';

import iconCrearEntrada from '../../imagenes/iconos/icon-crear-entrada.svg';
import iconCrearPagina from '../../imagenes/iconos/icon-crear-pagina.svg';
import iconCrearProyecto from '../../imagenes/iconos/icon-crear-proyecto.svg';

import iconCrearMencion from '../../imagenes/iconos/icon-crear-mencion.svg';
import iconCrearCategoria from '../../imagenes/iconos/icon-crear-categoria.svg';

// ------------------------

class SidebarCuenta extends React.Component {


    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className="Sidebar">

                <div className="Sidebar-menu" id='menu-completo'>

                    <ul className="Sidebar-list">

                        <p className="Sidebar__fuente">Crear</p>
                        <Link className="Sidebar-link" to="/crear-entrada"><img className="Sidebar-icon" src={iconCrearEntrada} alt="Botón"/>Entrada</Link>
                        <Link className="Sidebar-link" to="/crear-pagina"><img className="Sidebar-icon" src={iconCrearPagina} alt="Botón"/>Página</Link>
                        <Link className="Sidebar-link" to="/crear-proyecto"><img className="Sidebar-icon" src={iconCrearProyecto} alt="Botón"/>Proyecto</Link>
                        
                        <div className='divider'/>
                        <Link className="Sidebar-link" to="/crear-mencion"><img className="Sidebar-icon" src={iconCrearMencion} alt="Botón"/>Mención</Link>
                        <Link className="Sidebar-link" to="/crear-categoria"><img className="Sidebar-icon" src={iconCrearCategoria} alt="Botón"/>Categoría</Link>

                    </ul>
                    
                </div>

            </div>
        );
    }
}


export default SidebarCuenta;