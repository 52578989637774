import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeCrearPost.css";

// Objetos personales
// import Post from './ObjetoPost.js';
import SubPagina from '../Objetos/ObjetoSubPagina.js';


// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import Snackbar from '../Contenido/Snackbar/index.js';

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1515595967223-f9fa59af5a3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80";

// ------------------------

// Referencias a Firestore
const db = getFirestore();
const storage = getStorage();

// Para seleccionar la foto
var rutaFotoPortada = "";
var mUid;
var file;
var urlNuevaPortada = "";
var referenciaStorage;

var valorCategoria = "";

// ------------------------

class BadgeCrearPost extends React.Component{

    // const uid = this.props.uid;
    mUid = this.props.autor;

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        rutaFotoPortada = this.props.portada;
        valorCategoria = this.props.categoria;

        if( this.props.postNuevo === true ){

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                picture: banner,
                mostrarSeleccionFoto: true,
                textoBoton: "",
                textoBotonSubirEntrada: "Postear",

                categoriaSeleccionada: "⚡ Nota rápida",
                otraCategoria: false,

                textoMensaje: ""
            };

        } else {

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                picture: this.props.portada,
                mostrarSeleccionFoto: true,
                textoBoton: "",
                textoBotonSubirEntrada: "Actualizar post",

                categoriaSeleccionada: this.props.categoria,
                otraCategoria: false,

                textoMensaje: ""
            };

        }

        // console.log(idReferencia);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        
        // ----------------------------

        this.handleMostrarPortada = this.handleMostrarPortada.bind(this);
        this.handleElegirFoto = this.handleElegirFoto.bind(this);
        this.handleElegirCategoria = this.handleElegirCategoria.bind(this);
        this.handleSubirEntrada = this.handleSubirEntrada.bind(this);
    }

    /* Eventos */
    handleMostrarPortada(event){
        event.preventDefault();

        // Para que no se cargue la foto seleccionada
        urlNuevaPortada = "";

        if(this.props.postNuevo){

            this.setState({
                picture: banner,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        } else {

            this.setState({
                picture: this.props.portada,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        }
    }

    handleElegirFoto(event){

        mUid = getAuth().currentUser.uid;
        file = event.target.files[0];
        if (file !== null){

            const objectURL = URL.createObjectURL(file);
            // Para que se cargue la foto seleccionada
            urlNuevaPortada = "Imagen cargada";

            this.setState({
                valorSubida: 0,
                picture: objectURL,
                mostrarSeleccionFoto: false,
                textoBoton: "Restablecer"
            });
        }
        
    }

    handleElegirCategoria = (e) => {
        e.preventDefault();

        valorCategoria = e.target.value;
        // console.log("Valor: " + valorCategoria)
        this.setState({
            categoriaSeleccionada: valorCategoria
        });

        if(valorCategoria === "Otra categoría"){
            this.setState({
                otraCategoria: true
            });
        } else {
            this.setState({
                otraCategoria: false
            });
        }
        
    }

    handleSubirEntrada = (e) => {
        e.preventDefault();

        
        const textoNuevo = e.target.elements.texto.value;

        const fechaNueva = e.target.elements.fecha.value;
        const horaNueva = e.target.elements.hora.value;

        // const urlPublicNueva = e.target.elements.url.value;

        // rutaFotoPortada = this.props.portada;
        const autor = this.props.autor;
        mUid = this.props.autor;

        // var categoria = this.state.categoriaSeleccionada;
        var categoria = e.target.elements.categoria.value;
        
        // NO utilizables en Post
        const privacidad = "";
        const portada = "";
        const titulo = "";
        const subtitulo = "";

        // Analítica
        const noComments = this.props.noComments;
        const noReposts = this.props.noReposts;
        const noLikes = this.props.noLikes;
        const noShared = this.props.noShared;
        const noSaved = this.props.noSaved;
        const noViews = this.props.noViews;

        const valor = valorCategoria;

        if(valor === "Otra categoría"){
            categoria = e.target.elements.otraCategoria.value;
        }/* else {
            categoria = this.state.categoriaSeleccionada;
        } */


        if (autor !== "") {
            // Si obtengo una uid existente

            if (textoNuevo === null || textoNuevo === "") {
                const mensaje = `Escribe algo genial`;
                alert(mensaje);

            } else if (fechaNueva === null || fechaNueva === "") {
                const mensaje = `Elige una FECHA 📅`;
                alert(mensaje);

            } else if (horaNueva === null || horaNueva === "") {
                const mensaje = `Elige una HORA ⌚`;
                alert(mensaje);

            } else {


                // var idReferenciaString;
                // var urlPublica;
                var fecha = new Date();
                
                // Separamos los datos de la fecha
                const [anio, mes, dia] = fechaNueva.split("-");
                const value1 = Number(anio);
                const value2 = Number(mes) - 1; // Enero inicia en 0
                const value3 = Number(dia);
                fecha.setFullYear(value1);
                fecha.setMonth(value2);
                fecha.setDate(value3);
                    
                // Separamos los datos de la hora
                const [hora, minuto] = horaNueva.split(":");
                const val1 = Number(hora);
                const val2 = Number(minuto);
                fecha.setHours(val1);
                fecha.setMinutes(val2);

                // const date = new Date();
                // const urlPrivada = "autor_" + mUid + "_fecha_";
                var urlPublica =
                    fecha.getFullYear() +
                    "-" +
                    (fecha.getMonth() + 1) +
                    "-" +
                    (fecha.getDate()) +
                    "-" +
                    fecha.getHours() +
                    "-" +
                    fecha.getMinutes() +
                    "-" +
                    fecha.getSeconds();
                // idReferenciaString = urlPrivada + urlPublica;

                /* if (this.props.idReferencia !== ""){
                    idReferenciaString = this.props.idReferencia;
                } */

                // const mensaje = `Tiempo del servidor: ` + serverTimestamp();
                // console.log(fecha);

                var idSubPagina = this.props.idSubPagina;
                if (idSubPagina === "") { // Post nuevo

                    idSubPagina = urlPublica;

                } else {  // Post editado

                    urlPublica = this.props.urlPublica;
                }

                if (urlNuevaPortada !== ""){

                    // Agregar file.name para tener múltiples fotos en la referencia de usuario
                    const url = "Posts/" + mUid + "/Estado/" + idSubPagina + "/portada";
                    // const url = "Posts/" + mUid + "/Estado/" + idSubPagina + "/listaGaleria";

                    // url = `Blog/${mUid}/foto-perfil`;
                    referenciaStorage = ref(storage, url);

                    // Se sube el archivo
                    const uploadTask = uploadBytesResumable(referenciaStorage, file);
                    // Register three observers:
                    // 1. 'state_changed' observer, called any time the state changes
                    // 2. Error observer, called on failure
                    // 3. Completion observer, called on successful completion
                    uploadTask.on('state_changed',
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
        
                        this.setState({
                            valorSubida: progress
                        });
        
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                            break;
                            case 'running':
                                console.log('Upload is running');
                            break;
                            default:
                                console.log('Pasó algo más');
                            break;
                        }
                    },
                    (error) => {
                        
                        const mensaje = `Error cargando portada: ` + error.message ;
                        console.log(mensaje);
                    },
                    () => {
        
        
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {

                            rutaFotoPortada = downloadURL; // Usar "portada" en su defecto
                            console.log(`Se subió a: ${referenciaStorage.fullPath}`);

                            // const objEntrada = new Entrada(autor, fecha, privacidad, categoria, rutaFotoPortada, tituloNuevo, subtituloNuevo, textoNuevo, listaGaleria, likes);
                            // const objPost = new Post(autor, fecha, categoria, textoNuevo, listaGaleria, likes);
                            const objSubPagina = new SubPagina(
                                urlPublica, autor, fecha, privacidad,
                                categoria, portada, titulo, subtitulo, textoNuevo,
                                noComments, noReposts, noLikes, noShared, noSaved, noViews
                            );

                            const ref = doc( db, `Posts/${autor}/Estado`, idSubPagina).withConverter(objSubPagina.subPaginaConverter);
                            setDoc(ref, objSubPagina);

                            this.setState({
                                valorSubida: 100,
                                subidaExitosa: true,

                                textoMensaje: "Post agregado"
                            });
                            // alert("¡Entrada agregada!");

                            // Reiniciamos la URL de la portada
                            urlNuevaPortada = "";
                            
                        });
                    });
        
                } else {

                    const objSubPagina = new SubPagina(
                        urlPublica, autor, fecha, privacidad,
                        categoria, portada, titulo, subtitulo, textoNuevo,
                        noComments, noReposts, noLikes, noShared, noSaved, noViews
                    );

                    const ref = doc( db, `Posts/${autor}/Estado`, idSubPagina).withConverter(objSubPagina.subPaginaConverter);
                    setDoc(ref, objSubPagina);

                    this.setState({
                        valorSubida: 100,
                        subidaExitosa: true,

                        textoMensaje: "Post agregado"
                    });
                    

                    // Reiniciamos la URL de la portada
                    urlNuevaPortada = "";        
                }
                

            }

        } else {

            // Si no tengo una uid existente
            mUid = this.props.autor;
            console.log("No se encontró la referencia de Autor: " + mUid);
        }
    };

    /* Lo que mostramos en pantalla */
    render(){

        return(

            <div>

                {/* Editable */}
                <div className="BadgeCrearPost">


                    {/* Formulario */}
                    <form onSubmit={this.handleSubirEntrada}>


                        {/* Texto */}
                        <textarea className="BadgeCrearPost-texto" type="text" id="texto" defaultValue={this.props.texto}
                            name="texto" placeholder="¿Qué estás pensando?"/>

                        {/* Opciones (barra de funciones) */}
                        <div className="BadgeCrearPost-opciones">

                            {/* Fecha y Hora */}
                            <div className="formulario-tarea">
                                <label className="label-tarea">Fecha: </label>
                                <input type="date" name="fecha" id="fecha" title="Elige una fecha" defaultValue={this.props.fechaString}/>
                            </div>
                            <div className="formulario-tarea">
                                <label className="label-tarea">Hora: </label>
                                <input type="time" name="hora" id="hora" title="Elige una hora" defaultValue={this.props.horaString}/>
                            </div>

                            {/* Lista de categorías */}
                            <div className="formulario-tarea">
                                <select className="menu-desplegable" id="categoria" defaultValue={this.state.categoriaSeleccionada} onChange={this.handleElegirCategoria} >
                                    
                                    <option><span role='img' aria-label='Emoji'>⚡</span> Nota rápida</option>
                                    <option><span role='img' aria-label='Emoji'>🔥</span> Emocionado</option>
                                    <option><span role='img' aria-label='Emoji'>🤷🏽‍♂️</span> Meh</option>
                                    <option><span role='img' aria-label='Emoji'>👨🏽‍💻</span> Trabajando</option>
                                    <option><span role='img' aria-label='Emoji'>🧠</span> Sólo pensando</option>
                                    <option>Otra categoría</option>
                                    
                                </select>
                                {/* Otra categoría */}
                                <div hidden={!this.state.otraCategoria} className="formulario-tarea">
                                    <input type="text" id="otraCategoria" name="otraCategoria" placeholder="Escribe una categoría" defaultValue={this.props.categoria}/>
                                </div>
                            </div>

                        </div>

                        {/* Progress bar */}
                        <progress hidden={!this.state.valorSubida} value={this.state.valorSubida} max="100" style={{ width: "50%", height: "0.5rem", display: "flex", margin: "0 auto", alignContent: "center" }}/>
                        <br/>

                        {/* Enviar */}
                        <button hidden={this.state.subidaExitosa} className="btn btn-secondary">{this.state.textoBotonSubirEntrada}</button>
                        <br />
                    
                    </form>

                    <br/>

                    {/* Progress bar de entrada enviada */}
                    <div hidden={!this.state.subidaExitosa} style={{ width: "100%", display: "block", margin: "0 auto 50px", alignContent: "center" }}>
                        <label className='span-centrado-simple'>¡Entrada subida con éxito!</label><br/>
                        <Link className='link-simple' to="/"><big>Regresar</big></Link>
                    </div>

                    <Snackbar theme={this.props.theme} mensaje={this.state.textoMensaje}/>
                                
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeCrearPost;