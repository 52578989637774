export default class Mencion {
    
    constructor ( importancia, date, logotipo, autor, descripcionAutor, cita, urlCita ) {

        this.importancia = importancia;
        this.date = date;
        
        this.logotipo = logotipo;
        this.autor = autor;
        this.descripcionAutor = descripcionAutor;
        this.cita = cita;
        this.urlCita = urlCita;
    }

    toString() {
        return this.importancia + ', ' + this.date + ', ' + 
        this.logotipo + ', ' + this.autor + ', ' + this.descripcionAutor + ', ' + 
        this.cita + ', ' + this.urlCita;
    }

    // Firestore data converter
    mencionConverter = {
        toFirestore: (mencion) => {
            return {

                importancia: mencion.importancia,
                date: mencion.date,
                
                logotipo: mencion.logotipo,
                autor: mencion.autor,
                descripcionAutor: mencion.descripcionAutor,
                
                cita: mencion.cita,
                urlCita: mencion.urlCita
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Mencion(data.importancia, data.date, 
                data.logotipo, data.autor, data.descripcionAutor,
                data.cita, data.urlCita);
        }
    }
}