import React from 'react';

import '../global.css';

// Objetos personales
import BadgeCrearPost from '../componentes/SesionIniciada/BadgeCrearPost.js';

// Imagen de portada provisional
// const banner = "https://images.unsplash.com/photo-1585854467604-cf2080ccef31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1773&q=80";

// UID del dueño
// const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";


// ------------------------


class CrearPost extends React.Component{



    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                <div id="visor" theme={this.props.theme}>
                    <BadgeCrearPost
                        theme={this.props.theme}

                        postNuevo = {true}                        
                        idReferencia = {""}
                        idSubPagina = {""}

                        urlPublica = {""}
                        autor = {"AK4rKU9fp3cP8dAcSd4VwJVOgfc2"}
                        horaString= {""}
                        fechaString= {""}
                        privacidad = {""}

                        categoria = {""}
                        portada = {""}
                        titulo = {""}
                        subtitulo = {""}
                        texto = {""}

                        // Analítica
                        noComments = {0}
                        noReposts = {0}
                        noLikes = {0}
                        noShared = {0}
                        noSaved = {0}
                        noViews = {0}
                    />
                </div>

            </React.Fragment>


        );
    }
}

export default CrearPost;