export default class Usuario {

    constructor (admin, suscrito, verificado, 
        nombrePublico, nombreUsuario, puesto, biografia, rutaFotoPerfil, 
        redFacebook, redInstagram, redLinkedin, redTwitter) {

        this.admin = admin; // true admin, false normal 
        this.suscrito = suscrito;
        this.verificado = verificado;
        this.nombrePublico = nombrePublico;
        this.nombreUsuario = nombreUsuario;

        this.puesto = puesto;
        this.biografia = biografia;
        this.rutaFotoPerfil = rutaFotoPerfil;

        this.redFacebook = redFacebook;
        this.redInstagram = redInstagram;
        this.redLinkedin = redLinkedin;
        this.redTwitter = redTwitter;
    }
    
    toString() {
        return this.tipo + ', ' + this.suscrito + ', ' + this.verificado + ', ' + 
        this.nombrePublico + ', ' + this.nombreUsuario + ', ' + 
        this.puesto + ', ' + this.biografia + ', ' + this.rutaFotoPerfil + ', ' +
        this.redFacebook + ', ' + this.redInstagram + ', ' + this.redLinkedin + ', ' + this.redTwitter;
    }

    // Firestore data converter
    usuarioConverter = {
        toFirestore: (usuario) => {
            return {

                admin: usuario.admin,
                suscrito: usuario.suscrito,
                verificado: usuario.verificado,

                nombrePublico: usuario.nombrePublico,
                nombreUsuario: usuario.nombreUsuario,
                
                puesto: usuario.puesto,
                biografia: usuario.biografia,
                rutaFotoPerfil: usuario.rutaFotoPerfil,

                redFacebook: usuario.redFacebook,
                redInstagram: usuario.redInstagram,
                redLinkedin: usuario.redLinkedin,
                redTwitter: usuario.redTwitter
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Usuario(data.admin, data.suscrito, data.verificado, 
                data.nombrePublico, data.nombreUsuario,
                data.puesto, data.biografia, data.rutaFotoPerfil, 
                data.redFacebook, data.redInstagram, data.redLinkedin, data.redTwitter);
        }
    }

}