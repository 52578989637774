import React from 'react'
import { Link } from 'react-router-dom';

import '../global.css';

// Objetos personales
// import { App as NavbarPublicar } from '../componentes/SesionIniciada/NavbarPublicar.js';
import NavbarPublicar from '../componentes/SesionIniciada/NavbarPublicar.js';
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
// import Post from '../componentes/SesionIniciada/ObjetoPost.js';
// import BadgePosts from '../componentes/SesionIniciada/BadgePosts.js';

// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
// import { getFirestore, collection, getDocs, query, /* where, */ orderBy, limit } from "firebase/firestore";
import { getFirestore, getDoc, doc } from "firebase/firestore";

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

class Menu extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // const user = auth.currentUser;
        mUid = auth.currentUser.uid;
        // console.log("UID: "+mUid);


        // Se muestran datos provisionales
        this.state = {

            mensajeTareas: "",
            idReferencia: "",
            ListaPosts:[]
        };

        // mUsuario = this.props.mUsuario;
        // console.log(this.props.nombrePublico);

        // Si mUsuario NO TIENE datos (se solicitan a la BD)
        if (mUsuario.nombrePublico === ""){

            mUid = auth.currentUser.uid;
            const ref = doc(db, "Usuarios", mUid).withConverter(Usuario.usuarioConverter);
            const docSnap =  getDoc(ref);

            // Se piden los datos
            docSnap.then((snapshot) => {
                
                // Se rellenan los datos
                mUsuario = snapshot.data();
                    
                return Promise.resolve(docSnap);
            });

        }


        // Handles's
        
    }

    // ENTRADAS --------------------


    /* Eventos */
        

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (

            <React.Fragment>

                {/* Últimas entradas del blog */}
                <div className="header-pantalla-completa-sin-bordes" theme={this.props.theme}>

                        {/* Acceso rápido para crear una entrada */}
                        <NavbarPublicar/>

                        

                </div>

                <br/>
                
                {/* Número de entradas */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    <Link to='/blog'>- Ver todas las publicaciones -</Link>
                </div>

                <br/>


            </React.Fragment>


        );
    }

}

export default Menu;