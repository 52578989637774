import React from "react";
import ReactDOM from 'react-dom';

import "../global.css";
import TarjetaPerfil from "../componentes/TarjetaPerfil.js";

// Objetos personales
import Usuario from '../componentes/SesionIniciada/ObjetoUsuario.js';
import Tarea from '../componentes/SesionIniciada/ObjetoTarea.js';
import Siguiendo from '../componentes/SesionIniciada/ObjetoSiguiendo.js';

// Importamos los componentes a Firebase
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore";
import { setDoc, getDoc, doc } from "firebase/firestore";

// Importamos los componentes de React-pdf
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
// import ReactPDF from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { PDFDownloadLink } from '@react-pdf/renderer';

import BadgeTareas from '../componentes/SesionIniciada/BadgeComentarios.js';
import imagenPrincipal from '../imagenes/iconos/icon_iniciar_sesion_256px.png';

// ------------------------

const auth = getAuth();
const db = getFirestore();

var mUid;

// Lo que extraemos de la URL para buscar al Usuario
var uidUsuario = "";
var nameUsuario = "";

var numeroFans = 0;
var mensajeFans = "";

// Sobre las tareas programadas
var numeroTareas = 0;
var mensajeNumeroTareas = "";

// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

var fechaString = "";
// var horaString = ""; // Se maneja dentro de la lista
var nombrePublico = "";
var nombreUsuario = "";

// Sobre el filtro de días
var date = new Date();
var diaAnterior = new Date();
var diaActual = new Date();
var diaSiguiente = new Date();

// Variable de tipo Usuario.class
var mUsuario = new Usuario('','','','','','','','');

// ------------------------

class PerfilExterno extends React.Component {

  /* Datos iniciales */
  constructor(props) {
    super(props);

    const user = auth.currentUser;

    // Días del filtro
    diaAnterior.setDate(date.getDate()-1);
    diaSiguiente.setDate(date.getDate()+1);

    const diaAnteriorString = diaAnterior.toLocaleDateString("es-MX", formatoFecha);
    const diaSiguienteString = diaSiguiente.toLocaleDateString("es-MX", formatoFecha);

    // Se muestran datos provisionales
    this.state = {
      verificado: false,

      nombrePublico: "",
      nombreUsuario: "cargando...",

      biografia: mUsuario.biografia,
      rutaFotoPerfil: imagenPrincipal,

      clavePais: mUsuario.clavePais,
      numeroTelefono: mUsuario.numeroTelefono,
      correo: user.email,

      mostrarBio: false,
      mostrarNumero: false,
      mostrarCorreo: false,

      mensajeFans: "",
      mensajeBotonSeguir: "Agregar contacto",

      mensajeComentarios: "",
      idReferencia: "",
      ListaTareas:[],

      diaAnterior: diaAnteriorString,
      diaActual: "Hoy",
      diaSiguiente: diaSiguienteString,
    };

    // Obtenemos nuestra UID
    mUid = auth.currentUser.uid;

    // Se obtiene el Nombre de Usuario de la URL
    const urlPadre = this.props.match.url;
    nameUsuario = urlPadre.replace("/", "");
    // console.log(nameUsuario);

    // mUsuario viene SIN datos, así que se hace una consulta
    const referenciaUsuarios = collection(db, "Usuarios");

    // Create a query against the collection.
    const q = query(referenciaUsuarios, where("nombreUsuario", "==", nameUsuario)).withConverter(Usuario.usuarioConverter);
    const querySnapshot = getDocs(q);

    querySnapshot
    .then((snapshot) => {
        snapshot.forEach((doc) => {
          // doc.data() is never undefined for query doc snapshots

          uidUsuario = doc.id;
          mUsuario = doc.data();

          // Se ejecuta cuando se comprueba que el usuario existe
          this.getListaFans();
          this.getListaTareas(date);

          // Si mUsuario ya tiene foto de perfil
          if (mUsuario.rutaFotoPerfil !== "") {
            this.setState(() => {
              return {
                verificado: mUsuario.verificado,
                nombrePublico: mUsuario.nombrePublico,
                nombreUsuario: mUsuario.nombreUsuario,

                biografia: mUsuario.biografia,
                rutaFotoPerfil: mUsuario.rutaFotoPerfil,

                clavePais: mUsuario.clavePais,
                numeroTelefono: mUsuario.numeroTelefono,
                correo: user.email,
              };
            });
          } else {
            // Si mUsuario aún no tiene foto de perfil

            this.setState(() => {
              return {
                verificado: mUsuario.verificado,
                nombrePublico: mUsuario.nombrePublico,
                nombreUsuario: mUsuario.nombreUsuario,

                biografia: mUsuario.biografia,
                rutaFotoPerfil: imagenPrincipal, // se muestra una provisional

                clavePais: mUsuario.clavePais,
                numeroTelefono: mUsuario.numeroTelefono,
                correo: user.email,
              };
            });
          }

          // Visibilidad de elementos --------

          var bio = mUsuario.biografia;
          var num = mUsuario.numeroTelefono;
          var mail = user.email;
      
          // Visibilidad de la biografía
          if(bio !== "") {

              this.setState({
                  mostrarBio: true,
              });
          }

          // Visibilidad del número de teléfono
          if(num !== "") {

              this.setState({
                  mostrarNumero: true,
              });
          }

          // Visibilidad del correo
          if(mail !== ""){

              this.setState({
                  mostrarCorreo: true,
              });
          }

          // -----------

        });

        if (snapshot.empty) {
          //No se devuelve un Objeto Usuario
          uidUsuario = "";
          // nameUsuario = "";
          this.setState(() => {
            return {
              verificado: false,
              nombrePublico: "Error 404!",
              nombreUsuario: nameUsuario,

              biografia: `Al parecer @${nameUsuario} aún no existe ❌`,
              rutaFotoPerfil: imagenPrincipal, // se muestra una provisional

              clavePais: mUsuario.clavePais,
              numeroTelefono: mUsuario.numeroTelefono,
              correo: user.email,
            };
          });
        }

        return Promise.resolve(snapshot);
    })
    .catch((error) => {
        // An error happened.
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(
          "No se pudo realizar la búsqueda: " +
            errorCode +
            "\n\n" +
            errorMessage
        );
    });

    //----------------------------------------------------------------------------

    // Handles's
    this.handleSeguirUsuario = this.handleSeguirUsuario.bind(this);
    this.handleMensajeSobreFans = this.handleMensajeSobreFans.bind(this);
    this.handleActualizarPagina = this.handleActualizarPagina.bind(this);
    this.handleCompartir = this.handleCompartir.bind(this);
    this.handleCrearPDF = this.handleCrearPDF.bind(this);

    this.handleAgregarTarea = this.handleAgregarTarea.bind(this);
    this.handleMensajeSobreTareas = this.handleMensajeSobreTareas.bind(this);

    this.handleDiaAnterior = this.handleDiaAnterior.bind(this);
    this.handleDiaSiguiente = this.handleDiaSiguiente.bind(this);
  }

  /* Se ejecuta cuando se comprueba que el usuario existe */

  // FANS ------------------------
  async getListaFans() {

    const referenciaFans = collection(db, `Fans/${uidUsuario}/Personas`);

    // Create a query against the collection.
    const q = query(referenciaFans).withConverter(Siguiendo.siguiendoConverter);
    const querySnapshot = getDocs(q);

    // Obtenemos cuántas personas siguen esta cuenta
    numeroFans = (await querySnapshot).size;

    // Dependiendo del número es lo que se muestra
    if (numeroFans === 0) {
      mensajeFans = `Aún no hay personas que agreguen a este usuario. Sé el primero.`;
      this.setState(() => {
        return {
          mensajeFans: mensajeFans,
        };
      });
    } else if (numeroFans === 1) {
      mensajeFans = `${numeroFans} persona lo ha agregado como contacto`;
      this.setState(() => {
        return {
          mensajeFans: mensajeFans,
        };
      });
    } else {
      mensajeFans = `${numeroFans} personas lo han agregado como contacto`;
      this.setState(() => {
        return {
          mensajeFans: mensajeFans,
        };
      });
    }
  }

  // TAREAS ----------------------
  async getListaTareas(dateInteres){
        
    // Creamos una lista local
    let lista = [];
    let listaCorta = [];

    const referenciaComentarios = collection(db, `Tareas/${uidUsuario}/Mensaje`);

    // Create a query against the collection.
    const q = query(referenciaComentarios, orderBy("date", "asc")).withConverter(Tarea.tareaConverter);
    const querySnapshot = getDocs(q);

    // ---------------------

    querySnapshot
    .then((snapshot) => {

            snapshot.forEach((documento) => {

                // doc.data() is never undefined for query doc snapshots
                
                // console.log(doc.id, " => ", doc.data());

                let idReferencia = documento.id;
                // var objTarea = doc.data();

                let uid = documento.data().uid;
                let date = documento.data().date;
                let texto = documento.data().texto;
                let leido = documento.data().leido;

                // Convertimos Timestamp a Date
                const dateTarea = date.toDate();

                // ------------
                // https://stackoverflow.com/questions/52247445/how-do-i-convert-a-firestore-date-timestamp-to-a-js-date

                // Example: Friday Nov 27 2017
                // const date = dateTarea.toDateString();

                // Example: 01:10:18 AM, the locale part 'en-US' is optional
                // const time = dateTarea.toLocaleTimeString('en-US');
                // ------------

                /* NECESARIO para el PDF */

                // "día, fecha de mes de año"
                fechaString = dateTarea.toLocaleDateString("es-MX", formatoFecha);
                // "hora:minuto am/pm"
                var horaString = dateTarea.toLocaleTimeString("es-MX", formatoHora);                    


                // Si coincide la Fecha de la Tarea con la Fecha de Interés
                if ( dateTarea.getDate() === dateInteres.getDate() && dateTarea.getMonth() === dateInteres.getMonth() && dateTarea.getFullYear() === dateInteres.getFullYear() ){
                
                  // Creamos un objeto local
                  let objCorto = { idReferencia };

                  // Agregamos el objeto local a la lista local
                  listaCorta.push(objCorto);

                      var objetoUsuario = new Usuario('','','','','','','','');
                      const ref = doc(db, "Usuarios", uid).withConverter(objetoUsuario.usuarioConverter);
                      const docSnap =  getDoc(ref);

                      // Se piden los datos
                      docSnap.then((snapshot) => {
                                                  
                          objetoUsuario = snapshot.data();
                          nombrePublico = objetoUsuario.nombrePublico;
                          nombreUsuario = objetoUsuario.nombreUsuario;

                          // Creamos un objeto local
                          let objeto = {idReferencia, uid, date, texto, leido, horaString, nombrePublico, nombreUsuario };

                          // Agregamos el objeto local a la lista local
                          lista.push(objeto);

                          // Se actualiza el estado
                          this.setState(() => {
                              return {
                                  idReferencia: idReferencia,
                                  ListaTareas: lista
                              }
                          });
                      });
      

                } else { // Si no coincide, se limpian la vista

                    //No se devuelve un Objeto Tarea
                    idReferencia = "";

                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaTareas: lista
                        }
                    });
                }

                // Obtenemos el número de tareas para la fecha de interés
                // numeroTareas = lista.length;
                numeroTareas = listaCorta.length;

                // Dependiendo del número es lo que se muestra
                if(numeroTareas === 0){

                    mensajeNumeroTareas = `No tienes tareas programadas para este día`;
                    this.setState(() => {                    
                        return {
                            mensajeTareas: mensajeNumeroTareas,
                        }
                    });

                } else if (numeroTareas === 1){

                    mensajeNumeroTareas = `Tienes 1 tarea programada`;
                    this.setState(() => {                    
                        return {
                            mensajeTareas: mensajeNumeroTareas,
                        }
                    });

                } else {

                    mensajeNumeroTareas = `${numeroTareas} tareas programadas`;
                    this.setState(() => {                    
                        return {
                            mensajeTareas: mensajeNumeroTareas,
                        }
                    });
                }

            });

            if(snapshot.empty){

                //No se devuelve un Objeto Tarea
                this.setState(() => {
                            
                    return {
                        idReferencia: "",
                        ListaTareas: lista
                    }
                });

            }

    })
    .catch((error) => {
            // An error happened.
            const errorCode = error.code;
            const errorMessage = error.message;

            console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
    });

  }

  // EVENTOS ---------------------


  handleMensajeSobreFans = (e) => {

    e.preventDefault();

    const mensaje = `Personas que han agregado a ${mUsuario.nombrePublico} como contacto`;
    alert(mensaje);
  };

  handleSeguirUsuario = (e) => {
    e.preventDefault();

    // const comentarioNuevo = e.target.elements.comentario.value;
    const uidDeUsuario = uidUsuario;

    if (uidDeUsuario !== "") {
      // Si obtengo una uid existente

      onAuthStateChanged(auth, (user) => {
        if (user) {
          mUid = auth.currentUser.uid;

          // Aquí agregamos la estructura Siguiendo > mUid > objetoUsuarioSimple { uid, date }
          // console.log("Siguiendo a: " + uidDeUsuario);
          
          // Misma hora para ambos
          const date = new Date();

          async function funcSeguir() {

            var objSiguiendo = new Siguiendo(uidDeUsuario, date);
            
            const ref = doc(db,`Siguiendo/${mUid}/Personas`, uidDeUsuario).withConverter(objSiguiendo.siguiendoConverter);
            setDoc(ref, objSiguiendo);

            const docSnap = getDoc(ref);
            return Promise.resolve(docSnap);
          }

          async function funcAgregarFan() {

            var objFan = new Siguiendo(mUid, date);
            
            const ref = doc(db,`Fans/${uidDeUsuario}/Personas`, mUid).withConverter(objFan.siguiendoConverter);
            setDoc(ref, objFan);

            const docSnap = getDoc(ref);
            return Promise.resolve(docSnap);
          }

          const mensaje = `Contacto agregado`;
          alert(mensaje);

          // alert("Este usuario se ha agregado a tu inicio");

          // Cambia el texto del botón
          this.setState(() => {
            return {
              mensajeBotonSeguir: "Contacto agregado",
            };
          });

          // Llamamos a las funciones
          funcSeguir();
          funcAgregarFan();

        } else {
          
          const mensaje = `Para agregar a este usuario, es necesario que inicies sesión`;
          // alert(mensaje);
          console.log(mensaje);

          // Cambia el texto del botón
          this.setState(() => {
            return {
              mensajeBotonSeguir: "Antes inicia sesión",
            };
          });
        }
      });
    } else {
      // Si no tengo una uid existente

      alert("Primero debes buscar una cuenta");
    }

  };

  handleActualizarPagina = (e) => {

      e.preventDefault();

      window.location.reload(); 
  }

  handleCompartir = (e) => {

    e.preventDefault();

    // Incluso para compartir solo un archivo, debe enviarse como una matriz de archivos.
    // const files = [new File([blob], 'image.png', { type: blob.type })];

    const datos = {
      title: 'AgendaPDF',
      text: ` | Agrega el perfil de trabajo de agendapdf.com/${mUsuario.nombreUsuario}`,
      // files,
    }

    // Si el navegador lo soporta y el dispositivo lo soporta
    if (navigator.canShare && navigator.canShare(datos)) {

      try {

        navigator.share(datos);

      } catch (err) {

        if (err.name !== 'AbortError') {
          console.error(err.name, err.message)
        }

      }

    } else { // El navegador no lo soporta o el dispositivo lo no soporta
      console.warn('El navegador no lo soporta o el dispositivo lo no soporta', datos);
    }
    
  };

  /* handleComentar = (e) => {
    e.preventDefault();

    const comentarioNuevo = e.target.elements.comentario.value;
    const uidDeUsuario = uidUsuario;

    if (uidDeUsuario !== "") {
      // Si obtengo una uid existente

      onAuthStateChanged(auth, (user) => {
        if (user) {
          mUid = auth.currentUser.uid;

          // Aquí agregamos la estructura Siguiendo > mUid > objetoUsuarioSimple { uid, date }
          // console.log("Siguiendo a: " + uidDeUsuario);

          if (comentarioNuevo !== null && comentarioNuevo !== "") {
            const date = new Date();

            const mensajeFecha =
              "_fecha_" +
              date.getFullYear() +
              "_" +
              (date.getMonth() + 1) +
              "_" +
              date.getDate() +
              "_" +
              date.getHours() +
              "_" +
              date.getMinutes() +
              "_" +
              date.getSeconds();
            const nuevoMensaje = "autor_" + mUid + mensajeFecha;
            // console.log(nuevoMensaje);

            const ref = doc(
              db,
              `Tareas/${uidDeUsuario}/Mensaje`,
              nuevoMensaje
            ).withConverter(comentarioConverter);
            setDoc(
              ref,
              new Comentario(mUid, serverTimestamp(), comentarioNuevo, false)
            );

            // Cambia el texto del botón
            this.setState(() => {
              return {
                mensajeBotonAgregarTarea: "Enviado",
              };
            });

            alert("Tu tarea se ha asignado");

            const docSnap = getDoc(ref);
            return Promise.resolve(docSnap);
          } else {
            const mensaje = `Asigna una tarea a este usuario`;
            alert(mensaje);
          }
        } else {
          const mensaje = `Para asignar una tarea a este usuario, es necesario que inicies sesión`;
          // alert(mensaje);
          console.log(mensaje);

          // Cambia el texto del botón
          this.setState(() => {
            return {
              mensajeBotonAgregarTarea: "Antes inicia sesión",
            };
          });
        }
      });
    } else {
      // Si no tengo una uid existente
      alert("Primero debes buscar una cuenta");
    }
  }; */

  handleCrearPDF = (e) => {

      e.preventDefault();

      // Si la lista de tareas está vacía
      if(numeroTareas === 0){

          const mensaje = `No se puede crear un PDF porque no tienes tareas programadas para esta fecha.`;
          alert(mensaje);

      } else { // Si no

          /* Estilos de fuentes */
          const styles = StyleSheet.create({
              page: { flexDirection: 'row', backgroundColor: '#fff' },
              section: { margin: 10, padding: 10, flexGrow: 1 },

              titulo: { marginTop: 10, paddingTop: 20, color: '#0071e3', backgroundColor: '#e7e7e750', textAlign: 'center', fontSize: '23'},
              subtitulo: { marginBottom: 10, paddingBottom: 20, backgroundColor: '#e7e7e750', textAlign: 'center', fontSize: '13'  },
              metadatos: { marginTop: 20, marginBottom: 10, fontSize: '18'  },
              tarea: { marginVertical: 5, fontSize: '14' },
              autor: { marginVertical: 5, fontSize: '10' },

              responsable: { textAlign: 'right', marginTop: 20, marginBottom: 10, color: '#0071e3', fontSize: '11' },

              pie: { marginHorizontal: 0, marginBottom: 10, padding: 10, color: '#0071e3', backgroundColor: '#e7e7e750', fontSize: '14' },
              empresa: { textAlign: 'left', marginBottom: -8 },
              firma: { textAlign: 'right', marginTop: -8 }
          });

          /* Creamos la vista del PDF */
          const MyDocument = () => (
              <Document id="visor">
                  <Page size="A4" style={styles.page}>
                      <View style={styles.section}>
                          
                          <Text style={styles.titulo}>Programa de actividades</Text>
                          <Text style={styles.subtitulo}>{"\n" + fechaString}</Text>
                          <Text style={styles.metadatos}>{"Tareas pendientes:\n\n"}</Text>
                          
                          {this.state.ListaTareas.map((tarea) => (

                              <div>
                                  <Text style={styles.tarea}>
                                  (  )  {tarea.horaString} • {tarea.texto}
                                  </Text>
                                  <Text style={styles.autor}>
                                  {"Por @" + tarea.nombreUsuario + "\n\n\n"}
                                  </Text>
                              </div>

                          ))}

                          <Text style={styles.responsable}>Agenda de {this.state.nombrePublico}</Text>
                          <div style={styles.pie}>
                              <Text style={styles.empresa}>AgendaPDF.com</Text>
                              <Text style={styles.firma}>Desarrollado por Fortín Tech</Text>
                          </div>
                          
                      </View>
                  </Page>
              </Document>
          );

          /* Reemplazamos el div "visor" */
          const App = () => (
              <PDFViewer className="visor-pdf-pantalla-completa">
                  <MyDocument />
              </PDFViewer>
          );
          ReactDOM.render(<App />, document.getElementById('visor'));
          

          // Cargamos una URL de descarga
          const Descargar = () => (
              <div>
                <PDFDownloadLink document={<MyDocument />} fileName={"Tareas del " + fechaString + ".pdf"}>
                  {({ blob, url, loading, error }) =>
                    loading ? 'Procesando...' : 'Descargar'
                  }
                </PDFDownloadLink>
              </div>
          );
          ReactDOM.render(<Descargar />, document.getElementById('descargar'));

      }

  }

  handleAgregarTarea = (e) => {
      e.preventDefault();

      const tareaNueva = e.target.elements.tarea.value;
      const fechaNueva = e.target.elements.fecha.value;
      const horaNueva = e.target.elements.hora.value;

      const uidDeUsuario = uidUsuario;


      if (uidDeUsuario !== "") {
          // Si obtengo una uid existente

          onAuthStateChanged(auth, (user) => {
              if (user) {
                  mUid = auth.currentUser.uid;

                  // Aquí agregamos la estructura Siguiendo > mUid > objetoUsuarioSimple { uid, date }
                  // console.log("Siguiendo a: " + uidDeUsuario);


                  if (tareaNueva === null || tareaNueva === "") {
                      const mensaje = `Escribe una TAREA para programarla`;
                      alert(mensaje);

                  } else if (fechaNueva === null || fechaNueva === "") {
                      const mensaje = `Escoge una FECHA para programarla`;
                      alert(mensaje);

                  } else if (horaNueva === null || horaNueva === "") {
                      const mensaje = `Escoge una HORA para programarla`;
                      alert(mensaje);

                  } else {

                      // const date = new Date();
                      const fecha = new Date();
                      
                      // Separamos los datos de la fecha
                      const [anio, mes, dia] = fechaNueva.split("-");
                      const value1 = Number(anio);
                      const value2 = Number(mes) - 1; // Enero inicia en 0
                      const value3 = Number(dia);
                      fecha.setFullYear(value1);
                      fecha.setMonth(value2);
                      fecha.setDate(value3);
                      
                      // Separamos los datos de la hora
                      const [hora, minuto] = horaNueva.split(":");
                      const val1 = Number(hora);
                      const val2 = Number(minuto);
                      fecha.setHours(val1);
                      fecha.setMinutes(val2);

                      const mensajeFecha =
                      "_fecha_" +
                      fecha.getFullYear() +
                      "_" +
                      (fecha.getMonth()) +
                      "_" +
                      (fecha.getDate()) +
                      "_" +
                      fecha.getHours() +
                      "_" +
                      fecha.getMinutes() +
                      "_" +
                      fecha.getSeconds();

                      const idReferenciaString = "autor_" + mUid + mensajeFecha;

                      console.log(fecha);

                      const objTarea = new Tarea(mUid, fecha, tareaNueva, false);
                      const ref = doc( db, `Tareas/${uidDeUsuario}/Mensaje`, idReferenciaString).withConverter(objTarea.tareaConverter);
                      setDoc(ref, objTarea);

                      alert("¡Tarea agregada!");

                      // Reiniciamos los datos del formulario
                      var tareaValue = document.getElementById("tarea");
                      if (tareaValue.value !== "") {
                          tareaValue.value = "";
                      }

                      // La fecha no se reinicia para agilizar la creación de múltiples tareas

                      // Reiniciamos los datos de la hora
                      var horaValue = document.getElementById("hora");
                      if (horaValue.value !== "") {
                          horaValue.value = "";
                      }

                      const docSnap = getDoc(ref);
                      return Promise.resolve(docSnap);
                  }





              } else {
                  const mensaje = `Para programes una tarea es necesario que inicies sesión`;
                  // alert(mensaje);
                  console.log(mensaje);

                  // Cambia el texto del botón
                  this.setState(() => {
                      return {
                          mensajeBotonAgregarTarea: "Antes inicia sesión",
                      };
                  });
              }
          });

      } else {
          // Si no tengo una uid existente
          alert("Primero debes buscar una cuenta");
      }
  };

  handleMensajeSobreTareas = (e) => {

      e.preventDefault();

      const mensaje = `Si compartes tu perfil podrán agregarte tareas`;
      alert(mensaje);        
  }

  // FILTRO de días 

  handleDiaAnterior = (e) => {
      e.preventDefault();

      // Se resta 1 día menos
      diaAnterior.setDate(diaAnterior.getDate()-1);
      diaActual.setDate(diaActual.getDate()-1);
      diaSiguiente.setDate(diaSiguiente.getDate()-1);


      const diaAnteriorString = diaAnterior.toLocaleDateString("es-MX", formatoFecha);
      const diaActualString = diaActual.toLocaleDateString("es-MX", formatoFecha);
      const diaSiguienteString = diaSiguiente.toLocaleDateString("es-MX", formatoFecha);
      
      // Lógica de Textos en los botones
      if ( diaActual.getDate() === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString,
                  diaActual: "Hoy",
                  diaSiguiente: diaSiguienteString,
              };
          });

      } else if ( (diaActual.getDate()-1) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString + " (hoy)",
                  diaActual: diaActualString,
                  diaSiguiente: diaSiguienteString,
              };
          });
          
      } else if ( (diaActual.getDate()+1) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString,
                  diaActual: diaActualString,
                  diaSiguiente: diaSiguienteString + " (hoy)",
              };
          });
          
      } else {

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString,
                  diaActual: diaActualString,
                  diaSiguiente: diaSiguienteString,
              };
          });
          
      }

      // Obtenemos las tareas de la fecha de interés
      this.getListaTareas(diaActual);

      // Reseteamos la vista del PDF
      const App = () => (<div />);
      ReactDOM.render(<App />, document.getElementById('visor'));
  }

  handleDiaSiguiente = (e) => {
      e.preventDefault();

      // Se suma 1 día más
      diaAnterior.setDate(diaAnterior.getDate()+1);
      diaActual.setDate(diaActual.getDate()+1);
      diaSiguiente.setDate(diaSiguiente.getDate()+1);


      const diaAnteriorString = diaAnterior.toLocaleDateString("es-MX", formatoFecha);
      const diaActualString = diaActual.toLocaleDateString("es-MX", formatoFecha);
      const diaSiguienteString = diaSiguiente.toLocaleDateString("es-MX", formatoFecha);
      
      // Lógica de Textos en los botones
      if ( diaActual.getDate() === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString,
                  diaActual: "Hoy",
                  diaSiguiente: diaSiguienteString,
              };
          });

      } else if ( (diaActual.getDate()-1) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString + " (hoy)",
                  diaActual: diaActualString,
                  diaSiguiente: diaSiguienteString,
              };
          });
          
      } else if ( (diaActual.getDate()+1) === date.getDate() && diaActual.getMonth() === date.getMonth() && diaActual.getFullYear() === date.getFullYear() ){

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString,
                  diaActual: diaActualString,
                  diaSiguiente: diaSiguienteString + " (hoy)",
              };
          });
          
      } else {

          // Cambia el texto del botón
          this.setState(() => {
              return {
                  diaAnterior: diaAnteriorString,
                  diaActual: diaActualString,
                  diaSiguiente: diaSiguienteString,
              };
          });
          
      }

      // Obtenemos las tareas de la fecha de interés
      this.getListaTareas(diaActual);

      // Reseteamos la vista del PDF
      const App = () => (<div />);
      ReactDOM.render(<App />, document.getElementById('visor'));
  }

  // -----------------------------

  /* Lo que mostramos en pantalla */
  render() {

    return (

      <React.Fragment>

        <div className="header-pantalla-completa" theme={this.props.theme}>

            {/* Vista de perfil */}
            <div className="header-un-tercio-pantalla">

                <br />
                <div id="perfilExterno">
                    <TarjetaPerfil
                      theme={this.props.theme}

                        verificado={this.state.verificado}
                        nombrePublico={this.state.nombrePublico}
                        nombreUsuario={this.state.nombreUsuario}
                        rutaFotoPerfil={this.state.rutaFotoPerfil}
                        biografia={this.state.biografia}

                        clavePais={this.state.clavePais}
                        numeroTelefono={this.state.numeroTelefono}
                        correo={this.state.correo}
                                
                        mostrarBio={this.state.mostrarBio}
                        mostrarNumero={this.state.mostrarNumero}
                        mostrarCorreo={this.state.mostrarCorreo}
                    />
                </div>
                

                <span className="link-fans" onClick={this.handleMensajeSobreFans}>
                <b>{this.state.mensajeFans}</b>
                </span>

                <button className="btn btn-cerrar-sesion" onClick={this.handleSeguirUsuario} >
                {this.state.mensajeBotonSeguir}
                </button>
                <button className="btn btn-seguir-usuario" onClick={this.handleCompartir}>Compartir perfil</button>
            </div>

            {/* Formulario de Tarea Nueva */}
            <div className="header-dos-tercios-pantalla">

                {/* Intrucciones */}
                <br />
                <br />
                <span className="span-centrado-light">Intrucciones</span>
                <span>• Asigna tareas a este usuario (con fecha y hora).</span><br/>
                <span>• El usuario podrá ver todas las tareas pendientes que se le asignaron (e imprimirlas).</span><br/>
                <span>• Para cualquier duda, ponte en contacto con el usuario mediante WhatsApp, por llamada o por correo.</span><br/>

                {/* Formulario */}
                <form onSubmit={this.handleAgregarTarea} className="formulario">

                    <div className="formulario">
                        <label></label>
                        <textarea className="input-tarea" type="text" maxLength={2000} id="tarea"
                        name="tarea" placeholder="Escribe una tarea o pendiente"/>
                    </div>

                    <div className="formulario-tarea">
                        <label className="label-tarea">Fecha: </label>
                        <input type="date" name="fecha" id="fecha"/>
                    </div>
                    <div className="formulario-tarea">
                        <label className="label-tarea">Hora: </label>
                        <input type="time" name="hora" id="hora"/>
                    </div>

                    <button className="btn btn-login">Agregar tarea</button>
                    <br />

                </form>

                
            </div>


          
            {/* Filtro de días */}
            <div className="filtro-pantalla-completa">
                <button className="btn btn-filtro-dia" onClick={this.handleDiaAnterior} title="Ver un día antes">{this.state.diaAnterior}</button>
                <span className="span-filtro-dia" title="Vista actual">{this.state.diaActual}</span>
                <button className="btn btn-filtro-dia" onClick={this.handleDiaSiguiente} title="Ver siguiente día">{this.state.diaSiguiente}</button>
            </div>
                    
            {/* Tareas programadas */}
            <div className="header-pantalla-completa">

                {this.state.ListaTareas.map((tarea) => (

                    <BadgeTareas
                    key={tarea.id}

                    idItem={`ID${tarea.idReferencia}`}

                    // idCreador={mUid}
                    uidPersona={uidUsuario}
                    idReferencia={tarea.idReferencia}

                    uid={tarea.uid}
                    date={tarea.date}
                    texto={tarea.texto}
                    leido={tarea.leido}
                            
                    horaString={tarea.horaString}
                    nombrePublico={tarea.nombrePublico}
                    nombreUsuario={tarea.nombreUsuario} />

                ))}

            </div>

            {/* Botón para generar PDF */}
            <span>{this.state.mensajeTareas}</span> <button className="btn btn-generar-pdf" onClick={this.handleCrearPDF}>Generar PDF</button><span id="descargar"/>
            <br/><br/>

            {/* Visor PDF */}
            <div className="visor-pdf-pantalla-completa" id="visor"/>


        </div>
        
      </React.Fragment>
    );
  }

}

export default PerfilExterno;
