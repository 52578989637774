import React from 'react';

import './style-textarea.css';

import iconEstilos from '../../../imagenes/iconos/icon-crear.svg';

import iconTexto from '../../../imagenes/iconos/icon-texto.svg';
import iconH1 from '../../../imagenes/iconos/icon-encabezado.svg';
import iconH2 from '../../../imagenes/iconos/icon-encabezado.svg';
import iconH3 from '../../../imagenes/iconos/icon-encabezado.svg';
import iconVHiper from '../../../imagenes/iconos/icon-hipervinculo.svg';
import iconVInterno from '../../../imagenes/iconos/icon-vinculo-interno.svg';
import iconVExterno from '../../../imagenes/iconos/icon-vinculo-externo.svg';
import iconImagen from '../../../imagenes/iconos/icon-imagen.svg';
import iconVideo from '../../../imagenes/iconos/icon-video.svg';
import iconDocumento from '../../../imagenes/iconos/icon-documento.svg';
import iconLista from '../../../imagenes/iconos/icon-lista.svg';
import iconDivisor from '../../../imagenes/iconos/icon-divisor.svg';
import iconCita from '../../../imagenes/iconos/icon-cita.svg';
import iconNota from '../../../imagenes/iconos/icon-nota.svg';
import iconCodigo from '../../../imagenes/iconos/icon-codigo.svg';
import iconDesplegable from '../../../imagenes/iconos/icon-desplegable.svg';

import SubPagina from '../../Objetos/ObjetoSubPagina.js';
import Snackbar from '../Snackbar/index.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { doc, deleteDoc } from "firebase/firestore";
import { getStorage, ref, deleteObject } from "firebase/storage";

// ------------------------

const db = getFirestore();
var objSubPagina = new SubPagina('','','','','','','','','','','','','','','');

// ------------------------

function toggleClass(elem, className){
    if (elem.className.indexOf(className) !== -1){
      elem.className = elem.className.replace(className,'');
    }
    else{
      elem.className = elem.className.replace(/\s+/g,' ') + 	' ' + className;
    }
  
    return elem;
}
  
function toggleMenuDisplay(e){
    const dropdown = e.currentTarget.parentNode;
    const menu = dropdown.querySelector('.menu');
  
    toggleClass(menu,'hide');
}
  
function handleOptionSelected(e){
    toggleClass(e.target.parentNode, 'hide');
}

// Cierra el menú si el usuario hace click fuera de este
/* window.onclick = function(event) {

    // const icon = dropdown.querySelector('.dropdown-icon');

    if (!event.target.matches('.dropdown-icon')) {
        
        // toggleMenuDisplay(event);
        handleOptionSelected(event);
    }
} */
  

class MenuTextAreaEstilos extends React.Component{

    constructor(props) {
        super(props);

        // Estado
        this.state = {
            textoMensaje: ""
        };

        this.showMenu = this.showMenu.bind(this);
        this.selectValue = this.selectValue.bind(this);
    }

    // EVENTOS ---------------

    /* Mostrar menú de opciones */
    showMenu = (e) => {
        e.preventDefault();
     
        toggleMenuDisplay(e);        
    }

    /* Valor seleccionado */
    selectValue = (e) => {
        e.preventDefault();

        handleOptionSelected(e);

        // const uidAutor = this.props.autor;
        const idElement = this.props.id;
        // const portada = this.props.portada;

        const valorMenu = e.target.id;
        // const urlStorage = e.target.urlStorage;
        // const texto = e.target.texto;

        // this.menuOpciones(uidAutor, idElement, portada, valorMenu);
        this.props.menuOpciones(idElement, valorMenu);
    }

    // META FUNCIONES ---------------

    /* Evento del Menú de Opciones (Dropdown) */
    menuOpciones(autor, numId, portada, valor){

        if (valor === "CopyText"){
            
            // Se copia el texto
            this.copiarTexto(numId);

        } else if (valor === "CopyURL"){
            
            // Se copia la URL
            this.copiarURL(numId);

        } else if (valor === "Delete"){
            
            // Se elimina
            this.borrarBloque(autor, numId, portada);
        }
    }

    // -----------------------------

    copiarTexto(numId){

        // Se copia el texto
        const elementoTexto = document.getElementById('texto-'+ numId).textContent;
    
        navigator.clipboard.writeText(elementoTexto)
        .then(() => {
                            
            // console.log("Text copied to clipboard...");
            // alert("¡Texto copiado al portapapeles!");
            
            // Se muestra un mensaje
            this.setState(() => {
                return {
                    textoMensaje: "Texto copiado al portapapeles"
                }
            });
    
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    }
    copiarURL(numId){
    
        // Se copia el texto
        const urlPost = "https://apeordi.com/post/" + numId;
    
        navigator.clipboard.writeText(urlPost)
        .then(() => {
                    
            // console.log("Text copied to clipboard...");
            // alert("¡Enlace copiado al portapapeles!");
    
            // Se muestra un mensaje
            this.setState(() => {
                return {
                    textoMensaje: "Enlace copiado al portapapeles"
                }
            });
        }).catch(err => {
            // console.log('Something went wrong', err);
            console.warn('El navegador no lo soporta o el dispositivo lo no soporta', err);
        });
    
    }
    borrarBloque(autor, numId, portada){
    
        // Se borra el documento
        const elementoTexto = document.getElementById('texto-'+numId).textContent;
    
        // Se solicita confirmación
        const mensaje = `⚠ ¿Desea eliminar el párrafo?\n\n"${elementoTexto}"`;
        const isOK = window.confirm(mensaje);
    
        if(isOK){
    
            this.borrarEnBaseDeDatos(autor, numId, portada);
        }
    }
    
    // -----------
    
    borrarEnBaseDeDatos(autor, numId, portada){
    
        // UID de la persona dueña de las Tareas
        // const uidPersona = this.props.autor;
    
        // UID de la persona que creó la Tarea
        // const uidDefault = this.props.uid;
    
        // Si existe una portada, se elimina
        if(portada !== ""){
    
            // Separamos para mostrar una URL más amigable
            const url = "Posts/" + autor + "/Estado/" + numId + "/portada";
    
            const storage = getStorage();
            const portadaRef = ref(storage, url);
    
            // Delete the file
            deleteObject(portadaRef)
            .then(() => {
    
                // Se elimina el post
                const refEntrada = doc( db, `Posts/${autor}/Estado/${numId}`).withConverter(objSubPagina.subPaginaConverter);
                deleteDoc(refEntrada);
    
                // Se borra la view del DOM
                this.borrarVisualmente(numId);
    
            }).catch((error) => {
                // Uh-oh, an error occurred!
                alert(`Ocurrió un error:\n\n ${error}`);
            });
    
    
        } else{
    
            // Se elimina el post
            const refEntrada = doc( db, `Posts/${autor}/Estado/${numId}`).withConverter(objSubPagina.subPaginaConverter);
            deleteDoc(refEntrada);
    
            // Se borra la view del DOM
            this.borrarVisualmente(numId);
        }
    }
    borrarVisualmente(numId){
    
        // Se borra de la lista
        const elementoPadre = document.getElementById('post-'+numId);
        elementoPadre.remove();
    
        // const mensaje = `Párrafo ${numId} eliminado`;
        // alert(mensaje);
    
        // Se muestra un mensaje
        this.setState(() => {
            return {
                textoMensaje: "Post eliminado"
            }
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render(){
        return(

            <div className='dropdown'>

                {/* Ícono de menú */}
                <img className="dropdown-icon" src={iconEstilos} alt='' title='Agregar estilo' onClick={this.showMenu}/>


                {/* Menú de opciones */}
                <div id={'menuEstilos-'+this.props.id} className="menu pointerCursor hide" onClick={this.selectValue}>
                    
                    <span>Predeterminado</span>
                    <div className='option' id='Texto' title='Texto simple. Sin formato.'><img src={iconTexto} alt=""/>Texto</div>
                    <span>Títulos</span>
                    <div className='option' id='H1' title='Encabezado grande.'><img src={iconH1} alt=""/>Encabezado 1</div>
                    <div className='option' id='H2' title='Encabezado mediano.'><img src={iconH2} alt=""/>Encabezado 2</div>
                    <div className='option' id='H3' title='Encabezado pequeño.'><img src={iconH3} alt=""/>Encabezado 3</div>
                    <span>Vínculos</span>
                    <div className='option' id='VHiper' title='Hipervínculo para llevarte a un bloque (ideal para glosarios).'><img src={iconVHiper} alt=""/>Referencia</div>
                    <div className='option' id='VInterno' title='Vínculo dentro de apeordi.com'><img src={iconVInterno} alt=""/>Interno</div>
                    <div className='option' id='VExterno' title='Vínculo de otro sitio (fuera de apeordi.com).'><img src={iconVExterno} alt=""/>Externo</div>
                    <span>Multimedia</span>
                    <div className='option' id='Imagen' title='Inserta una imagen (Instagram, Unsplash, etc.).'><img src={iconImagen} alt=""/>Imagen</div>
                    <div className='option' id='Video' title='Inserta un video (YouTube, TikTok, etc.).'><img src={iconVideo} alt=""/>Video</div>
                    <div className='option' id='Documento' title='Inserta un documento (PDF, Word, etc.).'><img src={iconDocumento} alt=""/>Documento</div>
                    <span>Otros</span>
                    <div className='option' id='Lista' title='Crea una lista.'><img src={iconLista} alt=""/>Lista</div>
                    <div className='option' id='Divisor' title='Separa dos bloques con una línea horizontal.'><img src={iconDivisor} alt=""/>Divisor</div>
                    <div className='option' id='Cita' title='Señala una cita.'><img src={iconCita} alt=""/>Cita</div>
                    <div className='option' id='Nota' title='Destaca un texto para que sobre salga.'><img src={iconNota} alt=""/>Nota</div>
                    <div className='option' id='Codigo' title='Destaca un fragmento de código.'><img src={iconCodigo} alt=""/>Código</div>
                    <div className='option' id='Desplegable' title='Ordena secciones de textos con menús desplegables.'><img src={iconDesplegable} alt=""/>Desplegable</div>
                    
                </div>

                <Snackbar theme={this.props.theme} mensaje={this.state.textoMensaje}/>

            </div>

        );

    }

}

export default MenuTextAreaEstilos;