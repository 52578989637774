import React from 'react';
import { Link } from 'react-router-dom';

import "../global.css";
import "./estilos/TarjetaAutor.css";
// import header from '../imagenes/iconos/icon_mybio_fans_512px.png';

//Iconos importados
import iconoFacebook from '../imagenes/iconos/icon-facebook.svg';
import iconoInstagram from '../imagenes/iconos/icon-instagram.svg';
import iconoLinkedin from '../imagenes/iconos/icon-linkedin.svg';
import iconoTwitter from '../imagenes/iconos/icon-twitter.svg';

// import iconFacebook from '../imagenes/iconos/icon-facebook.svg';
// import iconTelegram from '../imagenes/iconos/icon-telegram.svg';
// import iconPaypal from '../imagenes/iconos/icon-paypal.svg';

import iconCuentaVerificada from '../imagenes/iconos/icon_usuario_verificado_128px.png';


class TarjetaAutor extends React.Component{

    constructor(props) {
        super(props);
    
        this.handleMensajeCuentaVerificada = this.handleMensajeCuentaVerificada.bind(this);
    }


    // EVENTOS ---------------

    handleMensajeCuentaVerificada = (e) => {
        e.preventDefault();

        const mensaje = `Esta cuenta ha comprobado que pertenece a alguien con influencia en internet (o en la vida real)`;
        alert(mensaje);        
    }

    
    render(){
        

        return(

            <div className="TarjetaAutor">

                <div className="TarjetaAutor__header">
                    <img className="TarjetaAutor__header-icon-verificado" src={iconCuentaVerificada} hidden={!this.props.verificado} onClick={this.handleMensajeCuentaVerificada} alt="Estado de cuenta verificada"/>
                </div>

                <div className="TarjetaAutor__badge">

                    <Link to="/blog"><h3 className="TarjetaAutor__section-name"><img className="TarjetaAutor__avatar" src={this.props.rutaFotoPerfil} alt="Imagen del autor"/>{this.props.nombrePublico}</h3></Link>

                    <span><b>{this.props.puesto}</b></span><br/>
                    <span>{this.props.biografia}</span><br/>
                    <div className="TarjetaAutor__list-icons">
                        <a hidden={!this.props.facebook} href={"https://facebook.com/"+this.props.facebook} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoFacebook} alt="Red social" title='Facebook'/></a>
                        <a hidden={!this.props.instagram} href={"https://instagram.com/"+this.props.instagram} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoInstagram} alt="Red social" title='Instagram'/></a>
                        <a hidden={!this.props.linkedin} href={"https://linkedin.com/in/"+this.props.linkedin} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoLinkedin} alt="Red social" title='LinkedIn'/></a>
                        <a hidden={!this.props.twitter} href={"https://twitter.com/"+this.props.twitter} target="_blank" rel="noopener noreferrer"><img className="icon-footer" src={iconoTwitter} alt="Red social" title='Twitter'/></a>
                    </div>
                    <br/>
                    <br/>
                    <br/>
                    <span><small>Para propuestas de trabajo o ideas, déjame un mensaje. <i>Atiendo personalmente cada mensaje que envía</i>.</small></span><br/>

                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default TarjetaAutor;