import React from 'react';

import "../../global.css";
import "./estilos/BadgeLeerEntrada.css";

//Iconos importados
import iconCuentaVerificada from '../../imagenes/iconos/icon_usuario_verificado_128px.png';

// ------------------------

class BadgeLeerEntrada extends React.Component{

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        // console.log(idReferencia);

        // const mUid = this.props.autor;

        this.handleCompartir = this.handleCompartir.bind(this);
    }

    /* Eventos */
    handleCompartir = (e) => {
        e.preventDefault();

        /* const tiempoServidor = (this.props.date);
        const dateTemporal = new Date(tiempoServidor*1000);
        const dateString = dateTemporal.getDate()+"-"+(dateTemporal.getMonth()+1) +
        " " +
        dateTemporal.getHours()+"-"+dateTemporal.getMinutes() +"hrs";

        const nombreImagen = `Comentario anónimo ${dateString}`;

        const llave = (this.props.idItem);
        var node = document.getElementById(llave);

        htmlToImage.toPng(node).then(function (blob) {

            download(blob, `${nombreImagen}.png`);
            
        }); */

    }

    
    /* Lo que mostramos en pantalla */
    render(){

        return(

            
            <div className="BadgeLeerEntrada" theme={this.props.theme}>

                {/* Portada */}
                <div className="header-portada">

                    {/* Imagen de Portada*/}
                    <img className="BadgeLeerEntrada-imagen-portada" src={this.props.portada} alt="Imagen de portada" />

                    {/* Título y Subtítulo*/}
                    <div className="header-contenido-portada">
                        <h1>{this.props.titulo}</h1>
                        <h2>{this.props.subtitulo}</h2>

                        {/* Categoría */}
                        <div hidden={!this.props.categoria} className="BadgeLeerEntrada-categoria" id={this.props.idReferencia + "_div"} title="Categoría">
                            {this.props.categoria}
                        </div>
                    </div>

                </div>

                {/* Hora y Fecha */}
                <span className="BadgeLeerEntrada-fecha">{this.props.fechaString} • {this.props.horaString}</span>

                {/* Categoría */}
                {/* <div hidden={!this.props.categoria} className="BadgeLeerEntrada-categoria" id={this.props.idReferencia + "_div"} title="Categoría">
                    {this.props.categoria}
                </div>
                */}

                {/* Firma del autor */}
                <div className="BadgeLeerEntrada-firma">

                    <img className='BadgeLeerEntrada-firma-avatar' src={this.props.rutaFotoPerfil} alt="Imagen del autor"/>
                    <div>
                        <span className='BadgeLeerEntrada-firma-nombre'>{this.props.nombrePublico}</span>
                        <img className='BadgeLeerEntrada-firma-verificado' src={iconCuentaVerificada} hidden={!this.props.verificado} alt="Estado de cuenta verificada"/>
                        <span className='BadgeLeerEntrada-firma-usuario'>@{this.props.nombreUsuario}</span>
                    </div>
                </div>

                {/* Texto */}
                {/* <textarea className="BadgeLeerEntrada-texto" id='texto' name="texto"
                    value={this.props.texto} readOnly={true} style={{ overflow: 'hidden', minHeight: "100px", height: this.state.alto, border: 'none', outline: 'none' }}/>
                */}
                <p className="BadgeLeerEntrada-texto">{this.props.texto}</p>
            
            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeLeerEntrada;