export default class Entrada {

    constructor (autor, date, privacidad, categoria, portada, titulo, subtitulo, texto, galeria, likes) {

        this.autor = autor;
        this.date = date;
        this.privacidad = privacidad;
        this.categoria = categoria;

        this.portada = portada;
        this.titulo = titulo;
        this.subtitulo = subtitulo;
        this.texto = texto;
        this.galeria = galeria;

        this.likes = likes;
    }

    toString() {
        return this.autor + ', ' + this.date + ', ' + this.privacidad + ', ' + this.categoria + ', ' + 
        this.portada + ', ' + this.titulo + ', ' + this.subtitulo + ', ' + this.texto + ', ' + this.galeria + ', ' + 
        this.likes;
    }

    // Firestore data converter
    entradaConverter = {
        toFirestore: (entrada) => {
            return {
                autor: entrada.autor,
                date: entrada.date,
                privacidad: entrada.privacidad,
                categoria: entrada.categoria,

                portada: entrada.portada,
                titulo: entrada.titulo,
                subtitulo: entrada.subtitulo,
                texto: entrada.texto,
                galeria: entrada.galeria,

                likes: entrada.likes
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new Entrada(data.autor, data.date, data.privacidad, data.categoria,
                data.portada, data.titulo, data.subtitulo, data.texto, data.galeria,
                data.likes);
        }
    }
}