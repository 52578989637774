import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "../estilos/Badge.css";
import icono from '../../imagenes/iconos/icon-logo-web.svg';

//Escuchador del estado de sesión del usuario
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

const auth = getAuth();


class BadgeIniciarSesion extends React.Component{


    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
     }
  
    // Botón para iniciar sesión
    handleSubmit = (e) => {

        e.preventDefault();
    
        const correo = e.target.elements.correo.value;
        const contraseña = e.target.elements.contraseña.value;
    
        signInWithEmailAndPassword(auth, correo, contraseña)
        .then((userCredential) => {
            
            // Signed in
            const user = userCredential.user;
            // ...

            const mensaje = `Bienvenido 🎉 ${user.email}`;

            console.log(mensaje);
            // alert("Bienvenido 🎉 " + user.email);
        })
        .catch((error) => {
            const errorCode = error.code;
            const errorMessage = error.message;

            const mensaje = "\nOcurrió un error \n\n 👉🏼 Verifica tu correo y tu contraseña\n 👉🏼 Si olvidaste tu contraseña puedes reiniciarla\n";

            alert(mensaje);

            console.log("Error: " + errorMessage, errorCode);
        });
            
    }

    
    // Lo que se visualiza en pantalla
    render(){
        //this.props;

        return(

            <div className="Badge_crear_cuenta">

                <div className="Badge__header__crear_cuenta">

                    <img className="Badge__header-icon_crear_cuenta" src={icono} alt="Logo de página personal"/>
                    
                </div>


                <div className="Badge__section-name_crear_cuenta">
            
                </div>

                <form onSubmit={this.handleSubmit} className="Badge__formulario">


                    <div className="formulario">
                        <label>Correo electrónico</label>
                        <input className="form-control" type="email" id="correo"
                        name="correo" placeholder="ejemplo@correo.com"/>
                    </div>

                    <div className="formulario">
                        <label>Contraseña</label>
                        <input className="form-control" type="password" id="contraseña"
                        name="contraseña" placeholder=""/>
                    </div>

                    <button className="btn btn-login" >Iniciar sesión</button>

                </form>



                <br/>
                <Link className="link-simple" to="/recuperar-cuenta">Olvidé mi contraseña</Link>
                {/* <Link className="link-simple" to="/crear-cuenta">Aún no tengo una cuenta</Link> */}

                

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeIniciarSesion;