import React from 'react';

import '../../global.css';

import BadgePagina from '../../componentes/Administracion/BadgePagina.js';
import Pagina from '../../componentes/SesionIniciada/ObjetoPagina.js';

import { getFirestore, collection, getDocs, query, orderBy } from "firebase/firestore";

// import iconoTwitter from '../../imagenes/iconos/icon-twitter.svg';
// import iconoFacebook from '../../imagenes/iconos/icon-facebook.svg';

const banner = "https://images.unsplash.com/photo-1573164713988-8665fc963095?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1769&q=80";
const imagenTop = "https://images.unsplash.com/photo-1573164574230-db1d5e960238?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1169&q=80";
// const imagen = "";

// --------------

const db = getFirestore();
// const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";


// Formato "día, fecha de mes de año"
const formatoFecha = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
// const formatoHora = { hour: 'numeric', minute: 'numeric', hour12: true };

// --------------

class Paginas extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            ListaSeccionesPagina:[],
        };

        this.getListaPaginas();
    }

    // ENTRADAS ----------------------
    async getListaPaginas(){
        
        // Creamos una lista local
        let lista = [];

        const referenciaComentarios = collection(db, `Paginas`);

        // Create a query against the collection.
        const q = query(referenciaComentarios, orderBy("date", "desc")).withConverter(Pagina.paginaConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    // Variable de tipo Entrada.class
                    var objPagina = new Pagina('','','','','','','','','');
                    objPagina = documento.data();

                    let idReferencia = documento.id;

                    let autor = objPagina.autor;
                    let date = objPagina.date;

                    // Convertimos Timestamp a Date
                    const dateEntrada = date.toDate();

                    /* NECESARIO para la tarjeta */
                    // "día, fecha de mes de año"
                    var fechaString = dateEntrada.toLocaleDateString("es-MX", formatoFecha);
                    // "hora:minuto am/pm"
                    // var horaString = dateEntrada.toLocaleTimeString("es-MX", formatoHora);


                    // Separamos para mostrar una URL más amigable
                    const urlPrivada = "autor_" + autor + "_fecha_";
                    const urlPublica = idReferencia.replace(urlPrivada, ""); 


                    var nombrePublico = "Adolfo Ordinola";
                    var nombreUsuario = "adolordi";

                    // Creamos un objeto local
                    let objeto = {idReferencia, urlPrivada, urlPublica, objPagina, fechaString, nombrePublico, nombreUsuario };

                    // Agregamos el objeto local a la lista local
                    lista.push(objeto);

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            paginaExistente: true,
                            idReferencia: idReferencia,
                            ListaSeccionesPagina: lista
                        }
                    });
                });

            if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaSeccionesPagina: lista
                        }
                    });

            }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                {/* Portada */}
                <div className="header-portada">
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Páginas</h1>
                        <h2>Sitemap del sitio</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <img className="imagen-media-pantalla" src={imagenTop} alt="Imagen de Unsplash"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>Colección de páginas del sitio</h3>
                        <br/>
                        <span>Aquí se ordenan según el grado de publicación. Consulta periódicamente si hay novedades de publicación.</span><br/>
                        <br/>
                        <span><b>¿Hay revisiones pendientes?</b></span><br/>
                    </div>
                    
                </div>

                {/* Items del blog */}
                <div className="header-pantalla-completa">

                    <div className="filtro-pantalla-completa">
                        <span className='span-filtro-dia' title='Se muestran todas las entradas'>Páginas del sitio</span>
                    </div>

                    {/* Entradas del blog */}
                    {this.state.ListaSeccionesPagina.map((lista) => (

                        <BadgePagina
                            key={lista.id}
                            idItem={`ID${lista.idReferencia}`}
                            
                            idReferencia={lista.idReferencia}
                            urlPrivada={lista.urlPrivada}
                            urlPublica={lista.urlPublica}

                            public={false}
                            
                            url={lista.objPagina.url}

                            autor={lista.objPagina.autor}
                            date={lista.objPagina.date}

                            portada={lista.objPagina.portada}
                            titulo={lista.objPagina.titulo}
                            subtitulo={lista.objPagina.subtitulo}
                            categoria={lista.objPagina.categoria}
                            texto={lista.objPagina.texto}
                            
                            fechaString={lista.fechaString}
                            nombrePublico={lista.nombrePublico}
                            nombreUsuario={lista.nombreUsuario} />
                    ))}

                </div>


                


            </React.Fragment>


        );
    }
}


export default Paginas;