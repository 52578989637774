export default class SubPagina {
    
    // Post global (SubPage in the website): página, post en blog, post en muro
    constructor ( urlPublic, autor, date, privacidad, categoria, portada, titulo, subtitulo, texto ) {


        // this.urlPrivate = urlPrivate; Innecesaria por default (se obtiene con la date)
        this.urlPublic = urlPublic;

        this.autor = autor;
        this.date = date;
        this.privacidad = privacidad; // public or private

        this.categoria = categoria;
        this.portada = portada;
        this.titulo = titulo;
        this.subtitulo = subtitulo;
        this.texto = texto;
    }

    toString() {
        return this.urlPublic + ', ' + this.autor + ', ' + this.date + ', ' + this.privacidad + ', ' + 
        this.categoria + ', ' + 
        this.portada + ', ' + this.titulo + ', ' + this.subtitulo + ', ' + this.texto;
    }

    // Firestore data converter
    subPaginaConverter = {
        toFirestore: (subPagina) => {
            return {

                urlPublic: subPagina.urlPublic,
                autor: subPagina.autor,
                date: subPagina.date,
                privacidad: subPagina.privacidad,
                
                categoria: subPagina.categoria,
                portada: subPagina.portada,
                titulo: subPagina.titulo,
                subtitulo: subPagina.subtitulo,
                texto: subPagina.texto
            };
        },
        fromFirestore: (snapshot, options) => {
            const data = snapshot.data(options);
            return new SubPagina(data.urlPublic, data.autor, data.date, data.privacidad,
                data.categoria, data.portada, data.titulo, data.subtitulo, data.texto);
        }
    }
}