import React from 'react';
import { Link } from 'react-router-dom';

import '../../global.css';

import banner from '../../imagenes/portadas/OpenGraph Adolfo Ordinola.png';
import iconTwitter from '../../imagenes/iconos/icon-twitter.svg';
import iconFacebook from '../../imagenes/iconos/icon-facebook.svg';

import imagenAdolfo from '../../imagenes/Adolfo Ordinola.png';
const imagenMisProyectos = "https://images.unsplash.com/photo-1572177812156-58036aae439c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
// const imagen = "";

class Adolfo extends React.Component {

    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Biografía</h1>
                        <h2>About me</h2>
                    </div>
                </div>

                {/* Portada */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenAdolfo} alt="Imagen de Adolfo Ordinola"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Quién soy?</h3>
                        <br/>
                        <span className="span-texto-normal">Desarrollador Android y Web, estudié en la Facultad de Ingeniería de la UNAM.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Soy el actual CEO de <Link to="/fortin-tech">Fortín Tech</Link>, junto a mi hermano, <Link to="/armando">Armando</Link>.</span><br/>
                        <br/>
                        --
                        <br/>
                        <br/>
                        <span className="span-texto-normal">En 2017 creé mi primera app, <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.fortinlabs.apamed">APAMED</a>.</span><br/>
                        <br/>
                        <span className="span-texto-normal">En 2019 vendí mi primer servicio como programador al H. Ayuntamiento de Tila, Chiapas.</span><br/>
                        <br/>
                        <img src={iconTwitter} alt="Icono de Twitter" style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/><a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/user?screen_name=apeordi">@apeordi</a>
                        <img src={iconFacebook} alt="Icono de Facebook" style={{ height:'20px', width:'20px', margin:'0 5px 0 10px' }}/><a target="_blank" rel="noopener noreferrer" href="https://facebook.com/apeordi">@apeordi</a>
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué otras cosas he creado?</h3>
                        <span className="span-texto-normal">Tengo un par de proyectos interesantes.</span><br/>
                        <br/>
                        <h4><b>• APAMED</b></h4>
                        <span className="span-texto-normal">Plataforma de capacitación para médicos, enfermeros y odontólogos gratis. Presente en más de 50 países.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://apamedoficial.com/acerca-de">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• Control de Obras</b></h4>
                        <span className="span-texto-normal">Bitácora inteligente para ingenieros civiles y arquitectos. Basada en la nube. Ideal para equipos de trabajo.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://apeordi.com/proyectos/control-flows">+ Leer más</a><br/>
                        <br/>
                        <h4><b>• ¿Qué red social se cayó?</b></h4>
                        <span className="span-texto-normal">Bot en Twitter que te avisa cuando una red social, plataforma o app se cae. Útil para no estresarte con tu módem.</span><br/>
                        <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/intent/user?screen_name=QuienSeCayo">+ Leer más</a><br/>
                        <br/>
                        <span className="span-texto-normal">También puedes visitar mi <a target="_blank" rel="noopener noreferrer" href="https://apeordi.com/blog">blog personal</a>.</span><br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenMisProyectos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default Adolfo;