import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeCrearPagina.css";

// Objetos personales
import Pagina from './ObjetoPagina.js';


// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1585854467604-cf2080ccef31?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1773&q=80";

// ------------------------

// Referencias a Firestore
const db = getFirestore();
const storage = getStorage();

// Para seleccionar la foto
var rutaFotoPortada = "";
var mUid;
var file;
var urlNuevaPortada = "";
var referenciaStorage;

var valorPrivacidad = "";
var valorCategoria = "";

// ------------------------

class BadgeCrearPagina extends React.Component{

    // const uid = this.props.uid;
    mUid = this.props.autor;

    /* Obtenemos de la base de datos */
    constructor(props) {
        super(props);

        rutaFotoPortada = this.props.portada;
        valorPrivacidad = this.props.privacidad;
        valorCategoria = this.props.categoria;

        if( this.props.postNuevo === true ){

            // Estado
            this.state = {
                valorSubida: 0,
                picture: banner,
                mostrarSeleccionFoto: true,
                textoBoton: "",
                textoBotonSubirEntrada: "Subir página",

                privacidadSeleccionada: "✍🏼 Borrador",
                categoriaSeleccionada: "✍🏼 Borrador",
                otraCategoria: false
            };

        } else {

            // Estado
            this.state = {
                valorSubida: 0,
                picture: this.props.portada,
                mostrarSeleccionFoto: true,
                textoBoton: "",
                textoBotonSubirEntrada: "Actualizar página",

                privacidadSeleccionada: this.props.privacidadSeleccionada,
                categoriaSeleccionada: this.props.categoria,
                otraCategoria: false
            };

        }

        // console.log(idReferencia);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        
        // ----------------------------

        this.handleMostrarPortada = this.handleMostrarPortada.bind(this);
        this.handleElegirFoto = this.handleElegirFoto.bind(this);
        this.handleElegirPrivacidad = this.handleElegirPrivacidad.bind(this);
        this.handleElegirCategoria = this.handleElegirCategoria.bind(this);
        this.handleSubirEntrada = this.handleSubirEntrada.bind(this);
    }

    /* Eventos */
    handleMostrarPortada(event){
        event.preventDefault();

        // Para que no se cargue la foto seleccionada
        urlNuevaPortada = "";

        if(this.props.postNuevo){

            this.setState({
                picture: banner,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        } else {

            this.setState({
                picture: this.props.portada,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        }
    }

    handleElegirFoto(event){

        mUid = getAuth().currentUser.uid;
        file = event.target.files[0];
        if (file !== null){

            const objectURL = URL.createObjectURL(file);
            // Para que se cargue la foto seleccionada
            urlNuevaPortada = "Imagen cargada";

            this.setState({
                valorSubida: 0,
                picture: objectURL,
                mostrarSeleccionFoto: false,
                textoBoton: "Restablecer"
            });
        }
        
    }

    handleElegirPrivacidad = (e) => {
        e.preventDefault();

        valorPrivacidad = e.target.value;
        // console.log("Valor: " + valorPrivacidad)
        this.setState({
            privacidadSeleccionada: valorPrivacidad
        });        
    }

    handleElegirCategoria = (e) => {
        e.preventDefault();

        valorCategoria = e.target.value;
        // console.log("Valor: " + valorCategoria)
        this.setState({
            categoriaSeleccionada: valorCategoria
        });

        if(valorCategoria === "Otra categoría"){
            this.setState({
                otraCategoria: true
            });
        } else {
            this.setState({
                otraCategoria: false
            });
        }
        
    }

    handleSubirEntrada = (e) => {
        e.preventDefault();

        const tituloNuevo = e.target.elements.titulo.value;
        const subtituloNuevo = e.target.elements.subtitulo.value; // Opcional
        const textoNuevo = e.target.elements.texto.value;

        const fechaNueva = e.target.elements.fecha.value;
        const horaNueva = e.target.elements.hora.value;

        rutaFotoPortada = this.props.portada;
        const autor = this.props.autor;
        mUid = this.props.autor;
        var privacidad = this.state.privacidadSeleccionada;
        var categoria = this.state.categoriaSeleccionada;

        const valor = valorCategoria;

        if(valor === "Otra categoría"){
            categoria = e.target.elements.otraCategoria.value;
        }/* else {
            categoria = this.state.categoriaSeleccionada;
        } */


        if (autor !== "") {
            // Si obtengo una uid existente

            if (tituloNuevo === null || tituloNuevo === "") {
                const mensaje = `Escribe un Título`;
                alert(mensaje);

            } else if (textoNuevo === null || textoNuevo === "") {
                const mensaje = `Escribe algo genial`;
                alert(mensaje);

            } else if (fechaNueva === null || fechaNueva === "") {
                const mensaje = `Elige una FECHA 📅`;
                alert(mensaje);

            } else if (horaNueva === null || horaNueva === "") {
                const mensaje = `Elige una HORA ⌚`;
                alert(mensaje);

            } else {

                var urlID;  
                var urlPublica;
                var fechaDefault = new Date();

                // const date = new Date();
                if (this.props.idReferencia !== ""){

                    urlID = this.props.idReferencia;
                    urlPublica = this.props.url;

                } else {

                    urlID =
                    fechaDefault.getFullYear() +
                    "_" +
                    (fechaDefault.getMonth()+1) +
                    "_" +
                    (fechaDefault.getDate()) +
                    "_" +
                    fechaDefault.getHours() +
                    "_" +
                    fechaDefault.getMinutes() +
                    "_" +
                    fechaDefault.getSeconds();

                    urlPublica = urlID;
                }

                var fecha = new Date();
                
                // Separamos los datos de la fecha
                const [anio, mes, dia] = fechaNueva.split("-");
                const value1 = Number(anio);
                const value2 = Number(mes) - 1; // Enero inicia en 0
                const value3 = Number(dia);
                fecha.setFullYear(value1);
                fecha.setMonth(value2);
                fecha.setDate(value3);
                    
                // Separamos los datos de la hora
                const [hora, minuto] = horaNueva.split(":");
                const val1 = Number(hora);
                const val2 = Number(minuto);
                fecha.setHours(val1);
                fecha.setMinutes(val2);


                // Si se subió una foto
                if (urlNuevaPortada !== ""){

                    // Agregar file.name para tener múltiples fotos en la referencia de usuario
                    const url = "Paginas/" + urlID + "/portada";

                    // url = `Blog/${mUid}/foto-perfil`;
                    referenciaStorage = ref(storage, url);

                    // Se sube el archivo
                    const uploadTask = uploadBytesResumable(referenciaStorage, file);
                    // Register three observers:
                    // 1. 'state_changed' observer, called any time the state changes
                    // 2. Error observer, called on failure
                    // 3. Completion observer, called on successful completion
                    uploadTask.on('state_changed',
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
        
                        this.setState({
                            valorSubida: progress
                        });
        
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                            break;
                            case 'running':
                                console.log('Upload is running');
                            break;
                            default:
                                console.log('Pasó algo más');
                            break;
                        }
                    },
                    (error) => {
                        
                        const mensaje = `Error cargando portada: ` + error.message ;
                        console.log(mensaje);
                    },
                    () => {
        
        
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        
                            rutaFotoPortada = downloadURL;
                            console.log(`Se subió a: ${referenciaStorage.fullPath}`);

                            const objPagina = new Pagina(urlPublica, autor, fecha, privacidad, categoria, rutaFotoPortada, tituloNuevo, subtituloNuevo, textoNuevo);

                            const ref = doc( db, `Paginas`, urlID).withConverter(objPagina.paginaConverter);
                            setDoc(ref, objPagina);

                            this.setState({
                                valorSubida: 100
                            });
                            // alert("¡Entrada agregada!");

                            // Reiniciamos los datos del formulario
                            var tituloValue = document.getElementById("titulo");
                            if (tituloValue.value !== "") {
                                tituloValue.value = "";
                            }
                            var subtituloValue = document.getElementById("subtitulo");
                            if (subtituloValue.value !== "") {
                                subtituloValue.value = "";
                            }
                            var textoValue = document.getElementById("texto");
                            if (textoValue.value !== "") {
                                textoValue.value = "";
                            }

                            urlNuevaPortada = "";

                            // La fecha no se reinicia para agilizar la creación de múltiples tareas

                            // Reiniciamos los datos de la hora
                            var horaValue = document.getElementById("hora");
                            if (horaValue.value !== "") {
                                horaValue.value = "";
                            }
        
                            
                        });
                    });
        
                } else {
        
                    
                    const objPagina = new Pagina(urlPublica, autor, fecha, privacidad, categoria, rutaFotoPortada, tituloNuevo, subtituloNuevo, textoNuevo);

                    const ref = doc( db, `Paginas`, urlID).withConverter(objPagina.paginaConverter);
                    setDoc(ref, objPagina);

                    this.setState({
                        valorSubida: 100
                    });
                    // alert("¡Entrada agregada!");

                    // Reiniciamos los datos del formulario
                    var tituloValue = document.getElementById("titulo");
                    if (tituloValue.value !== "") {
                        tituloValue.value = "";
                    }
                    var subtituloValue = document.getElementById("subtitulo");
                    if (subtituloValue.value !== "") {
                        subtituloValue.value = "";
                    }
                    var textoValue = document.getElementById("texto");
                    if (textoValue.value !== "") {
                        textoValue.value = "";
                    }

                    urlNuevaPortada = "";

                    // La fecha no se reinicia para agilizar la creación de múltiples tareas

                    // Reiniciamos los datos de la hora
                    var horaValue = document.getElementById("hora");
                    if (horaValue.value !== "") {
                        horaValue.value = "";
                    }
        
                }
                
                

            }

        } else {

            // Si no tengo una uid existente
            mUid = this.props.autor;
            console.log("No se encontró la referencia de Autor: " + mUid);
        }
    };

    /* Lo que mostramos en pantalla */
    render(){

        return(

            <div>

                {/* Editable */}
                <div className="BadgeCrearPagina">

                    
                    {/* Portada */}
                    <div className="BadgeCrearPagina-portada">

                        <img hidden={!this.state.picture} className="BadgeCrearPagina-imagen-portada" src={this.state.picture} alt="Imagen de portada" />
                        <img hidden={this.state.picture} className="BadgeCrearPagina-imagen-portada" src={this.props.portada} alt="Puedes subir una foto de portada" />

                        <div className="BadgeCrearPagina-header-contenido-portada">
                            
                            {/* Seleccionar foto */}
                            <input 
                                className="ocultar-vista" type="file" id="fileElem"
                                accept="image/*" onChange={this.handleElegirFoto}/>
                            <label hidden={!this.state.mostrarSeleccionFoto} className="btn btn-seleccionar-foto" htmlFor="fileElem">Seleccionar foto</label>
                            <button hidden={!this.state.textoBoton} className="btn btn-mostrar-foto" onClick={this.handleMostrarPortada}>{this.state.textoBoton}</button>
                        </div>
                    </div>

                    {/* Formulario */}
                    <form onSubmit={this.handleSubirEntrada}>

                        {/* Título, Subtítulo y Texto */}
                        <textarea className="BadgeCrearPagina-titulo" type="text" id="titulo" defaultValue={this.props.titulo}
                            name="titulo" placeholder="Título de la página" rows="1"/>
                        <textarea className="BadgeCrearPagina-subtitulo" type="text" id="subtitulo" defaultValue={this.props.subtitulo}
                            name="subtitulo" placeholder="Subtítulo (opcional)" rows="1"/>
                        <textarea className="BadgeCrearPagina-texto" type="text" id="texto" defaultValue={this.props.texto}
                            name="texto" placeholder="Escribe algo genial"/>

                        {/* Progress bar */}
                        <progress value={this.state.valorSubida} max="100" style={{ width: "50%", height: "0.5rem", display: "flex", margin: "0 auto", alignContent: "center" }}/>
                        <br/>

                        {/* Opciones de privacidad para mostrarse */}
                        <select className="menu-desplegable" onChange={this.handleElegirPrivacidad} defaultValue={this.state.privacidadSeleccionada}>
                            
                            <option><span role='img' aria-label='Emoji'>✍🏼</span> Borrador</option>
                            <option><span role='img' aria-label='Emoji'>⌚</span> Revisión</option>
                            <option><span role='img' aria-label='Emoji'>📌</span> Corregir</option>
                            <option><span role='img' aria-label='Emoji'>✅</span> En línea</option>
                            
                        </select>

                        {/* Lista de categorías */}
                        <select className="menu-desplegable" onChange={this.handleElegirCategoria} defaultValue={this.state.categoriaSeleccionada}>
                            
                            <option><span role='img' aria-label='Emoji'>✍🏼</span> Borrador</option>
                            <option><span role='img' aria-label='Emoji'>⌚</span> Revisión</option>
                            <option><span role='img' aria-label='Emoji'>📌</span> Corregir</option>
                            <option><span role='img' aria-label='Emoji'>✅</span> En línea</option>
                            
                        </select>
                        {/* Otra categoría */}
                        <div hidden={!this.state.otraCategoria} className="formulario-tarea">
                            <input type="text" id="otraCategoria" name="otraCategoria" placeholder="Escribe una categoría" defaultValue={this.props.categoria}/>
                        </div>

                        {/* Fecha y Hora */}
                        <div>
                            <div className="formulario-tarea">
                                <label className="label-tarea">Fecha: </label>
                                <input type="date" name="fecha" id="fecha" title="Elige una fecha" defaultValue={this.props.fechaString}/>
                            </div>
                            <div className="formulario-tarea">
                                <label className="label-tarea">Hora: </label>
                                <input type="time" name="hora" id="hora" title="Elige una hora" defaultValue={this.props.horaString}/>
                            </div>
                        </div>


                        {/* Enviar */}
                        <button className="btn btn-secondary">{this.state.textoBotonSubirEntrada}</button>
                        <br />
                    
                    </form>

                    <br/>

                    {/* Progress bar de página enviada */}
                    <div hidden={!this.state.valorSubida} style={{ width: "100%", display: "block", margin: "0 auto 50px", alignContent: "center" }}>
                        <label className='span-centrado-simple'>¡Página subida con éxito!</label><br/>
                        <Link className='link-simple' to="/"><big>Regresar</big></Link>
                    </div>
                    

                        
                                
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeCrearPagina;