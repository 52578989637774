import React from 'react'

import '../../global.css';
import '../estilos/BadgeMain.css';

import imagenLogo from '../../imagenes/iconos/icon-logo-web.svg';

const banner = "https://images.unsplash.com/photo-1584433144859-1fc3ab64a957?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1930&q=80";

const imagenQueDatosNecesitaUnaCuenta = "https://images.unsplash.com/photo-1573166475912-1ed8b4f093d2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=388&q=80";
const imagenMisDatos = "https://images.unsplash.com/photo-1562564055-71e051d33c19?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80";
// const imagen = "";


class Inicio extends React.Component{

    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Privacidad</h1>
                        <h2>Términos y condiciones</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="header-media-pantalla">
                        <img src={imagenLogo} alt="Icono de my bio fans" style={{ height: '100%', width: '100%', padding:'0 25%' }}/>
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué tan seguro es apeordi.com?</h3>
                        <br/>
                        <span className="span-texto-normal">Usamos únicamente servidores de Google para mantener seguro el tráfico todo el tiempo. Esto hace que nadie pueda hackear, vulnerar o interceptar sus datos de navegación.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Nuestro sitio mantiene una integración 100% nativa. Así mantenemos sus datos siempre encriptados.</span><br/>
                        <br/>
                        <span className="span-texto-normal"><b>La principal característica del sitio es la productividad e información</b>.</span><br/>
                        <br/>
                        
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué datos necesita una cuenta?</h3>
                        <br/>
                        <span className="span-texto-normal">En caso de ser invitado a crear una cuenta, se requerirán estos datos:</span><br/>
                        <br/>
                        <span className="span-texto-normal"><b>Necesarios:</b></span><br/>
                        <span className="span-texto-normal">• Correo electrónico.</span><br/>
                        <span className="span-texto-normal">• Un <b>Nombre público</b> (con el que decidas identificarte).</span><br/>
                        <span className="span-texto-normal">• Un <b>Nombre de usuario</b> (con el que puedan buscarte).</span><br/>
                        <br/>
                        <span className="span-texto-normal"><b>Opcionales:</b></span><br/>
                        <span className="span-texto-normal">• Una biografía para describirte.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Nota: <b>NUNCA vemos ni compartimos su contraseña</b> (ésta se guarda de forma encriptada y ni el desarrollador tiene acceso a ella).</span><br/>
                        <br/>
                    </div>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenQueDatosNecesitaUnaCuenta} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', maxHeight: '700px' , borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <div className="header-media-pantalla">
                        <img className="imagen-media-pantalla" src={imagenMisDatos} alt="Imagen de Unsplash" style={{ height: '100%', width: '100%', borderRadius:'100px', padding:'50px' }} />
                    </div>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>¿Qué pasa con mis datos?</h3>
                        <br/>
                        <span className="span-texto-normal">Eres dueño de todos los datos que proporciones (foto de perfil, posts, etc.).</span><br/>
                        <br/>
                        <span className="span-texto-normal">No recopilamos, vendemos, ni usamos tus datos con fines comerciales. Es una plataforma de uso libre. Sin letras pequeñas.</span><br/>
                        <br/>
                        <span className="span-texto-normal">Mira nuestra <a target="_blank" rel="noopener noreferrer" href="https://apeordi.notion.site/T-rminos-y-condiciones-Privacidad-6f4440dfcfe24437978ac50bdd12a7b8">Política de Privacidad completa</a> ❤.</span><br/>
                        <br/>
                    </div>
                    
                </div>
                

            </React.Fragment>


        );
    }
}


export default Inicio;