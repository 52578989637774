import React from 'react';

// import { App as NavbarAdministrador } from './NavbarAdministrador.js';
import NavbarAdministrador from './NavbarAdministrador.js'
import BotonScrollTop from '../Main/BotonScrollTop.js';
import Footer from '../Main/Footer.js';
// import { NavbarBottom as NavbarDeUsuario } from '../SesionIniciada/NavbarDeUsuario.js';

import '../../global.css';

function Layout (props) {
    
    return (
        
        <React.Fragment>

            <NavbarAdministrador theme={props.theme}/>
            {props.children}
            <BotonScrollTop theme={props.theme}/>
            {/* <NavbarDeUsuario /> */}            
            <Footer theme={props.theme}/>

        </React.Fragment>
    );
}

export default Layout;