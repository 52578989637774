import React from 'react';

import '../global.css';

// Objetos personales
import Entrada from '../componentes/SesionIniciada/ObjetoEntrada.js';
import BadgeCrearEntrada from '../componentes/SesionIniciada/BadgeCrearEntrada.js';

// Importamos los componentes de Firebase
import { getFirestore } from "firebase/firestore";
import { getDoc, doc } from "firebase/firestore";


// ------------------------

const db = getFirestore();


// Variable de tipo Entrada.class
var objEntrada = new Entrada('','','','','','','','','','');

// ------------------------


class EditarEntrada extends React.Component{


    /* Datos iniciales */
    constructor(props) {
        super(props);

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            urlPublica: "",

            autor: mUid,
            portada: "",

            horaString: "",
            fechaString: "",

            titulo: "",
            subtitulo: "",
            texto: "",

            privacidad: "",
            categoria: "",
            likes: ""
        };

        this.getEntrada();
    }

    async getEntrada(){

        // UID del dueño
        const mUid = "AK4rKU9fp3cP8dAcSd4VwJVOgfc2";

        // console.log("Props: " + this.props.match.url);

        // const idReferencia = urlPrivada + urlPublica;
        const urlPrivada = "autor_" + mUid + "_fecha_";
            
        // Se obtiene el Nombre de Usuario de la URL
        const urlPadre = this.props.match.url;            
        const urlPublica = urlPadre.replace("/editar-entrada/", "");            

        const idReferencia = urlPrivada + urlPublica;
       // console.log(idReferencia);

        const ref = doc(db, "Blog/" + mUid + "/Entrada", idReferencia).withConverter(Entrada.entradaConverter);
        const docSnap = getDoc(ref);

        // Se piden los datos
        docSnap.then((snapshot) => {
                
            // Se rellenan los datos
            objEntrada = snapshot.data();
            const titulo = objEntrada.titulo;
            document.title = titulo + " | Editar entrada";

            // Convertimos Timestamp a Date
            const dateEntrada = objEntrada.date.toDate();

            // Separamos el date en Hora y Fecha
            const horaString = ("0" + dateEntrada.getHours()).slice(-2) + ":" + ("0" + dateEntrada.getMinutes()).slice(-2);
            const fechaString = (dateEntrada.getFullYear()) + "-" + ("0"+(dateEntrada.getMonth()+1)).slice(-2) + "-" + ("0" + dateEntrada.getDate()).slice(-2);

            // Se actualiza el estado
            this.setState(() => {
                return {
                    idReferencia: idReferencia,
                    urlPublica: urlPublica,

                    autor: mUid,
                    portada: objEntrada.portada,

                    horaString: horaString,
                    fechaString: fechaString,

                    titulo: objEntrada.titulo,
                    subtitulo: objEntrada.subtitulo,
                    texto: objEntrada.texto,

                    privacidad: objEntrada.privacidad,
                    categoria: objEntrada.categoria,
                    likes: objEntrada.likes
                }
            });
                    
            return Promise.resolve(docSnap); 
        });
    }

    // -----------------------------

    /* Lo que mostramos en pantalla */
    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                <div id="visor" theme={this.props.theme}>
                    <BadgeCrearEntrada
                        theme={this.props.theme}

                        postNuevo = {false}
                        idReferencia = {this.state.idReferencia}
                        urlPublica = {this.state.urlPublica}

                        autor = {this.state.autor}
                        portada = {this.state.portada}

                        horaString= {this.state.horaString}
                        fechaString= {this.state.fechaString}

                        titulo = {this.state.titulo}
                        subtitulo = {this.state.subtitulo}
                        texto = {this.state.texto}

                        privacidad = {this.state.privacidad}
                        categoria = {this.state.categoria}
                        likes = {this.state.likes}
                    />
                </div>

            </React.Fragment>


        );
    }
}

export default EditarEntrada;