import React from 'react';
import { Link } from 'react-router-dom';

import SEO from '../../componentes/Main/SEO.js';
import DataSeo from '../../constantes/DataSeo.js';

import '../../global.css';

import imagenAdolfo from '../../imagenes/Adolfo Ordinola.png';
const banner = DataSeo.SobreMiImage;
const imagenCreadorContenido = "https://images.unsplash.com/photo-1526045612212-70caf35c14df?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80";
const imagenBiograficamente = "https://images.unsplash.com/photo-1619878473858-ace2b236897c?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
// const imagen = "";

class SobreMi extends React.Component{

    render() {
        return (
            <React.Fragment>

                {/* SEO */}
                <SEO
                    title={DataSeo.SobreMiTitle}
                    description={DataSeo.SobreMiDescription}
                    image={banner}
                    url={DataSeo.SobreMiLink}
                    name={"@apeordi"}
                    type={"article"}
                />

                {/* Portada */}
                <div className="header-portada" theme={this.props.theme}>
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Sobre mí</h1>
                        <h2>Todos tenemos una historia, ¿Cuál es la mía?</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenAdolfo} alt="Imagen de Adolfo Ordinola"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>Profesionalmente</h3>
                        <span>• Estudié la carrera universitaria en la <b>Facultad de Ingeniería</b> en la Universidad Nacional Autónoma de México (<b>UNAM</b>) 🎓</span><br/>
                        <br/>
                        <span>• <b>Fundé</b> mi propia empresa con impacto en el sector médico durante la pandemia.</span><br/>
                        <br/>
                        <span>• He aparecido en múltiples noticieros, revistas y periódicos gracias a mi presencia en internet.</span><br/>
                        <br/>
                        <Link to="/portafolio/apamed">Leer más</Link>
                    </div>
                    
                </div>

                {/* Sección principal 
                <div className="header-pantalla-completa" theme={this.props.theme}>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>En ratos libres</h3>
                        <span>• Soy creador digital en redes sociales, especializado en contenido viral.</span><br/>
                        <br/>
                        <span>• Editor en la marca <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/ActitudUNAM">Actitud Universitaria UNAM</a> en Facebook, Twitter e Instagram.</span><br/>
                        <br/>
                        <span>• Dirijo mi propia firma independiente como <b>El Foráneo</b> | <b>@adolordi</b> en <a target="_blank" rel="noopener noreferrer" href="https://facebook.com/adolordi">Facebook</a> y <a target="_blank" rel="noopener noreferrer" href="https://twitter.com/adolordi">Twitter</a>.</span><br/>
                        <br/>
                        <Link to="/adolordi">Leer más</Link>
                    </div>

                    <img className="imagen-media-pantalla" src={imagenCreadorContenido} alt="Imagen de Unsplash"/>
                    
                </div>
                */}

                {/* Sección principal */}
                <div className="header-pantalla-completa" theme={this.props.theme}>

                    <img className="imagen-media-pantalla" src={imagenBiograficamente} alt="Imagen de Unsplash"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>Biográficamente</h3>
                        <span>• Crecí en la <b>selva norte de Chiapas</b>, en un pueblito aislado y marginado llamado <b>Chilón</b>.</span><br/>
                        <br/>
                        <span>• Desde temprana edad desarrollé un gusto por resolver problemas lógicos, como las matemáticas.</span><br/>
                        <br/>
                        <span>• Representé a mi pueblito en varias ocasiones a nivel estatal en concursos de conocimiento, fue ahí donde me enteré poco a poco de la UNAM.</span><br/>
                        <br/>
                        <span>• Mi hermano, a la edad de 17 años, viajó junto a mi padre a la Ciudad de México, sin conocerla, para buscar información sobre cómo entrar a la universidad. Después de presentar el examen de admisión, lo hizo. Y la historia se repitió un par de años después conmigo. Era la primera vez que dejamos la vida en la selva de Chiapas.</span><br/>
                        <br/>
                        <Link to="/biografia">Leer más</Link>
                    </div>
                    
                </div>


            </React.Fragment>


        );
    }
}


export default SobreMi;