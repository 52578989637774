import React from 'react';

import '../../global.css';

import BadgeItemsBuscador from '../../componentes/Administracion/BadgeItemsBuscador.js';
import Buscador from '../../componentes/Objetos/ObjetoBuscador.js';

import { getFirestore, collection, getDocs, query, where, orderBy } from "firebase/firestore";

// import iconoTwitter from '../../imagenes/iconos/icon-twitter.svg';
// import iconoFacebook from '../../imagenes/iconos/icon-facebook.svg';

const banner = "https://images.unsplash.com/photo-1666886573301-b5d526cfd518?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
const imagenTop = "https://images.unsplash.com/photo-1666886573531-48d2e3c2b684?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D";
// const imagen = "";

// --------------

const db = getFirestore();

// --------------

class ItemsBuscador extends React.Component{

    /* Datos iniciales */
    constructor(props) {
        super(props);

        // Se muestran datos provisionales
        this.state = {
            idReferencia: "",
            ListaItemsBuscador:[],
        };

        this.getListaItemsBuscador();
    }

    // ENTRADAS ----------------------
    async getListaItemsBuscador(){
        
        // Creamos una lista local
        let lista = [];

        const referenciaItemsBuscador = collection(db, `ItemsBuscador`);

        // Create a query against the collection.
        const q = query(referenciaItemsBuscador, orderBy("name", "asc")).withConverter(Buscador.buscadorConverter);
        // const q = query(referenciaItemsBuscador, where("tipo", "==", "Vademecum"), orderBy("name", "asc")).withConverter(Buscador.buscadorConverter);
        const querySnapshot = getDocs(q);

        // ---------------------

        querySnapshot
        .then((snapshot) => {

                snapshot.forEach((documento) => {

                    // doc.data() is never undefined for query doc snapshots
                    // console.log(doc.id, " => ", doc.data());

                    // Variable de tipo Entrada.class
                    var objBuscador = new Buscador('','','','','');
                    objBuscador = documento.data();

                    let idReferencia = documento.id;

                    /* let tipo = objBuscador.tipo;
                    let name = objBuscador.name;
                    let firstDescription = objBuscador.firstDescription;
                    let secondDescription = objBuscador.secondDescription;
                    let thirdDescription = objBuscador.thirdDescription; */

                    // Creamos un objeto local
                    let objeto = {idReferencia, objBuscador };

                    // Agregamos el objeto local a la lista local
                    lista.push(objeto);

                    // Se actualiza el estado
                    this.setState(() => {
                        return {
                            idReferencia: idReferencia,
                            ListaItemsBuscador: lista
                        }
                    });
                });

            if(snapshot.empty){

                    //No se devuelve un Objeto Tarea
                    this.setState(() => {
                                
                        return {
                            idReferencia: "",
                            ListaItemsBuscador: lista
                        }
                    });

            }

        })
        .catch((error) => {
                // An error happened.
                const errorCode = error.code;
                const errorMessage = error.message;

                console.log("No se pudo realizar la búsqueda: " + errorCode + "\n\n" + errorMessage);
        });
    }

    render() {
        document.title = this.props.title;
        return (


            <React.Fragment>

                {/* Portada */}
                <div className="header-portada">
                    <img className="imagen-portada" src={banner} alt="Imagen de portada" />

                    <div className="header-contenido-portada">
                        <h1>Items (diccionario y fármacos)</h1>
                        <h2>Buscador de APAMED</h2>
                    </div>
                </div>

                {/* Sección principal */}
                <div className="header-pantalla-completa">

                    <img className="imagen-media-pantalla" src={imagenTop} alt="Imagen de Unsplash"/>
                    
                    <div className="header-media-pantalla-blanca">
                        <h3>Colección de items del buscador</h3>
                        <br/>
                        <span>Aquí se ordenan todos los fármacos y síntomas en una única lista. Consulta periódicamente si hay novedades. <b>Actualmente existen {this.state.ListaItemsBuscador.length} items.</b></span><br/>
                        <br/>
                        <span><b>¿Quieres exportar los datos? Elíge el formato que necesites.</b></span><br/>
                    </div>
                    
                </div>

                {/* Items del blog */}
                <div className="header-pantalla-completa">

                    <div className="filtro-pantalla-completa">
                        <span className='span-filtro-dia' title='Se muestran todos los items'>Selecciona desde aquí 👇🏽</span>
                    </div>

                    [
                    {/* Entradas del blog */}
                    {this.state.ListaItemsBuscador.map((lista, i) => (

                        <BadgeItemsBuscador
                            key={i}
                            idItem={`ID${lista.idReferencia}`}
                            
                            idReferencia={i+1}
                            urlPublica={lista.idReferencia}

                            tipo={lista.objBuscador.tipo}
                            name={lista.objBuscador.name}

                            firstDescription={lista.objBuscador.firstDescription}
                            secondDescription={lista.objBuscador.secondDescription}
                            thirdDescription={lista.objBuscador.thirdDescription}
                            />
                    ))}
                    ]



                    <div className="filtro-pantalla-completa">
                        <span className='span-filtro-dia' title='Se muestran todos los items'>Hasta aquí 👆🏽</span>
                    </div>

                </div>


                


            </React.Fragment>


        );
    }
}


export default ItemsBuscador;