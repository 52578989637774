import React from 'react';
import { Link } from 'react-router-dom';

import "../../global.css";
import "./estilos/BadgeCrearEntrada.css";
import iconAgregarBloque from '../../imagenes/iconos/icon-crear-bloque.svg';

// Objetos personales
import Entrada from './ObjetoEntrada.js';
// import Entrada from '../Objetos/ObjetoSubPagina.js';

import BloqueTextArea from '../Contenido/TextArea/index.js';
// import BloqueTextArea from '../Contenido/TextArea/text.js';

// Importamos los componentes de Firebase
import { getAuth } from "firebase/auth";
import { getFirestore, setDoc, doc } from "firebase/firestore";
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

// Imagen de portada provisional
const banner = "https://images.unsplash.com/photo-1515595967223-f9fa59af5a3b?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1887&q=80";

// ------------------------

// Referencias a Firestore
const db = getFirestore();
const storage = getStorage();

// Para seleccionar la foto
var rutaFotoPortada = "";
var mUid;
var file;
var urlNuevaPortada = "";
var referenciaStorage;

var valorPrivacidad = "";
var valorCategoria = "";

// Creamos una lista global
let lista = [];

// ------------------------

function borrarBloque(numId){

    // Elemento hijo
    // const elementoHijo = document.getElementById('div-'+numId);
    /* console.log("Borrando bloque: " + numId);

    // ----

    // Se solicita confirmación
    const mensaje = `⚠ ¿Desea eliminar el párrafo no.${numId}?`;
    const isOK = window.confirm(mensaje);

    if(isOK){

        // Agregamos el nuevo elemento al padre
        // elementoPadre.removeChild(elementoHijo);
        // elementoHijo.remove();
        const elemento = numId - 1;
        lista.splice(elemento, 1);

        // Creamos una lista local
        // const nuevaLista = [];

        /* for (let index = 0; index < lista.length; ++index) {

            const element = lista[index];
            let id = index + 1;
            let texto = element;

            console.log("ID: " + id + "\nTexto: " + texto);

            // Creamos un objeto local
            let objeto = { id, texto };

            // Agregamos el objeto local a la lista local
            nuevaLista.push(objeto);

            // this.updateEstado(nuevaLista);
            
        } */
        /* const mensaje = `Párrafo ${numId} eliminado`;
        alert(mensaje);

        return isOK;
    } */

    const elemento = numId - 1;
    lista.splice(elemento, 1);
}

// ------------------------

class BadgeCrearEntrada extends React.Component{

    // const uid = this.props.uid;
    mUid = this.props.autor;

    /* Datos iniciales */
    constructor(props) {
        super(props);

        rutaFotoPortada = this.props.portada;
        valorPrivacidad = this.props.categoria;
        valorCategoria = this.props.categoria;

        if( this.props.postNuevo === true ){

            // Lista de bloques (párrafos)
            // let id = lista.id;
            // let texto = lista.texto;

            // Creamos un objeto local
            // let objeto = { id, texto };

            // Agregamos el objeto local a la lista local
            // lista.push(objeto);
            // ARREGLAR: se auto agrega cada vez que se entra a la página CrearEntrada

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                picture: banner,
                mostrarSeleccionFoto: true,

                textoBoton: "",
                textoBotonSubirEntrada: "Subir entrada",

                privacidadSeleccionada: "🔐 Entrada privada",
                categoriaSeleccionada: "⚡ Nota rápida",
                otraCategoria: false,

                ListaBloques: lista
            };

        } else {

            // Estado
            this.state = {
                valorSubida: 0,
                subidaExitosa: false,
                picture: this.props.portada,
                mostrarSeleccionFoto: true,

                textoBoton: "",
                textoBotonSubirEntrada: "Actualizar entrada",

                privacidadSeleccionada: this.props.privacidad,
                categoriaSeleccionada: this.props.categoria,
                otraCategoria: false,

                ListaBloques: this.props.lista
            };

        }

        // console.log(idReferencia);

        // ID de la Entrada
        // idReferencia = this.props.idReferencia;
        
        // ----------------------------

        this.handleMostrarPortada = this.handleMostrarPortada.bind(this);
        this.handleElegirFoto = this.handleElegirFoto.bind(this);
        this.nuevoBloque = this.nuevoBloque.bind(this);
        this.handleElegirCategoria = this.handleElegirCategoria.bind(this);
        this.handleElegirPrivacidad = this.handleElegirPrivacidad.bind(this);
        this.handleSubirEntrada = this.handleSubirEntrada.bind(this);

        // this.updateEstado = this.updateEstado.bind(this);
    }

    /* Eventos */
    handleMostrarPortada(event){
        event.preventDefault();

        // Para que no se cargue la foto seleccionada
        urlNuevaPortada = "";

        if(this.props.postNuevo){

            this.setState({
                picture: banner,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        } else {

            this.setState({
                picture: this.props.portada,
                mostrarSeleccionFoto: true,
                textoBoton: ""
            });

        }
    }

    handleElegirFoto(event){

        mUid = getAuth().currentUser.uid;
        file = event.target.files[0];
        if (file !== null){

            const objectURL = URL.createObjectURL(file);
            // Para que se cargue la foto seleccionada
            urlNuevaPortada = "Imagen cargada";

            this.setState({
                valorSubida: 0,
                picture: objectURL,
                mostrarSeleccionFoto: false,
                textoBoton: "Restablecer"
            });
        }
        
    }

    /* Evento del Menú de Estilos (Dropdown) */
    menuEstilos(numId, valor){

        // console.log("Bloque (párrafo)\n\nID:\n" + numId + "\nValor:\n" + valor);

        if (valor === "MoveUp"){

        } else if (valor === "MoveDown"){
            
        } else if (valor === "Copy"){ /* Se ejecuta dentro del Objeto BloqueTextArea */
        } else if (valor === "Delete"){
            
            // Si se elimina
            if ( borrarBloque(numId) ) {

                // Se actualiza el estado
                // this.updateEstado();
                console.log("Elementos: " + lista.texto);
            }
        }
    }

    /* Evento del Menú de Opciones (Dropdown) */
    menuOpciones(numId, valor){

        // console.log("Bloque (párrafo)\n\nID:\n" + numId + "\nValor:\n" + valor);

        if (valor === "MoveUp"){

        } else if (valor === "MoveDown"){
            
        } else if (valor === "Copy"){ /* Se ejecuta dentro del Objeto BloqueTextArea */
        } else if (valor === "Delete"){
            
            // Si se elimina
            if ( borrarBloque(numId) ) {

                // Se actualiza el estado
                // this.updateEstado();
                console.log("Elementos: " + lista.texto);
            }
        }
    }

    /* Bloques (párrafos) */
    nuevoBloque(event){
        event.preventDefault();

        // Creamos una lista local
        // let lista = [];

        let id = lista.length + 1;
        let texto = "";

        // console.log("ID: " + id + "\nTexto: " + texto);

        // Creamos un objeto local
        let objeto = { id, texto };

        // Agregamos el objeto local a la lista global
        lista.push(objeto);

        // Obtenemos cuántas entradas hay
        // var numeroEntradas = lista.length;
        // Texto debajo de las entradas
        // const mensajeNumeroBloques = `${numeroEntradas} bloques (párrafos) en la publicación.`;

        // Se actualiza el estado
        this.setState(() => {
            return {
                ListaBloques: lista
            }
        });

    }

    // Se actualiza el estado
    updateEstado(){

        // Se actualiza el estado
        this.setState(() => {
            return {
                ListaBloques: lista
            }
        });
    }
    

    handleElegirPrivacidad = (e) => {
        e.preventDefault();

        valorPrivacidad = e.target.value;
        // console.log("Valor: " + valorCategoria)
        this.setState({
            privacidadSeleccionada: valorPrivacidad
        });
        
    }

    handleElegirCategoria = (e) => {
        e.preventDefault();

        valorCategoria = e.target.value;
        // console.log("Valor: " + valorCategoria)
        this.setState({
            categoriaSeleccionada: valorCategoria
        });

        if(valorCategoria === "Otra categoría"){
            this.setState({
                otraCategoria: true
            });
        } else {
            this.setState({
                otraCategoria: false
            });
        }
        
    }

    handleSubirEntrada = (e) => {
        e.preventDefault();

        const tituloNuevo = e.target.elements.titulo.value;
        const subtituloNuevo = e.target.elements.subtitulo.value; // Opcional
        const textoNuevo = e.target.elements.texto.value;

        const fechaNueva = e.target.elements.fecha.value;
        const horaNueva = e.target.elements.hora.value;

        /* console.log(
            "titulo: " + tituloNuevo + 
            "\n subtitulo: " + subtituloNuevo +
            "\n texto: " + textoNuevo +
            "\n fecha: " + fechaNueva +
            "\n hora: " + horaNueva); */

        rutaFotoPortada = this.props.portada;
        const autor = this.props.autor;
        mUid = this.props.autor;

        // var privacidad = this.state.privacidadSeleccionada;
        // var categoria = this.state.categoriaSeleccionada;
        var privacidad = e.target.elements.privacidad.value;
        var categoria = e.target.elements.categoria.value;

        let listaGaleria = [];
        const likes = this.props.likes;

        const valor = valorCategoria;

        if(valor === "Otra categoría"){
            categoria = e.target.elements.otraCategoria.value;
        }/* else {
            categoria = this.state.categoriaSeleccionada;
        } */


        if (autor !== "") {
            // Si obtengo una uid existente

            if (tituloNuevo === null || tituloNuevo === "") {
                const mensaje = `Escribe un Título`;
                alert(mensaje);

            } else if (textoNuevo === null || textoNuevo === "") {
                const mensaje = `Escribe algo genial`;
                alert(mensaje);

            } else if (fechaNueva === null || fechaNueva === "") {
                const mensaje = `Elige una FECHA 📅`;
                alert(mensaje);

            } else if (horaNueva === null || horaNueva === "") {
                const mensaje = `Elige una HORA ⌚`;
                alert(mensaje);

            } else {


                var idReferenciaString;
                var urlPublica;
                var fecha = new Date();
                
                // Separamos los datos de la fecha
                const [anio, mes, dia] = fechaNueva.split("-");
                const value1 = Number(anio);
                const value2 = Number(mes) - 1; // Enero inicia en 0
                const value3 = Number(dia);
                fecha.setFullYear(value1);
                fecha.setMonth(value2);
                fecha.setDate(value3);
                    
                // Separamos los datos de la hora
                const [hora, minuto] = horaNueva.split(":");
                const val1 = Number(hora);
                const val2 = Number(minuto);
                fecha.setHours(val1);
                fecha.setMinutes(val2);

                // const date = new Date();
                if (this.props.idReferencia !== ""){

                    idReferenciaString = this.props.idReferencia;
                    urlPublica = this.props.urlPublica;

                } else {

                    const urlPrivada = "autor_" + mUid + "_fecha_";
                    urlPublica =
                    fecha.getFullYear() +
                    "_" +
                    (fecha.getMonth()) +
                    "_" +
                    (fecha.getDate()) +
                    "_" +
                    fecha.getHours() +
                    "_" +
                    fecha.getMinutes() +
                    "_" +
                    fecha.getSeconds();

                    idReferenciaString = urlPrivada + urlPublica;                    
                }

                // const mensaje = `Tiempo del servidor: ` + serverTimestamp();
                // console.log(fecha);

                if (urlNuevaPortada !== ""){

                    // Agregar file.name para tener múltiples fotos en la referencia de usuario
                    const url = "Blog/" + mUid + "/Entrada/" + urlPublica + "/portada";

                    // url = `Blog/${mUid}/foto-perfil`;
                    referenciaStorage = ref(storage, url);

                    // Se sube el archivo
                    const uploadTask = uploadBytesResumable(referenciaStorage, file);
                    // Register three observers:
                    // 1. 'state_changed' observer, called any time the state changes
                    // 2. Error observer, called on failure
                    // 3. Completion observer, called on successful completion
                    uploadTask.on('state_changed',
                    (snapshot) => {
                        // Observe state change events such as progress, pause, and resume
                        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                        // let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log('Upload is ' + progress + '% done');
        
                        this.setState({
                            valorSubida: progress
                        });
        
                        switch (snapshot.state) {
                            case 'paused':
                                console.log('Upload is paused');
                            break;
                            case 'running':
                                console.log('Upload is running');
                            break;
                            default:
                                console.log('Pasó algo más');
                            break;
                        }
                    },
                    (error) => {
                        
                        const mensaje = `Error cargando portada: ` + error.message ;
                        console.log(mensaje);
                    },
                    () => {
        
        
                        // Handle successful uploads on complete
                        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
        
                            rutaFotoPortada = downloadURL;
                            console.log(`Se subió a: ${referenciaStorage.fullPath}`);

                            const objEntrada = new Entrada(autor, fecha, privacidad, categoria, rutaFotoPortada, tituloNuevo, subtituloNuevo, textoNuevo, listaGaleria, likes);

                            const ref = doc( db, `Blog/${autor}/Entrada`, idReferenciaString).withConverter(objEntrada.entradaConverter);
                            setDoc(ref, objEntrada);

                            this.setState({
                                valorSubida: 100,
                                subidaExitosa: true
                            });
                            // alert("¡Entrada agregada!");

                            // Reiniciamos la URL de la portada
                            urlNuevaPortada = "";
                            
                        });
                    });
        
                } else {
        
                    
                    const objEntrada = new Entrada(autor, fecha, privacidad, categoria, rutaFotoPortada, tituloNuevo, subtituloNuevo, textoNuevo, listaGaleria, likes);

                    const ref = doc( db, `Blog/${autor}/Entrada`, idReferenciaString).withConverter(objEntrada.entradaConverter);
                    setDoc(ref, objEntrada);

                    this.setState({
                        valorSubida: 100,
                        subidaExitosa: true
                    });
                    // alert("¡Entrada agregada!");

                    // Reiniciamos los datos del formulario
                    /* var tituloValue = document.getElementById("titulo");
                    if (tituloValue.value !== "") {
                        tituloValue.value = "";
                    }
                    var subtituloValue = document.getElementById("subtitulo");
                    if (subtituloValue.value !== "") {
                        subtituloValue.value = "";
                    }
                    var textoValue = document.getElementById("texto");
                    if (textoValue.value !== "") {
                        textoValue.value = "";
                    } */

                    // Reiniciamos la URL de la portada
                    urlNuevaPortada = "";

                    // La fecha no se reinicia para agilizar la creación de múltiples tareas

                    // Reiniciamos los datos de la hora
                    /* var horaValue = document.getElementById("hora");
                    if (horaValue.value !== "") {
                        horaValue.value = "";
                    } */
        
                }
                
                

            }

        } else {

            // Si no tengo una uid existente
            mUid = this.props.autor;
            console.log("No se encontró la referencia de Autor: " + mUid);
        }
    };

    /* Lo que mostramos en pantalla */
    render(){

        return(

            <div>

                {/* Editable */}
                <div className="BadgeCrearEntrada">

                    {/* Portada */}
                    <div className="BadgeCrearEntrada-portada">

                        <img hidden={!this.state.picture} className="BadgeCrearEntrada-imagen-portada" src={this.state.picture} alt="Imagen de portada" />
                        <img hidden={this.state.picture} className="BadgeCrearEntrada-imagen-portada" src={this.props.portada} alt="Puedes subir una foto de portada" />

                        <div className="BadgeCrearEntrada-header-contenido-portada">
                            
                            {/* Seleccionar foto */}
                            <input 
                                className="ocultar-vista" type="file" id="fileElem"
                                accept="image/*" onChange={this.handleElegirFoto}/>
                            <label hidden={!this.state.mostrarSeleccionFoto} className="btn btn-seleccionar-foto" htmlFor="fileElem">Seleccionar foto</label>
                            <button hidden={!this.state.textoBoton} className="btn btn-mostrar-foto" onClick={this.handleMostrarPortada}>{this.state.textoBoton}</button>
                        </div>
                    </div>


                    {/* Formulario */}
                    <form onSubmit={this.handleSubirEntrada}>

                        {/* Título */}
                        <textarea className="BadgeCrearEntrada-titulo" type="text" id="titulo" defaultValue={this.props.titulo}
                            name="titulo" placeholder="Título de la entrada" rows="1"/>
                        {/* Subtítulo */}
                        <textarea className="BadgeCrearEntrada-subtitulo" type="text" id="subtitulo" defaultValue={this.props.subtitulo}
                            name="subtitulo" placeholder="Subtítulo (opcional)" rows="1"/>

                        {/* Texto */}
                        {/* Bloques de la publicación */}
                        {this.state.ListaBloques.map((lista, i) => (

                            <BloqueTextArea
                                key={i}
                                id={lista.id}
                                texto={lista.texto}

                                menuEstilos={this.menuEstilos}
                                menuOpciones={this.menuOpciones}
                            />
                        ))}


                        {/* Botón agregar bloque */}
                        <div className='BadgeCrearEntrada-nuevoBloque'>
                            <img className="BadgeCrearEntrada-icon-nuevoBloque" src={iconAgregarBloque} alt="Agregar bloque (párrafo)" title="Agregar bloque (párrafo)"
                                onClick={this.nuevoBloque}/>
                        </div>
                        

                        {/* Espacio (eliminar) */}
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>
                        <div className='br' theme={this.props.theme}/>

                        {/* Opciones de la entrada */}
                        <div className='divider' theme={this.props.theme}/>
                        <div className='filtro-pantalla-completa' theme={this.props.theme}>
                            <span className='span-filtro-dia' theme={this.props.theme}>Opciones de la página</span>
                        </div>


                        {/* Fecha, categoría y privacidad */}
                        <div className="BadgeCrearEntrada-opciones">

                            {/* Fecha y Hora */}
                            <div className="formulario-tarea">
                                <label className="label-tarea">Fecha: </label>
                                <input type="date" name="fecha" id="fecha" title="Elige una fecha" defaultValue={this.props.fechaString}/>
                            </div>
                            <div className="formulario-tarea">
                                <label className="label-tarea">Hora: </label>
                                <input type="time" name="hora" id="hora" title="Elige una hora" defaultValue={this.props.horaString}/>
                            </div>

                            {/* Lista de categorías */}
                            <div className="formulario-tarea">
                                <select className="menu-desplegable" id="categoria" defaultValue={this.state.categoriaSeleccionada} onChange={this.handleElegirCategoria} >
                                    
                                    <option><span role='img' aria-label='Emoji'>⚡</span> Nota rápida</option>
                                    <option><span role='img' aria-label='Emoji'>🔥</span> Emocionado</option>
                                    <option><span role='img' aria-label='Emoji'>🤷🏽‍♂️</span> Meh</option>
                                    <option><span role='img' aria-label='Emoji'>👨🏽‍💻</span> Trabajando</option>
                                    <option><span role='img' aria-label='Emoji'>🧠</span> Sólo pensando</option>
                                    <option>Otra categoría</option>
                                    
                                </select>
                                {/* Otra categoría */}
                                <div hidden={!this.state.otraCategoria} className="formulario-tarea">
                                    <input type="text" id="otraCategoria" name="otraCategoria" placeholder="Escribe una categoría" defaultValue={this.props.categoria}/>
                                </div>
                            </div>

                            {/* Privacidad de entrada */}
                            <div className="formulario-tarea">
                                <select className="menu-desplegable" id="privacidad" defaultValue={this.state.privacidadSeleccionada} onChange={this.handleElegirPrivacidad}>
                                    
                                    <option><span role='img' aria-label='Emoji'>🔐</span> Entrada privada</option>
                                    <option><span role='img' aria-label='Emoji'>🌎</span> Entrada pública</option>
                                    
                                </select>
                            </div>                                    

                        </div>

                        {/* Opciones de SEO */}
                        <div className='filtro-pantalla-completa' theme={this.props.theme}>
                            <span className='span-filtro-dia' theme={this.props.theme}>SEO de la página</span>
                        </div>

                        {/* URL y Descripción de la página */}
                        <div className="formulario-tarea">
                            <label className="label-tarea">https://apeordi.com/</label>
                            <textarea className="BadgeCrearEntrada-url" type="text" defaultValue={this.props.url} id='url' name='url' rows='1' placeholder="URL de la página"/>
                        </div>
                        <br/>
                        <div className="formulario-tarea">
                            <label className="label-tarea">Descripción corta: </label>
                            <textarea className="BadgeCrearEntrada-descripcion" type="text" defaultValue={this.props.texto} id='texto' name='texto' rows='7' placeholder='"Ejemplo de una página genial."'/>
                        </div>  
                        

                        {/* Progress bar */}
                        <progress hidden={!this.state.valorSubida} value={this.state.valorSubida} max="100" style={{ width: "50%", height: "0.5rem", display: "flex", margin: "0 auto", alignContent: "center" }}/>
                        <br/>

                        {/* Enviar */}
                        <button hidden={this.state.subidaExitosa} className="btn btn-secondary">{this.state.textoBotonSubirEntrada}</button>
                        <br />
                    
                    </form>

                    <br/>

                    {/* Progress bar de entrada enviada */}
                    <div hidden={!this.state.subidaExitosa} style={{ width: "100%", display: "block", margin: "0 auto 50px", alignContent: "center" }}>
                        <label className='span-centrado-simple'>¡Entrada subida con éxito!</label><br/>
                        <Link className='link-simple' to="/"><big>Regresar</big></Link>
                    </div>
                                
                </div>

            </div>
            
        );

    }

}

//Como estamos en un archivo, debemos asegurarnos de exportar este componente
export default BadgeCrearEntrada;